import styled, { css } from "styled-components/macro";
import { darken } from "../../utils/color";

// Components
import { Button as BSButton } from "react-bootstrap";

const HOVER_INDEX = 0.15;
const ACTIVE_INDEX = 0.18;

// Button base styles
export const buttonBase = css`
  letter-spacing: 0.28px;
  font-weight: 700;
  font-size: 0.9rem;
  letter-spacing: 1px;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  min-width: 112px;
  min-height: 3.2143rem;
  border-width: 3px;

  &:hover {
    box-shadow: ${({ theme }) => theme.forms.boxShadow};
  }

  &:before {
    content: none;
  }
`;

export const buttonLg = css`
  font-size: 1rem;
  min-height: 3.7857rem;
`;

export const buttonVariant = (color) => css`
  background-color: ${color};
  border-color: ${color};

  /* Hover */
  &:hover {
    background-color: ${darken(color, HOVER_INDEX)};
    border-color: ${darken(color, HOVER_INDEX)};
  }

  /* Active */
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > &.dropdown-toggle {
    background-color: ${darken(color, ACTIVE_INDEX)};
    border-color: ${darken(color, ACTIVE_INDEX)};
  }

  /* Focus */
  &.focus,
  &:focus {
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    background-color: ${color};
    border-color: ${color};

    &:hover {
      box-shadow: none;
    }
  }
`;

export const outlineBase = css`
  background-color: transparent;

  &.disabled,
  &:disabled {
    background-color: transparent;
  }
`;

export const outlineVariant = (color) => css`
  color: ${color};

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > &.dropdown-toggle:focus {
    svg[data-icon] path {
      stroke: white;
    }
  }

  &.disabled,
  &:disabled {
    color: ${color};
  }
`;

export default styled(BSButton)`
  &.btn-sm-block {
    @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
      width: 100%;
    }
  }

  ${buttonBase}
  ${({ theme }) => buttonVariant(theme.colors.primary.base)}
  border-radius: ${({ theme }) => theme.borderRadius.base};

  /* Color variants */
  ${({ color, theme }) =>
    color === "primary" && buttonVariant(theme.colors.primary.base)}
  ${({ color, theme }) =>
    color === "alt" && buttonVariant(theme.colors.alt.base)}
  ${({ color, theme }) =>
    color === "dark" && buttonVariant(theme.colors.text.base)}
  ${({ color, theme }) =>
    color === "secondary" && buttonVariant(theme.colors.grey.darker)}
  
  /* Size */
  ${({ size }) => size === "lg" && buttonLg}

  /* Outline base style */
  ${({ outline }) => outline && outlineBase}
  ${({ color, outline, theme }) =>
    outline && color === "primary" && outlineVariant(theme.colors.primary.base)}
  ${({ color, outline, theme }) =>
    outline && color === "alt" && outlineVariant(theme.colors.alt.base)}
  ${({ color, outline, theme }) =>
    outline && color === "dark" && outlineVariant(theme.colors.text.base)}
`;
