const host = process.env.REACT_APP_SERVER;

/*
 *
 */
export async function createPaymentMethod(pm_id) {

  const body = JSON.stringify({ pm_id: pm_id });

  const request = new Request(`${host}/api/v2/payment-methods`, {
    method: 'POST',
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: 'include',
    body: body
  });

  return fetch(request).then(response => response.json())
}

/*
 *
 */
export async function listPaymentMethods() 
{
  const request = new Request(`${host}/api/v2/payment-methods`, {
    method: 'GET',
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: 'include',
  });

  return fetch(request).then(response => response.json())  
}

/*
 *
 */
export async function removePaymentMethod(pm_id) 
{
  const body = JSON.stringify({ pm_id : pm_id });

  const request = new Request(`${host}/api/v2/payment-methods/${pm_id}`, {
    method: 'DELETE',
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: 'include',
    body: body
  });

  return fetch(request); // returns 204
}

/*
 *
 */
export async function listCurrentSubscriptions() 
{
  const url = `${host}/api/v2/subscriptions`;

  const request = new Request( url, {
    method: 'GET',
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: 'include',
  });

  return fetch(request).then(response => response.json());
}

/*
 *
 */
export async function cancelSubscription(subscriptionId) 
{
  const url = `${host}/api/v2/subscriptions/${subscriptionId}`;

  const request = new Request( url, {
    method: 'DELETE',
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: 'include',
  });

  return fetch(request);
}