import React, {useState} from "react";
import {Row, Col, NavLink, Spinner} from 'react-bootstrap';
import {Redirect} from "react-router-dom";
import {ProductCard} from './ProductCard';
import * as CartService from 'utils/cart.service';
import * as ProductService from 'utils/products.service';

//this is the plan interval selection page /profile/cart/prices
//considering the selected plan/product, choose the number of seats and compare
//yearly and monthly subscription options
//product either set from /profile/cart/products (localstorage) or valid url params
//if no product selected, redirect to /profile/cart/products
//for url params- required: plan, optional: planSeats

export default function CartPrices(props) {
  const [product, setProducts] = useState(CartService.getProduct());
  const paramsProduct = CartService.getURLParams();
  const [pending, setPending] = useState(true);
  const [extraUsers, setExtraUsers] = useState(CartService.getProductQuantity());

  if(!product && paramsProduct.plan) {
    //if there's no products from /profile/cart/products but there's valid query params:
    const plan = `${paramsProduct.plan.charAt(0).toUpperCase()}${paramsProduct.plan.slice(1)}`;
    if(pending) {
      ProductService.searchProducts()
        .then(prod => {setProducts(prod.filter(item => item.name === plan)[0]);})
        .then(() => setPending(false))
        .catch(console.log);
      return (
        <>
          <Row className="mb-3">
            <Col md={6}>
              <h2>Register</h2>
              <p>
                Choose between paying monthly or paying yearly.
                A 15% off discount will apply to yearly subscriptions.
                Upgrade at any point to a yearly subscription to save.
              </p>
            </Col>
            <Col md={6}></Col>
          </Row>
          <Spinner animation="border" role="status">
          </Spinner>
          <p>Loading...</p>
        </>
      );
    }
  }
  if(extraUsers === null && paramsProduct.plan && paramsProduct.planSeats) {
    //if there's specified plan seats in query param set to extraUsers
    if(+paramsProduct.planSeats >= +product.metadata.max_users) {
      //if query param of seats is HIGHER than plan's max allowed seats, set to plan max
      //enterprise is NaN 'unlimited'
      setExtraUsers(+product.metadata.max_users - +product.metadata.included_users);
    } else if(+paramsProduct.planSeats <= +product.metadata.included_users) {
      //if query param of seats is LOWER than plan's max allowed seats, set to plan min
      setExtraUsers(0);
    } else {
      setExtraUsers((paramsProduct.planSeats - +product.metadata.included_users));
    }
  }

  if(!product) {
    // if no plan selected or paramsProduct.plan is invalid, redirect to plan select
    return <Redirect to="/profile/cart/products"></Redirect>;
  }

  const monthlyPrice = CartService.getValidPrices('month', product.prices);
  const yearlyPrice = CartService.getValidPrices('year', product.prices);

  return (
    <>
      <Row className="mb-3">
        <Col md={6}>
          <h2>Register</h2>
          <p>
            Choose between paying monthly or paying yearly.
            A 15% off discount will apply to yearly subscriptions.
            Upgrade at any point to a yearly subscription to save.
          </p>
        </Col>
        <Col md={6}></Col>
      </Row>
      <Row className="mb-3 product-cards">
        <Col md={4} key={monthlyPrice.id}>
          <ProductCard
            addUsers={true}
            extraUsers={extraUsers}
            interval='month'
            price={monthlyPrice}
            product={product}
          />
        </Col>
        <Col md={4} key={yearlyPrice.id}>
          <ProductCard
            addUsers={true}
            extraUsers={extraUsers}
            interval='year'
            price={yearlyPrice}
            product={product}
          />
        </Col>
      </Row>

      <NavLink
        to="/profile/cart/products"
        href="/profile/cart/products"
        className="btn btn-outline-secondary d-inline-block mb-4"
        onClick={event => CartService.clearCart()}
      >
        Go Back
      </NavLink>
    </>
  )
}
