import React, { useState, useEffect, ElementConfig } from "react";
import { CustomSelect } from "./../../CustomForm";
import { components } from "react-select";

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 19.01 9.74"
        height="9.74px"
        width="10px"
      >
        <polygon fill="#4F4F50" points="0,0 9.5,9.74 19,0 " />
      </svg>
    </components.DropdownIndicator>
  );
};

const SelectUser = ({ users, selectHandler }) => {
  // States
  const [options, setOptions] = useState([]);

  // Load Users Options
  useEffect(() => {
    let usersOptions = [];

    //All Company events option
    usersOptions.push({
      value: "All Company Events",
      label: "All Company Events",
    });

    users.map((user, index) => {
      let option = {
        value: user.userID,
        label: user.name,
      };
      usersOptions.push(option);
    });
    setOptions(usersOptions);
  }, [users]);

  return (
    <React.Fragment>
      <div className="d-flex align-items-center">
        <span className="d-block mr-2">Filter by User:</span>
        <div className="flex-grow-1">
          <CustomSelect
            options={options}
            components={{ DropdownIndicator }}
            onChange={(e) => selectHandler(e)}
            defaultValue={{
              value: "All Company Events",
              label: "All Company Events",
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SelectUser;
