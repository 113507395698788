import React from 'react';

export function ProductMetaTable(meta) {
  return <React.Fragment>
    <table className="table table-sm table-striped">
      <tbody>
        <tr><td>{meta.events==-1?'unlimited':meta.events} events</td></tr>
        <tr><td>{meta.max_users !== 'unlimited' ? 'up to ' : ''}{meta.max_users} users</td></tr>
        <tr><td>up to {meta.participants} participants/event</td></tr>
        <tr><td>up to {meta.survey_questions} survey questions</td></tr>
        <tr><td>{meta.months_storage} months of event replay storage</td></tr>
      </tbody>
    </table>
  </React.Fragment>
}
