import React, {useState, useEffect} from "react";
import * as BillingService from 'utils/billing.service';
import Subscription from './SubscriptionCard';

// this is the My Subscription tab on Profile /profile/my-subscription/cancel
// this component gets information about the current subscription(s)
// and offers a cancel sub option, rendering info using <Subscription/> card component

export default function SubscriptionCancel(props) {
  const [subscriptions, setSubscriptions] = useState([]);
  const [pending, setPending] = useState(true);

  const fetchSubscription = async () => {
    const sub = await BillingService.listCurrentSubscriptions();
    setSubscriptions(sub);
    setPending(false);
  };
  const cancelSubscription = async (id) => {
    setPending(true);
    await BillingService.cancelSubscription(id);
    setSubscriptions(subscriptions.filter(sub => sub.id !== id));
    setPending(false);
    // after cancel, show /my-subscription with no active sub
    window.location.href = '/profile/my-subscription';
  };

  useEffect(() => {
    if(!subscriptions || !subscriptions.length) {
      fetchSubscription();
    }
  }, []);

  if(pending) {
    return (
      <>
        <h3>Cancel</h3>
        <p>
          Please confirm that you would like to cancel your plan.<br />
          In doing so you will lose access to your past events and any upcoming events on your renewal date.
        </p>
      </>
    );
  }

  if(!subscriptions | subscriptions.length === 0) {
    return (
      <>
        <h3>Subscription Details</h3>
        <h5>You have no active subscriptions to cancel.</h5>
      </>
    );
  }

  const subscription = subscriptions[0];

  return (
    <>
      <h3>Cancel</h3>
      <p>
        Please confirm that you would like to cancel your plan.<br />
        In doing so you will lose access to your past events and any upcoming events on your renewal date.
      </p>

      <div className='product-cards'>
        <Subscription
          key={subscription.id}
          onCancel={cancelSubscription}
          subscription={subscription}
        />
      </div>
    </>
  );
}
