import React from "react";
import LoginLayout from "./../../layouts/LoginLayout/LoginLayout";
import { MainContent } from "./../../layouts/LoginLayout/LoginLayout.styles";
import ErrorMessage from "./../../components/ErrorMessage";

const Error404 = () => {
  return (
    <LoginLayout>
      <MainContent className="p-0">
        <ErrorMessage
          number="404"
          description="Page not found"
          instructions="Oops! You seem to be lost, or this page doesn't exist. Don't give up, check out our homepage."
        />
      </MainContent>
    </LoginLayout>
  );
};

export default Error404;
