import React, { Component, useContext } from "react";
import "./Account.css";
import * as sessionActions from "../actions/sessionActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Input, Button } from "../Bootstrap";
import { TableWrapper } from "../Home/Home.styles";
import {
  Table,
  Card,
  Accordion,
  useAccordionToggle,
  AccordionContext,
} from "react-bootstrap";
import EventsCollapse from "./components/EventsCollapse";
var myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

function ActiveToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const decorativeOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      className={`event-collapse__header ${isCurrentEventKey && "active"}`}
      onClick={decorativeOnClick}
    >
      {children}
    </div>
  );
}
class PaymentTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cards: [],
    };
    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleDefault = this.handleDefault.bind(this);
    this.removeCard = this.removeCard.bind(this);
  }

  removeCard(e) {
    let self = this;
    var id = this.state.cards[e.target.value].id;

    var confirmDelete = window.confirm("Are you sure?");
    if (!confirmDelete) {
      return;
    }

    var value = e.target.value;
    var request = new Request(myServer + "/api/v1/payment", {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ stripeID: id }),
    });
    fetch(request).then(function (response) {
      response.json().then((res) => {
        var array = self.state.cards;

        if (res.message === "success") {
          array.splice(value, 1);
          self.setState({
            cards: array,
          });
        }
      });
    });
  }

  handleDefault(e) {
    let self = this;
    var id = this.state.cards[e.target.value].id;
    var role = self.props.role;
    var companyID = this.props.companyID;
    var token;
    if (companyID == "") {
      token = localStorage.getItem("jwt");
    } else {
      token = this.props.companyID;
    }

    var value = e.target.value;
    var request = new Request(myServer + "/api/v1/changeDefault", {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        token: token,
        role: role,
      }),
      body: JSON.stringify({ stripeID: id }),
    });
    fetch(request).then(function (response) {
      response.json().then((res) => {
        var array = self.state.cards;
        for (let i = 0; i < array.length; i++) {
          array[i].default = 0;
        }
        array[value].default = 1;
        self.setState({
          cards: array,
        });
      });
    });
  }

  componentDidMount() {
    var self = this;
    const getCards = new Request(myServer + "/api/v1/payment", {
      method: "GET",
      credentials: "include",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(getCards).then(function (response) {
      response.json().then((array) => {
        self.setState({
          cards: array.cards,
        });
      });
    });
  }

  render() {
    return (
      <div className="my-3">
        <Button
          className="btn btn-primary"
          id="AddUser"
          size="med"
          onClick={this.props.addCard}
          variant={"alt"}
        >
          Add a Credit Card
        </Button>
        <div className="d-block clearfix"></div>
        <TableWrapper className="d-none d-lg-block">
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Card</th>
                <th>Expiry Date</th>
                <th>Default</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {this.state.cards.map((card, index) => (
                <tr key={index}>
                  <td>Ending with {card.card.last4}</td>
                  <td>
                    {card.card.exp_month}/{card.card.exp_year}
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      value={index}
                      onChange={this.handleDefault}
                      checked={card.default}
                    />
                  </td>
                  <td>
                    <Button
                      id="removeButton"
                      variant="danger"
                      disabled={card.default}
                      onClick={this.removeCard}
                      value={index}
                    >
                      Remove Card
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
        <EventsCollapse variant="alt" className="d-block d-lg-none">
          {this.state.cards.map((card, index) => (
            <Card key={`collapseCard${card.id}`}>
              <Card.Header>
                <ActiveToggle eventKey={card.id}>
                  Ending with {card.card.last4}
                </ActiveToggle>
              </Card.Header>
              <Accordion.Collapse eventKey={card.id}>
                <Card.Body>
                  <div className="d-flex flex-column">
                    <p className="event-collapse__details-item">
                      <span>Expiry Date </span>
                      <strong>
                        {card.card.exp_month}/{card.card.exp_year}
                      </strong>
                    </p>
                    <p className="event-collapse__details-item">
                      <span>Default: </span>
                      <input
                        type="checkbox"
                        value={index}
                        onChange={this.handleDefault}
                        checked={card.default}
                      />
                    </p>
                    <Button
                      id="removeButton"
                      variant="danger"
                      disabled={card.default}
                      onClick={this.removeCard}
                      value={index}
                    >
                      Remove Card
                    </Button>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </EventsCollapse>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAdmin: state.isAdmin,
    logged_in: state.session,
    loggedOut: state.loggedOut,
    companyID: state.companyID,
    userZone: state.userZone,
    creditScore: state.creditScore,
    eventID: state.eventID,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTab);
