import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sessionActions from "../actions/sessionActions";

//Assets
import Play from "./../../assets/img/play.svg";
import Pause from "./../../assets/img/pause.svg";

// Layout
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

// Components
import {
  CommentsList,
  CommentListTitle,
  CommentsToggleContainer,
  EventTitle,
  EventOverallScore,
  EventPresenterName,
  EventStats,
} from "./Event.styles";
import "react-tabs/style/react-tabs.css";
import { Form } from "react-bootstrap";
import { Container, Label, Switch } from "./../Bootstrap";
import { PlayPauseButton } from "./../CustomButtons";

//ThirdParty
import moment from "moment";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import SurveyChart from "./components/SurveyChart";
import am4lang_en_US from "@amcharts/amcharts4/lang/en_US";

var myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

var fileDataUpload;

class ViewEvent extends Component {
  constructor(props) {
    super(props);
    this.audio = React.createRef();
    this.audioRef = React.createRef();
    this.file = React.createRef();
    this.state = {
      Answers: [],
      Comments: [],
      title: "",
      chart: [],
      startTime: "",
      endTime: "",
      startTimeEpoch: "",
      endTimeEpoch: "",
      averageRatingPerSecond: 0,
      averageRatingsPerSecond: [],
      averageRatingsPerSecondTemp: [],
      attendees: [],
      attendee: "All Attendees",
      maximum: 0,
      name: "",
      numberOfAttendees: "",
      presenterName: "",
      paid: 0,
      userRecordings: [],
      upload: true,
      widthOfContainer: 1435,
      markers: [],
      pixelPerSec: "",
      recreateGraphInternal: false,
      path: "",
      loadingAudio: true,
      timeZone: "",

      // Chart States
      commentsVisible: true,
      commentSections: [],

      // AudioPlayer States
      isAudioPlaying: false,
      audioSrc: "",
      audioBlob: "",
      audioStart: "",
      audioLength: "",
      customRecording: "",
      chosenAudio: "",
    };
    this.getLocalAudio = this.getLocalAudio.bind(this);
    this.getSampleRate = this.getSampleRate.bind(this);
  }

  //Function to get the sample rate of the audio, important to create the waveform
  async getSampleRate(e) {
    let self = this;
    let graphReset = "";
    if (e !== undefined) {
      graphReset = e.target.value;
    }
    const request = new Request(myServer + "/api/v1/getSampleRate", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ path: self.state.path }),
    });

    await fetch(request).then((response) => {
      response.json().then((res) => {
        var sampleRate = res.sample_rate;
        var samplePerPixel;

        samplePerPixel = sampleRate / self.state.pixelPerSec;

        self.setState(
          {
            sampleRate: sampleRate,
            samplePerPixel: samplePerPixel,
            loadingAudio: false,
            recreateGraphInternal: false,
          },
          () => {
            if (graphReset === "reset") {
              self.createGraph(self.state.averageRatingsPerSecond);
            }
          }
        );
      });
    });
  }

  //get Audio File
  getFile = (e) => {
    let self = this;
    if (e.target.files[0]) {
      fileDataUpload = new FormData();
      fileDataUpload.append(
        "Recording",
        e.target.files[0],
        e.target.files[0].name
      );
      self.setState({
        upload: false,
      });
    }
  };

  //Upload a custom audio
  upload = (e) => {
    let self = this;
    var tempArray = [];
    self.setState(
      {
        upload: true,
      },
      () => {
        const request = new Request(myServer + "/api/v1/Recording", {
          method: "PUT",
          credentials: "include",
          headers: new Headers({
            event: self.props.eventID,
            type: "userRecord",
          }),
          body: fileDataUpload,
        });
        fetch(request).then(function (response) {
          response.json().then((res) => {
            tempArray = self.getUserAudio();
            self.setState({
              userRecordings: tempArray,
            });
          });
        });
      }
    );
    this.file.current.value = "";
  };

  //Get Presentaion Laptop Audio
  async getLocalAudio(audioName) {
    let self = this;
    await fetch(
      myServer +
        "/Uploads/Events/" +
        self.props.eventID +
        "/LocalRecordings/" +
        audioName
    )
    .then(function (response) {
      if (response.ok) {
        return response.blob();
      }
      throw new Error("Network response was not ok.");
    })
    .then(function (myBlob) {
      var blob = new Blob();
      blob = myBlob;
      var objectURL = URL.createObjectURL(blob);

      self.setState({
        audioSrc: objectURL,
        audioBlob: blob,
        path:
          "/Uploads/Events/" +
          self.props.eventID +
          "/LocalRecordings/" +
          audioName,
      });
    })
    .catch((error) => {
      console.log(error);
    });
  }

  //Get All Uploaded Audio By user
  getUserAudio = () => {
    let self = this;
    var tempArray = [];
    const userRecordings = new Request(myServer + "/api/v1/userRecordings", {
      method: "GET",
      headers:
        ({ "Content-Type": "application/json" },
        { eventID: this.props.eventID }),
    });

    fetch(userRecordings).then(function (response) {
      response.json().then((res) => {
        for (let i = 0; i < res.length; i++) {
          tempArray.push(res[i]);
          tempArray[i]["checked"] = false;
        }
        if (self.state.customRecording !== "") {
          tempArray.unshift(self.state.customRecording);
        }
        return tempArray;
      });
    });
    return tempArray;
  };

  async componentDidMount() {
    var array = [];
    let self = this;
    const profileRequest = new Request(myServer + "/api/v1/profile", {
      method: "GET",
      headers:
          ({ "Content-Type": "application/json" }),
    });
    fetch(profileRequest)
        .then((res) => res.json())
        .then((res) => {
          self.setState({ name: res.name });
        });
    const valueRequest = new Request(myServer + "/api/v1/eventValues", {
      method: "GET",
      headers:
        ({ "Content-Type": "application/json" },
        { eventID: this.props.eventID }),
    });
    const AnswerRequest = new Request(myServer + "/api/v1/getAnswers", {
      method: "GET",
      headers:
        ({ "Content-Type": "application/json" },
        { eventID: this.props.eventID }),
    });
    const commentRequest = new Request(myServer + "/api/v1/comments", {
      method: "GET",
      headers:
        ({ "Content-Type": "application/json" },
        { eventID: this.props.eventID }),
    });
    var record = "";
    await fetch(AnswerRequest)
      .then(function (response) {
        response.json().then((res) => {
          if (res.data[0].recording !== null) {
            self.getLocalAudio(res.data[0].recording);
            record = {
              idcustomRecording: 0,
              path: res.data[0].recording,
              audioLength: res.data[0].audioLength,
              audioRecordStart: res.data[0].audioRecordStart,
              checked: true,
            };
            self.setState({
              fileName: res.data[0].recording,
              customRecording: record,
              chosenAudio: record,

              pixelPerSec:
                self.state.widthOfContainer / res.data[0].audioLength,
            });

            res.data.forEach((element) => {
              var answer = {
                attendeeID: element.attendeeID,
                question: element.questionText,
                questionAnswer: element.givenAnswer,
                questionType: element.type,
              };
              array.push(answer);
            });
          } else {
            var tempArray = self.getUserAudio();
            self.setState({
              userRecordings: tempArray,
            });
          }

          var commentsArray = [];
          fetch(commentRequest).then(function (response) {
            response.json().then((res2) => {
              const comments = res2.comments;
              // We want to display attendeeIDs starting from 1 instead of seemingly arbitrary numbers so we calculate lowestAttendeeID to get attendeeIDNormalized for each comment.
              const lowestAttendeeID = comments.reduce(
                (acc, { attendeeID }) => (acc < attendeeID ? acc : attendeeID),
                Number.MAX_SAFE_INTEGER
              );
              comments.forEach((comment) => {
                var utcMilliSeconds = comment.epoch;
                var d = new Date(0);
                d.setUTCSeconds(utcMilliSeconds);
                var convertToTimeZone = d.toLocaleString("en-US", {
                  timeZone: self.state.timeZone,
                });
                var returned = self.helper(utcMilliSeconds);
                var date = new Date(
                  returned[0][2],
                  returned[0][1],
                  returned[0][0],
                  returned[1][0],
                  returned[1][1],
                  returned[2][0]
                );
                const attendeeIDNormalized =
                  comment.attendeeID - lowestAttendeeID + 1;
                var commentObj = {
                  attendeeIDNormalized,
                  attendeeID: comment.attendeeID,
                  commentID: comment.commentID,
                  comment: comment.commentText,
                  time: convertToTimeZone,
                  date: date,
                };
                commentsArray.push(commentObj);
              });
              self.setState({
                Comments: commentsArray,
              });
            });
          });
          self.setState({
            eventID: res.data[0].eventID,
            Answers: res.questions,
            title: res.data[0].Title,
            presenterName: res.data[0].presenterName,
            startTime: res.data[0].startTime,
            endTime: res.data[0].endTime,
            paid: res.data[0].paid,
            audioLength: res.data[0].audioLength,
            audioStart: res.data[0].audioRecordStart,
            localRecord: res.data[0].localRecord,
            startTimeEpoch: res.data[0].startTimeEpoch,
            endTimeEpoch: res.data[0].endTimeEpoch,
            timeZone: res.data[0].TimeZone,
            survey: res.data[0].survey,
          });
          fetch(valueRequest).then(function (response) {
            response.json().then((res) => {
              var customArray = Array.from(res.data);

              var averageValues = new Request(myServer + "/api/v1/getAverage", {
                method: "POST",
                headers: new Headers({
                  "Content-Type": "application/json",
                  eventID: self.props.eventID,
                }),
                body: JSON.stringify({ epochTimes: customArray }),
              });
              fetch(averageValues).then(function (response) {
                response.json().then((res) => {
                  if (self.state.localRecord === 1) {
                    var maximum = self.state.audioLength;
                  } else {
                    maximum =
                      self.state.endTimeEpoch - self.state.startTimeEpoch;
                  }
                  let averageRatingsPerSecond = res.data;
                  let averageRatingPerSecond =
                    averageRatingsPerSecond &&
                    averageRatingsPerSecond.length > 0
                      ? averageRatingsPerSecond
                          .map((a) => a.rating)
                          .reduce((a, b) => a + b) /
                        averageRatingsPerSecond.length
                      : 0;
                  averageRatingPerSecond = averageRatingPerSecond.toFixed(2);
                  self.setState({
                    averageRatingPerSecond,
                    averageRatingsPerSecond,
                    averageRatingsPerSecondTemp: res.data,
                    maximum: maximum,
                  });
                  //Instance for creating the graph
                  self.createGraph(self.state.averageRatingsPerSecond);

                  // Move the AudioTimeLine Stick when the Audio is playing
                  audioPlayer.addEventListener("timeupdate", function (e) {
                    let chart = self.myChart;
                    let audioPlayer = chart.series.values.find(
                      (v) => v.axisRanges.values.length > 0
                    );
                    let chartRange = audioPlayer.axisRanges.values[0].dates;
                    let startDate = moment(chartRange.date).valueOf();
                    let currentAudioPosition = Math.floor(
                      e.target.currentTime * 1000
                    );

                    chart.xAxes.values[0].axisRanges.values[0].value =
                      startDate + currentAudioPosition;
                    chart.xAxes.values[0].axisRanges.values[1].value =
                      startDate + currentAudioPosition;
                  });
                });
              });
            });
          });
        });
      })
      .catch((error) => {
        return error;
      });

    const getActiveAttendees = new Request(
      myServer + "/api/v1/getActiveAttendees",
      {
        method: "GET",
        headers:
          ({ "Content-Type": "application/json" },
          { eventID: this.props.eventID }),
      }
    );
    fetch(getActiveAttendees).then((response) => {
      response.json().then((res) => {
        this.setState({
          attendees: res.attendees,
          numberofActiveAttendees: res.attendees.length,
        });
      });
    });
    const getAllAttendees = new Request(myServer + "/api/v1/attendees", {
      method: "GET",
      headers:
        ({ "Content-Type": "application/json" },
        { eventID: this.props.eventID }),
    });
    fetch(getAllAttendees).then((response) => {
      response.json().then((res) => {
        this.setState({
          numberOfAttendees: res.length,
        });
      });
    });

    var audioPlayer = document.getElementById("chart-audio");
  }

  helper = (epochTime) => {
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(epochTime);
    var convertToTimeZone = d.toLocaleString("en-US", {
      timeZone: this.state.timeZone,
    });

    var sliceString = convertToTimeZone.split(",");

    var timeArray = sliceString[1].split(":");
    var seconds = timeArray[2].split(" ");

    var dateArray = sliceString[0].split("/");
    var returnArray = [dateArray, timeArray, seconds];
    return returnArray;
  };

  // Function for creating the data graph, receiving the ratings per second with comments
  createGraph = (ratingsPersecond) => {
    let self = this;

    // instance amCharts
    var myChart = am4core.create("chartdiv", am4charts.XYChart);
    myChart.logo.disabled = true;
    myChart.dateFormatter.dateFormat = "hh:mm:ss a";

    // Style Zoom out button
    myChart.zoomOutButton.background.fill = am4core.color("#2ea39e");
    myChart.zoomOutButton.background.states.getKey(
      "hover"
    ).properties.fill = am4core.color("#2c9d98");
    myChart.zoomOutButton.background.states.getKey(
      "down"
    ).properties.fill = am4core.color("#2c9d98");

    am4core.options.autoSetClassName = true;
    let comments = [];
    let ratings = [];
    let duration = [];

    // Get Ratings
    for (let i = 1; i < ratingsPersecond.length; i++) {
      let y = ratingsPersecond[i].rating;
      let newDate = moment.unix(ratingsPersecond[i].epoch).toDate();
      let ratingColor = "#ef6d53";

      ratings.push({
        date: newDate,
        rating: y,
        color: ratingColor,
        lineColor: "#ef6d53",
      });
    }

    // Get Audio timeTrack
    for (let i = 1; i < ratingsPersecond.length; i++) {
      if (i === 1) {
        duration.push({
          y: 100,
          date: moment.unix(ratingsPersecond[i].epoch).toDate(),
        });
      }
      if (i + 1 === ratingsPersecond.length) {
        duration.push({
          y: 100,
          date: moment.unix(ratingsPersecond[i].epoch).toDate(),
        });
      }
    }

    // Get comments in a separated array
    for (let i = 1; i < ratingsPersecond.length; i++) {
      let y = ratingsPersecond[i].rating;
      let newDate = moment.unix(ratingsPersecond[i].epoch).toDate();
      let commentText = "";
      let commentID = "";

      for (let j = 0; j < ratingsPersecond[i].comments.length; j++) {
        commentText = ratingsPersecond[i].comments[j].comment + "\n";
        commentID = ratingsPersecond[i].comments[j].id;
        if (ratingsPersecond[i].comments.length > 0) {
          comments.push({
            id: commentID,
            date: newDate,
            y,
            comments: commentText,
            color: "#2ea39e",
            lineColor: "#0000",
          });
        }
      }
    }

    myChart.data = ratings;
    myChart.language.locale = am4lang_en_US;

    let timeAxis = myChart.xAxes.push(new am4charts.DateAxis());
    timeAxis.renderer.tooltipLocation = 2;
    timeAxis.startLocation = 0.5;
    timeAxis.endLocation = 0;
    timeAxis.renderer.minGridDistance = 120;
    timeAxis.title.fontWeight = "bold";
    timeAxis.marginRight = 20;
    timeAxis.tooltipDateFormat = "hh:mm:ss aa";
    timeAxis.title.text = "TIME";
    timeAxis.events.on("inited", calculateCollisions);
    timeAxis.events.on("startendchanged", recalculateCollisions);

    // Push Comments into the Section Array
    function pushCommentsToSections(sections, comments) {
      const sectionsCopy = sections;
      sectionsCopy.forEach((section, index) => {
        comments.forEach((comment, i) => {
          const date = moment(comment.date).valueOf();
          const start = section.start;
          const end = section.end;

          if (date >= start && date <= end) {
            if (section.comments.length > 0) {
              section.size = 12;
            } else {
              section.size = 8;
            }
            section.y = comment.y;
            section.comments.push(comment);
          }
        });
      });
      // get the first comment position by section
      sectionsCopy.forEach((section) => {
        if (section.comments.length > 0) {
          section.start = moment(section.comments[0].date).valueOf();
          section.position = section.comments[0].date;
          section.y = section.comments[0].y;
        }
      });

      let sectionsWithComments = sectionsCopy.filter(function (e) {
        return e.comments.length > 0;
      });

      return sectionsWithComments;
    }

    function recalculateCollisions(ev) {
      if (self.state.commentsVisible) {
        if (myChart.series.indexOf(2)) {
          myChart.series.removeIndex(2);
          calculateCollisions(ev);
        }
      }
    }

    function calculateCollisions(ev) {
      const BULLET_WIDTH = 50;
      let availableWidth = ev.target.contentWidth;
      let totalSections = Math.floor(availableWidth / BULLET_WIDTH);

      var start = moment(ev.target.minZoomed);
      var end = moment(ev.target.maxZoomed);

      let totalTimeinZoom = end.diff(start);

      let sectionSize = totalTimeinZoom / totalSections;

      self.setState({
        commentSections: [],
      });

      let counterHelper = start.valueOf();

      for (let i = 0; i < totalSections; i++) {
        let start = counterHelper;
        let end = counterHelper + sectionSize;
        let position = moment(end).toDate();
        counterHelper = end;

        let block = {
          hasMultiple: false,
          start,
          end,
          color: "#2ea39e",
          y: 10,
          position,
          comments: [],
        };

        self.setState((prevState) => ({
          commentSections: [...prevState.commentSections, block],
        }));
      }

      let commentsData = pushCommentsToSections(
        self.state.commentSections,
        comments
      );
      addComments(myChart, commentsData);
    }

    let ratingAxis = myChart.yAxes.push(new am4charts.ValueAxis());
    ratingAxis.max = 100;
    ratingAxis.min = 0;
    ratingAxis.marginLeft = 0;
    ratingAxis.title.text = "INTEREST";
    ratingAxis.title.fontWeight = "bold";
    ratingAxis.cursorTooltipEnabled = false;
    ratingAxis.renderer.grid.template.disabled = true;
    ratingAxis.renderer.labels.template.disabled = true;
    let top = ratingAxis.axisRanges.create();
    top.value = 95;
    top.label.text = "+";
    top.label.fontWeight = 800;
    top.label.fontSize = 40;
    top.label.fill = am4core.color("#ef6d53");
    let bottom = ratingAxis.axisRanges.create();
    bottom.value = 5;
    bottom.label.text = "–";
    bottom.label.fontWeight = 800;
    bottom.label.fontSize = 40;
    bottom.label.fill = am4core.color("#2ea39e");

    //----------
    // Add Ratings
    //----------

    let series = myChart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "rating";
    series.strokeWidth = 2;
    series.propertyFields.stroke = "lineColor";
    series.propertyFields.fill = "lineColor";

    // Average or "Overall" Trend line.
    let averageRatingPerSecond = this.state.averageRatingPerSecond;
    let trend = myChart.series.push(new am4charts.LineSeries());
    trend.dataFields.dateX = "date";
    trend.dataFields.valueY = "rating";
    trend.strokeWidth = 2;
    let color = "#4f4f50";
    trend.stroke = am4core.color(color);
    if (ratings && ratings.length > 0) {
      let dataPoint = { rating: 50, color, lineColor: color };
      trend.data = [
        { date: ratings[0].date, ...dataPoint },
        { date: ratings[ratings.length - 1].date, ...dataPoint },
      ];
    }
    let segment = trend.segments.template;
    segment.interactionsEnabled = true;
    let hs = segment.states.create("hover");
    hs.properties.strokeWidth = 8;
    trend.tooltipText = `50%`;
    trend.tooltip.getFillFromObject = false;
    trend.tooltip.label.fill = am4core.color(color);
    trend.tooltip.background.fill = am4core.color("#fff");
    trend.tooltip.label.fontSize = 20;
    trend.tooltip.label.fontWeight = 400;
    trend.tooltip.label.textAlign = "middle";

    myChart.scrollbarX = new am4charts.XYChartScrollbar();
    myChart.scrollbarX.startGrip.background.fill = am4core.color("#ef6d53");
    myChart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
    myChart.scrollbarX.endGrip.background.fill = am4core.color("#ef6d53");
    myChart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
    myChart.scrollbarX.background.fill = am4core.color(color);
    myChart.scrollbarX.background.fillOpacity = 1;
    let percent = am4core.percent(100);
    myChart.scrollbarX.background.cornerRadius(
      percent,
      percent,
      percent,
      percent
    );

    function customizeGrip(grip, flip = false) {
      grip.icon.disabled = true;
      grip.background.disabled = true;
      let img = grip.createChild(am4core.Circle);
      img.width = 30;
      img.height = 30;
      img.fill = am4core.color("#ef6d53");
      img.align = "center";
      img.valign = "middle";
      let line = grip.createChild(am4core.Triangle);
      line.height = 6;
      line.dx = flip ? -1 : 1;
      line.width = 10;
      line.rotation = flip ? -90 : 90;
      line.fill = am4core.color("#fff");
      line.align = "center";
      line.valign = "middle";
    }
    customizeGrip(myChart.scrollbarX.startGrip, true);
    customizeGrip(myChart.scrollbarX.endGrip);

    let container = myChart.scrollbarX.createChild(am4core.Container);
    container.width = am4core.percent(100);

    let label = container.createChild(am4core.Label);
    label.isMeasured = false;
    label.text = "Adjust the arrows to zoom";
    label.fontWeight = 600;
    label.fill = am4core.color("#fff");
    label.x = am4core.percent(50);
    label.y = 20;
    label.horizontalCenter = "middle";
    label.align = "center";

    //-------------
    // Add Audio Timeline
    //-------------
    let audioSeries = myChart.series.push(new am4charts.LineSeries());
    audioSeries.data = duration;
    audioSeries.fillOpacity = 0.1;
    audioSeries.fill = "#ef6d53";
    audioSeries.dataFields.dateX = "date";
    audioSeries.dataFields.valueY = "y";

    var seriesRange = timeAxis.createSeriesRange(audioSeries);
    seriesRange.contents.strokeDasharray = "2,3";
    seriesRange.contents.stroke = "#ef6d53";
    seriesRange.contents.strokeWidth = 1;

    var pattern = new am4core.LinePattern();
    pattern.rotation = -45;
    pattern.stroke = seriesRange.contents.stroke;
    pattern.width = 1000;
    pattern.height = 1000;
    pattern.gap = 6;
    seriesRange.contents.fill = pattern;
    seriesRange.contents.fillOpacity = 0.1;

    // add range
    var range = timeAxis.axisRanges.push(new am4charts.DateAxisDataItem());
    range.grid.stroke = "#ef6d53";
    range.grid.strokeOpacity = 1;
    range.bullet = new am4core.ResizeButton();
    range.bullet.background.fill = "#ef6d53";
    range.bullet.background.states.copyFrom(
      myChart.zoomOutButton.background.states
    );
    range.bullet.minX = 0;
    range.bullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    range.bullet.zIndex = 9999;
    range.bullet.adapter.add("minY", function (minY, target) {
      target.maxY = myChart.plotContainer.maxHeight;
      target.maxX = myChart.plotContainer.maxWidth;
      return myChart.plotContainer.maxHeight;
    });

    var firstTime = myChart.data.length > 0 ? myChart.data[0].date.getTime() : '';
    // var lastTime = myChart.data[myChart.data.length - 1].date.getTime();
    var date = new Date(firstTime);

    range.bullet.events.on("dragged", function () {
      range.value = timeAxis.xToValue(range.bullet.pixelX);
      seriesRange.value = range.value;
    });
    range.bullet.events.on("dragstop", function () {
      let calculatedTime =
        (range.value - moment(range.dates.date).valueOf()) / 1000;
      self.audioRef.current.currentTime = calculatedTime;
    });

    range.date = date;

    seriesRange.date = date;
    seriesRange.endDate = myChart.data.length > 0 ? myChart.data[myChart.data.length - 1].date : date;

    //-------------
    // Add Comments
    //-------------

    function addComments(chart, data) {
      var commentSeries = chart.series.push(new am4charts.LineSeries());

      commentSeries.data = data;

      // Tooltip helper
      let commentsArray = [];
      data.forEach((section) => {
        commentsArray.push(section.comments);
      });

      commentSeries.dataFields.dateX = "position";
      commentSeries.dataFields.valueY = "y";
      commentSeries.propertyFields.fill = "color";
      commentSeries.dataFields.children = "comments";
      commentSeries.interpolationDuration = 0;
      commentSeries.strokeWidth = 0;

      var bullet = commentSeries.bullets.push(new am4charts.CircleBullet());
      var circle = bullet.createChild(am4core.Circle);
      circle.propertyFields.radius = "size";
      circle.strokeWidth = 1;
      circle.stroke = am4core.color("#2ea39e");
      circle.propertyFields.fill = "color";
      var bullethover = bullet.states.create("hover");
      bullethover.properties.scale = 1.2;

      bullet.tooltipHTML = `<div class="mb-2 comments-header">Comments</div>`;
      // Set Custom Tooltip
      bullet.adapter.add("tooltipHTML", function (html, target) {
        let nameCells = "";
        let firstCommentID = "";
        if (
          target.tooltipDataItem.dataContext &&
          target.tooltipDataItem.dataContext.comments &&
          target.tooltipDataItem.dataContext.comments.length
        ) {
          target.tooltipDataItem.dataContext.comments.forEach(
            (comment, index) => {
              let commentText = "";
              let commentId = comment.id;

              if (index === 0) {
                firstCommentID = commentId;
              }

              if (index >= 2) {
                return false;
              }

              // If comment length is greater than 160 char, ellipsis at the end
              if (comment.comments.length > 160) {
                commentText = comment.comments.substring(0, 160) + "...";
              } else {
                commentText = comment.comments;
              }

              nameCells += `<a class="comments-item" href="#${commentId}"><div class="mb-1">"${commentText.trim()}"</div>`;
            }
          );
          html += `<div class="comments-container">`;
          html += `${nameCells}`;
          if (target.tooltipDataItem.dataContext.comments.length > 2) {
            html += `<a href="#${firstCommentID}"><div class="comments-footer-button">more</div></a>`;
          }
          html += `</div>`;
        }
        return html;
      });

      // Style Tooltip
      commentSeries.tooltip.getFillFromObject = false;
      commentSeries.tooltip.background.fill = am4core.color("#fff");
      commentSeries.tooltip.background.fillOpacity = 1;
      commentSeries.tooltip.background.cornerRadius = 0;
      commentSeries.tooltip.pointerOrientation = "down";
      commentSeries.tooltip.label.interactionsEnabled = true;
      commentSeries.tooltip.keepTargetHover = true;
      commentSeries.tooltip.dy = -10;
    }

    myChart.cursor = new am4charts.XYCursor();
    myChart.cursor.snapToSeries = []; // This allows me to unsnap the tooltips to the cursor
    myChart.cursor.lineX.strokeWidth = 4;
    myChart.cursor.lineX.strokeOpacity = 0.2;
    myChart.cursor.lineX.strokeDasharray = "";
    myChart.cursor.lineY.disabled = true;
    myChart.cursor.behavior = "none";
    var cursorPosition = null;
    myChart.cursor.events.on("cursorpositionchanged", function (ev) {
      let xAxis = ev.target.chart.xAxes.getIndex(0);
      cursorPosition = xAxis.positionToDate(
        xAxis.toAxisPosition(ev.target.xPosition)
      );
    });
    myChart.events.on("down", function (ev) {
      let calculatedTime =
        (moment(cursorPosition).valueOf() - moment(date).valueOf()) / 1000;
      if (
        calculatedTime &&
        Number(calculatedTime) === calculatedTime &&
        calculatedTime.isNan &&
        !calculatedTime.isNaN()
      ) {
        self.audioRef.current.currentTime = calculatedTime;
      }
    });

    myChart.maxWidth = 1519;
    this.myChart = myChart;
  };

  toggleChartComments = () => {
    const allSeries = this.myChart.series;
    const series = allSeries.getIndex(allSeries.length - 1);
    if (this.state.commentsVisible) {
      this.setState({
        commentsVisible: false,
      });
      series.hide();
    } else {
      if (series.isHiding || series.isHidden) {
        this.setState({
          commentsVisible: true,
        });
        series.show();
      }
    }
  };

  //Sort by attendee
  handleSelectedAttendee = (e) => {
    let self = this;
    var attendee = e.target.value;
    if (attendee !== "All Attendees") {
      self.setState({
        attendee: attendee,
      });
    } else if (attendee === "All Attendees") {
      self.setState({
        attendee: "All Attendees",
      });
    }
  };

  //AudioPlayer Handler (Play/Pause)
  audioPlayerHandler = () => {
    const AudioPlayer = this.audioRef.current;
    if (this.state.isAudioPlaying) {
      this.setState({
        isAudioPlaying: false,
      });
      AudioPlayer.pause();
    } else {
      this.setState({
        isAudioPlaying: true,
      });
      AudioPlayer.play();
    }
  };

  componentDidUpdate(prevProp, prevState) {
    let self = this;
    if (this.state.attendee !== prevState.attendee) {
      var attendee = this.refs.selectedAttendee.value;
      if (attendee !== "All Attendees") {
        var request = new Request(myServer + "/api/v1/getAttendeePoints", {
          method: "GET",
          headers:
            ({ "Content-Type": "application/json" }, { attendeeID: attendee }),
        });
        fetch(request).then((response) => {
          response.json().then((res) => {
            self.myChart.dispose();
            self.createGraph(res.attendee);
          });
        });
      }
      if (attendee === "All Attendees") {
        self.myChart.dispose();

        self.createGraph(self.state.averageRatingsPerSecond);
      }
    }
  }

  render() {
    var Display;
    const {
      startTime,
      endTime,
    } = this.state;
    const endTimeEpoch = new Date(endTime).getTime() / 1000;
    const startTimeEpoch = new Date(startTime).getTime() / 1000;
    const seconds = Math.max(0, endTimeEpoch - startTimeEpoch);
    const length = seconds < 60 ? `${seconds} seconds` : `${Math.floor(seconds/60)} mins`
    Display = (
      <div className="m-3" id="wrapper">
        <div>
          <label>
            <h3 className="d-inline mb-3">
              {" "}
              <strong>
                Event Results
              </strong>
            </h3>
          </label>
          <EventTitle>
            {this.state.title}
          </EventTitle>
          <EventPresenterName>
            PRESENTED BY: {this.state.presenterName}
          </EventPresenterName>
          <div>
            <EventStats>
              Attendees: {this.state.attendees.length}
            </EventStats>
            <EventStats>
              Length: {length}
            </EventStats>
            <EventOverallScore className="float-right">
              Captured Score: {this.state.averageRatingPerSecond}%
            </EventOverallScore>
          </div>
        </div>
        <div className="wrapper">
            <React.Fragment>
              <div style={{ minHeight: "500px" }} id="chartdiv"></div>
            </React.Fragment>
            <div style={{ marginTop: "1rem" }}className="row">
              <PlayPauseButton className="ml-5" onClick={this.audioPlayerHandler}>
                {this.state.isAudioPlaying ? (
                    <img src={Pause} alt="" />
                ) : (
                    <img className="playpause-btn__play" src={Play} alt="" />
                )}
              </PlayPauseButton>
              <CommentsToggleContainer className="col align-items-center d-flex">
                <Label className="mr-4 mb-0 pr-lg-4">Comments visible?</Label>
                <Switch
                    type="switch"
                    id="commentSwitch"
                    label=""
                    checked={this.state.commentsVisible}
                    onChange={this.toggleChartComments}
                />
              </CommentsToggleContainer>
            </div>
            <div className="row">
              <audio
                ref={this.audioRef}
                className="d-none"
                src={this.state.audioSrc}
                autobuffer="autobuffer"
                preload="metadata"
                id="chart-audio"
                controls
              ></audio>
            </div>

          {/* Comments Timeline */}
          {this.state.Comments.length > 0 ? (
            <CommentListTitle className="mb-3">
              <strong>Comments</strong>
            </CommentListTitle>
          ) : null}
          <CommentsList>
            {this.state.Comments.map((attendee, index) => (
              <li
                id={attendee.commentID}
                className="comments-timeline__item"
                key={`${attendee.time}-${index}`}
              >
                <div className="comments-timeline__date">
                  {moment(attendee.time).format("hh:mm:ss A")}
                </div>
                <div className="comments-timeline__content">
                  <p className="m-0">
                    <strong>Attendee {attendee.attendeeIDNormalized}: </strong>{" "}
                    {attendee.comment}
                  </p>
                </div>
              </li>
            ))}
          </CommentsList>

          <React.Fragment>
            {!!this.state.survey && this.state.Answers.length && (
              <div id="myscrollDiv" className="ml-5 mt-3">
                <h3 className="mb-4">Survey Stats</h3>
                <div className="d-flex justify-content-between">
                  {this.state.Answers.map((question, index) => (
                    <SurveyChart
                      answers={question.answers}
                      index={index}
                      title={question.question}
                    />
                  ))}
                </div>
              </div>
            )}
          </React.Fragment>
        </div>
      </div>
    );

    return (
      <DefaultLayout>
        <React.Fragment>
          <Container>{Display}</Container>
        </React.Fragment>
      </DefaultLayout>
    );
  }
}
function mapStateToProps(state) {
  return {
    isAdmin: state.isAdmin,
    logged_in: state.session,
    loggedOut: state.loggedOut,
    companyID: state.companyID,
    userZone: state.userZone,
    creditScore: state.creditScore,
    eventID: state.eventID,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewEvent);
