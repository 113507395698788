import React, { useState, useEffect } from "react";

// Components
import {
  FormQuestion,
  QuestionOption,
  QuestionAddOption,
} from "./SurveyForm.styles";
import { Col, Form, InputGroup } from "react-bootstrap";
import { Input, Button } from "../Bootstrap";



export default function SurveyForm(props) {
  // States
  const [questionForm, setQuestionForm] = useState(true);
  const [questionText, setQuestionText] = useState(""); //Question Title
  const [questionOption, setQuestionOption] = useState(""); //Single Question Answer
  const [questionOptions, setQuestionOptions] = useState([]); //Set of Question Answers

  //Transitional States
  const [editingQuestion, setEditingQuestion] = useState(false);
  const [focusedQuestion, setFocusedQuestion] = useState(null);

  const [editingAnswer, setEditingAnswer] = useState(false);
  const [focusedAnswer, setFocusedAnswer] = useState(null);
  const [focusedAnswerValue, setFocusedAnswerValue] = useState("");
  const [answerEditingError, setAnswerEditingError] = useState("");

  const [questionTextError, setQuestionTextError] = useState(false);
  const [questionOptionError, setQuestionOptionError] = useState(false);
  const [questionOptionError2, setQuestionOptionError2] = useState(false);
  const [questionOptionError3, setQuestionOptionError3] = useState(false); //If there is a duplicated option
  const [questionOptionsError, setQuestionOptionsError] = useState(false);
  const [questionOptionErrorMaxOptions, setQuestionOptionErrorMaxOptions] = useState(false);

  //set from component props and dotenv
  const [maxQuestions, setMaxQuestions] = useState(3);
  const [minAnswers, setMinAnswers] = useState(2);
  const [maxAnswers, setMaxAnswers] = useState(5);

  const handleQuestionChange = (e, questionIndex, textValue) => {
    let newQuestions = [...props.questions];
    newQuestions[questionIndex].text = textValue;

    if (textValue != '' && questionIndex === (newQuestions.length - 1) && newQuestions.length < maxQuestions) {
      let newQuestion = {
        id: '',
        text: '',
        type: 'Multiple Choice',
        options: [{
          id: '',
          text: ''
        }]
      };

      newQuestions.push(newQuestion);
    }
    if (textValue == '' && questionIndex === (newQuestions.length - 2) && newQuestions[questionIndex + 1] == '') {
      newQuestions.pop();
    }

    props.setQuestions(newQuestions);
  }

  const handleQuestionOptionChange = (e, questionIndex, optionIndex, textValue) => {
    let newQuestions = [...props.questions];
    newQuestions[questionIndex].options[optionIndex].text = textValue;
    if (textValue != '' && optionIndex === (newQuestions[questionIndex].options.length - 1) && newQuestions[questionIndex].options.length < maxAnswers) {
      newQuestions[questionIndex].options.push({
        id: '',
        text: ''
      });
    }
    if (textValue == '' && optionIndex === (newQuestions[questionIndex].options.length - 2) && newQuestions[questionIndex].options[optionIndex + 1].text == '') {
      newQuestions[questionIndex].options.pop();
    }
    props.setQuestions(newQuestions);
  }

  const handleQuestionBlur = (e, questionIndex, textValue) => {
    let newQuestions = [...props.questions];
    newQuestions[questionIndex].text = textValue;
    if (textValue === '' && questionIndex > 0 && questionIndex < (newQuestions.length - 1) && newQuestions[questionIndex].options.length < 2) {
      newQuestions.splice(questionIndex, 1);
    }
    props.setQuestions(newQuestions);
  }

  const handleQuestionOptionBlur = (e, questionIndex, optionIndex, textValue) => {
    let newQuestions = [...props.questions];
    newQuestions[questionIndex].options[optionIndex].text = textValue;
    if (textValue === '' && optionIndex < (newQuestions[questionIndex].options.length - 1)) {
      newQuestions[questionIndex].options.splice(optionIndex, 1);
    }
    props.setQuestions(newQuestions);
  }

  useEffect(() => {
    //load defaults from dot-env file and read meta information from subscription
    const defaultMaxQuestions = props.subscription.metadata.survey_questions ? parseInt(props.subscription.metadata.survey_questions) : 3;
    const defaultMinAnswers = process.env.REACT_APP_DEFAULT_MIN_ANSWERS != null ? parseInt(process.env.REACT_APP_DEFAULT_MIN_ANSWERS) : 2;
    const defaultMaxAnswers = process.env.REACT_APP_DEFAULT_MAX_ANSWERS != null ? parseInt(process.env.REACT_APP_DEFAULT_MAX_ANSWERS) : 5;
    setMaxQuestions(defaultMaxQuestions);
    setMinAnswers(defaultMinAnswers);
    setMaxAnswers(defaultMaxAnswers);

    if (props.questions.length) {
      props.questions.forEach((question) => {
        let questionOptions = [];
        question.options.forEach((option) => {
          questionOptions.push({
            id: option.id,
            text: option.text
          });
        });
        question.options = questionOptions;
      });

      props.setQuestions(props.questions);
    } else {
      let newQuestions = [{
        text: '',
        type: 'Multiple Choice',
        options: [{
          id: '',
          text: ''
        }]
      }];

      props.setQuestions(newQuestions);
    }
  }, []);

  return (
    <Form.Group>
      <Form.Row>
        <Col md={6} className="mb-4 d-flex flex-column">

          {props.questions.map((question, questionIndex) => (
            <FormQuestion key={questionIndex}>
              <p className="form-question__item">{`Question ${questionIndex + 1}`}</p>

              <Input
                name="questionTitle"
                placeholder="Add Question"
                variant="alt"
                autoComplete="off"
                value={question.text}
                onChange={(e) => handleQuestionChange(e, questionIndex, e.target.value)}
                onBlur={(e) => handleQuestionBlur(e, questionIndex, e.target.value)}
              />
    
              <ol className="form-question__options" type="a">
                {question.options.map((option, optionIndex) => (
                  <li key={optionIndex}>
                    <QuestionOption className={`option-${questionIndex}-${optionIndex}`}>

                      <InputGroup><Input
                        placeholder="Add Answer"
                        id={`answer-${optionIndex}`}
                        variant="alt"
                        autoComplete="off"
                        value={option.text}
                        className="answer-option"
                        onBlur={(e) => handleQuestionOptionBlur(e, questionIndex, optionIndex, e.target.value)}
                        onChange={(e) => handleQuestionOptionChange(e, questionIndex, optionIndex, e.target.value)}
                        name="`{answerOption-${optionIndex}`"
                        type="text"
                      /></InputGroup>

                    </QuestionOption>

                  </li>
                ))}
              </ol>
              {question.text != '' && question.options.length < 3 && (question.options[0].text == '' || (question.options.length > 1 && question.options[1].text == '')) && (
                <small className="text-danger">A question must have at least {minAnswers} answers</small>
              )}
            </FormQuestion>
          ))}
        </Col>
      </Form.Row>
    </Form.Group>
  );


}
