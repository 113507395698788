import React, {useState} from 'react';
import {NavLink} from "react-bootstrap";
import * as CartService from "utils/cart.service";
import {ProductMetaTable} from './product-meta-table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faMinus} from '@fortawesome/free-solid-svg-icons';

// this component renders the information about different
// subscription products, data from props

export function ProductCard(props) {
  const includedUsers = +props.product.metadata.included_users;
  const maxUsers = +props.product.metadata.max_users;
  const [users, setUsers] = useState(includedUsers + (props.extraUsers ? Number(props.extraUsers) : 0));
  const [extraUsers, setExtraUsers] = useState(props.extraUsers ? Number(props.extraUsers) : 0);
  const price = CartService.getValidPrices(props.interval, props.product.prices);
  const priceYear = CartService.getValidPrices('year', props.product.prices).tiers;
  const planBasePrice = price.tiers[0].flat_amount
    ? (price.tiers[0].flat_amount / 100) //if tier has flat_amount for base
    : (price.tiers[1].unit_amount / 100); //else use next tier unit_amount as base
  const extraUserPrice = (price.tiers[1].unit_amount / 100);

  const ShowLinks = () => {
    return (!props.price
      ? <>
        <NavLink
          to="/profile/cart/prices"
          href={`/profile/cart/prices`}
          className="btn btn-outline-primary rounded-0"
          onClick={() => {CartService.selectProduct(props.product, extraUsers);}}
        >
          Select {props.product.name}
        </NavLink>
        <NavLink
          to="/profile/cart/prices"
          href={`/profile/cart/prices`}
          onClick={() => {CartService.selectProduct(props.product, extraUsers);}}
        >
          Or pay ${((+priceYear[0].flat_amount / 100) + (extraUsers * (priceYear[1].unit_amount / 100))).toFixed(2).replace(/[.,]00$/, "")} for a year and save 15%
        </NavLink>
      </>
      : <NavLink
        to="/profile/cart/payment"
        href={`/profile/cart/payment`}
        className="btn btn-outline-primary rounded-0"
        onClick={() => {
          CartService.selectPrice(props.price, extraUsers);
          CartService.selectProduct(props.product, extraUsers);
        }}
      >
        Select
      </NavLink>
    );
  };

  const ShowAddUsers = () => {
    return (props.addUsers
      ? <>
        <div className='user-pick'>
          <button
            disabled={users === includedUsers}
            onClick={() => {
              setUsers(users - 1);
              setExtraUsers(extraUsers - 1);
            }}
          >
            <FontAwesomeIcon icon={faMinus}></FontAwesomeIcon>
          </button>
          <p>{users} user{users > 1 ? 's' : ''}</p>
          <button
            disabled={users === maxUsers}
            onClick={() => {
              setUsers(users + 1);
              setExtraUsers(extraUsers + 1);
            }}
          >
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
          </button>
        </div>
      </>
      : <div className='user-pick'>
        <p>with {users} users</p>
      </div>
    );
  };

  return (
    <>
      <div key={props.product.id} className={`card border-0 rounded-0 plan-${props.product.name.toLowerCase()}`}>
        <div className="card-body text-center">
          <h3 className="card-title">{props.product.name}</h3>
          <div className="card-subtitle">
            <p>
              ${(planBasePrice + (extraUsers * extraUserPrice)).toFixed(2).replace(/[.,]00$/, "")}
              <span>/{price.recurring.interval}</span>
            </p>
            {ShowAddUsers()}
          </div>
          {(Object.entries(props.product.metadata)).length
            ? ProductMetaTable(props.product.metadata) : ''
          }
          {(props.buttons !== false)
            ? ShowLinks() : ''
          }
        </div>
      </div>
    </>
  );
}
