import {
  LOG_IN_SUCCESS_COMPANY,
  ADD_CARD,
  SET_EVENT_ATTENDEE_COUNTS,
} from "./actionTypes";
import { UPDATE_SUCCESS } from "./actionTypes";
import { LOG_OUT } from "./actionTypes";
import { LOG_IN_FAILED } from "./actionTypes";
import { LOG_IN_SUCCESS_USER } from "./actionTypes";
import { ADD_EVENT } from "./actionTypes";
import { MODIFY_EVENT } from "./actionTypes";
import { VIEW_EVENT } from "./actionTypes";
import { MENU_COLLAPSED } from "./actionTypes";

import * as StorageService from 'utils/storage.service';

var myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

export function menuCollapse(state) {
  return { type: MENU_COLLAPSED, menuCollapsed: state };
}

export function AddCard(payload) {
  return function (dispatch) {
    const customerRequest = new Request(myServer + "/api/v1/payment", {
      method: "POST",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
        cardtoken: payload.paymentMethod.id,
      }),
    });
    fetch(customerRequest).then((response) => {
      response.json().then((res) => {
        if (res.message === "success") {
          dispatch(paymentSuccess());
        }
      });
    });
  };
}
export function paymentSuccess() {
  return { type: ADD_CARD, payment: 1 };
}

export function loginSuccess(payload, credit, payment, isViewingAsAdmin) {
  return {
    type: LOG_IN_SUCCESS_COMPANY,
    payload: payload,
    credit: credit,
    payment: payment,
    isViewingAsAdmin,
  };
}
export function loginSuccessUser(payload, credit, role, email, payment, isViewingAsAdmin) {
  return {
    type: LOG_IN_SUCCESS_USER,
    payload: payload,
    credit: credit,
    role: role,
    email: email,
    payment: payment,
    isViewingAsAdmin,
  };
}

export function loginFailed(response) {
  return { type: LOG_IN_FAILED, response: response };
}

export function logInUser(user, completion) {
  return function (dispatch) {
    var code;
    if (user.email) {
      code = btoa(user.email + ":" + user.password);
    } else {
      code = btoa(user.token + ":" + user.password);
    }

    const request = new Request(myServer + "/api/v1/login", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + code,
      },
      body: JSON.stringify(user),
    });

    fetch(request)
      .then(response => response.json())
      .then(response => StorageService.add('user', response))
      .then((res) => {
        var isThereCard = 0;
        var respond;
        var storeRedux = JSON.parse(localStorage.getItem("reduxState"));
        if (res.message === "company success") {
          storeRedux.loggedOut = false;
          localStorage.setItem("reduxState", JSON.stringify(storeRedux));
          localStorage.setItem("signedIn", true);

          if (res.payment !== null) {
            isThereCard = 1;
          }
          return dispatch(
            loginSuccess(res.userZone, res.credit, isThereCard, res.isViewingAsAdmin)
          );
        } else if (res.message === "user success") {
          var isThereCard2 = 0;
          storeRedux.loggedOut = false;
          localStorage.setItem("reduxState", JSON.stringify(storeRedux));
          localStorage.setItem("signedIn", true);

          if (res.payment !== null) {
            isThereCard2 = 1;
          }
          return dispatch(
            loginSuccessUser(
              res.userZone,
              res.credit,
              res.role,
              res.email,
              isThereCard2,
              res.isViewingAsAdmin,
            )
          );
        } else if (res.message === "account not activated") {
          return dispatch(loginFailed("account not activated"));
        } else if (res.message === "false email") {
          return dispatch(loginFailed("false email"));
        } else if (res.message === "wrong password") {
          return dispatch(loginFailed("wrong password"));
        } else if (res.message === "Empty password") {
          return dispatch(loginFailed("Empty password"));
        } else if (res.message === "Deactivated account") {
          return dispatch(loginFailed("Deactivated account"));
        } else if (res.message === "user not found") {
          return dispatch(loginFailed("user not found"));
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}
export function logOutSuccess() {
  return function (dispatch) {
    localStorage.removeItem("signedIn");
    const request = new Request(myServer + "/api/v1/logOut", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    });
    fetch(request).then((response) => {
      response.json().then((res) => {
        StorageService.remove('user');
        dispatch(logOut());
      });
    });
  };
}
export function logOut() {
  return { type: LOG_OUT };
}

export function updatesuccss(userZone) {
  return { type: UPDATE_SUCCESS, userZone: userZone };
}

export function updateUser(user, fd) {
  return function (dispatch) {
    const request = new Request(myServer + "/api/v1/Profile", {
      method: "PUT",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ auth: user }),
    });

    if (fd === null) {
      return fetch(request)
        .then((response) => {
          return response.json().then((res) => {
            if (res.message === "Updated") {
              dispatch(updatesuccss(user.TimeZone));
            }
          });
        })
        .catch((error) => {
          return error;
        });
    } else {
      const request2 = new Request(myServer + "/api/v1/Profile/image", {
        method: "PUT",
        credentials: "include",
        headers: new Headers({
          typeOfUpload: "profile",
        }),
        body: fd,
      });

      var apiRequest = fetch(request)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          return error;
        });
      var apiRequest2 = fetch(request2)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          return error;
        });
      var combinedData = { apiRequest1: {}, apiRequest2: {} };
      return Promise.all([apiRequest, apiRequest2]).then(function (values) {
        combinedData["apiRequest1"] = values[0];
        combinedData["apiRequest2"] = values[1];

        if (
          combinedData["apiRequest1"].message === "Updated" ||
          combinedData["apiRequest2"].message === "Updated"
        ) {
          dispatch(updatesuccss(user.TimeZone));
        }
      });
    }
  };
}
export function updatePassword(user, fd) {
  return function (dispatch) {
    const request = new Request(myServer + "/api/v1/changePass", {
      method: "POST",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ auth: user }),
    });

    return fetch(request)
      .then((response) => {
        return response.json().then((res) => {
          return res.message;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function UpdateScore(cost) {
  return function (dispatch) {
    let token = localStorage.getItem("jwt");
    const request = new Request(myServer + "/api/v1/credit", {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        token: token,
        cost: cost,
      }),
    });
    fetch(request)
      .then((response) => {
        response.json().then((res) => {
          if (res.message === "updated") {
            dispatch(updateScore(res.credit));
          }
        });
      })
      .catch((error) => {
        return error;
      });
  };
}
export function updateScore(score) {
  return { type: ADD_EVENT, credit: score };
}

export function modifyEvent(eventID) {
  return function (dispatch) {
    dispatch(getEventID(eventID));
    return true;
  };
}
export function view(eventID) {
  return function (dispatch) {
    dispatch(dispatchViewEvent(eventID));
    return true;
  };
}
export function getEventID(event) {
  return { type: MODIFY_EVENT, eventID: event };
}
export function dispatchViewEvent(event) {
  return { type: VIEW_EVENT, eventID: event };
}

export function getAttendees(event) {
  const request = new Request(myServer + "/api/v1/attendees", {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({ eventID: event.eventID }),
  });
  return fetch(request)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return error;
    });
}

export function updateAttendeeCount(eventID) {
  const parameters = {
    method: "GET",
    credentials: "include",
    headers: new Headers({ "Content-Type": "application/json" }),
  };
  const getAttendeeCount = new Request(
    `/api/v1/attendeesCount/${eventID}`,
    parameters
  );
  return function (dispatch) {
    fetch(getAttendeeCount)
      .then((resp) => resp.json())
      .then((data) => {
        dispatch({ type: SET_EVENT_ATTENDEE_COUNTS, payload: data });
      })
      .catch(() => {
        /* NO-OP */
      });
  };
}
