import * as StorageService from 'utils/storage.service';


export function isLoggedIn() {
  const state = StorageService.get('reduxState');
  return (state.loggedOut !== true);
}

export function isAdmin() {
  const state = StorageService.get('reduxState');
  return (state.isAdmin === true);
}

export function isSuper() {
  const state = StorageService.get('reduxState');
  return (state.isSuperUser === true);
}