import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Button } from "components/Bootstrap";

import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import * as CartService from 'utils/cart.service';
import * as BillingService from 'utils/billing.service';

import { AddressForm } from 'components/Account-Mangement/profile/address-form'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

export class CreditCardForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        postal_code: '',
        country: 'CA',
      },
      card: {},
      pending: false,
      complete: false,
    };
  }

  onCardChange(event) {
    if (event.complete) {
      this.setState({ complete: true })
    }
  }

  onFormChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(Object.assign(this.state.form, {[name]: value}));
  }

  async submitPaymentForm(event) {
    this.setState({ pending: true });

    event.preventDefault();

    const {stripe, elements} = this.props.elements;

    if (!stripe || !elements) {
      return;
    }

    // Get a reference to a mounted CardElement.
    const cardElement = elements.getElement(CardElement);

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        address: {
          line1: this.state.form.line1,
          line2: this.state.form.line2,
          city: this.state.form.city,
          state: this.state.form.state,
          postal_code: this.state.form.postal_code,
          country: this.state.form.country,
        }
      }
    });

    if (error) {
      console.log('[error]', error);
      return;
    }

    this.props.onCardCollected(paymentMethod);

    this.setState({ pending: false });
  }

  submitIsDisabled() {
    if (!this.state.complete) { return true; }
    if (this.state.pending) { return true; }
    if (this.props.pending) { return true; }
    return false;
  }

  render() {
    return (
      <div>
        <AddressForm 
          onChange={event => this.onFormChange(event)}>
        </AddressForm>
        <CardElement 
          className="form-control"
          onChange={event => this.onCardChange(event)}
          options={{
            hidePostalCode: true,
            style: {
              base: {
                iconColor: '#666EE8',
                color: '#31325F',
                fontFamily: 'Montserrat, sans-serif',
                // fontWeight: '300',
                // fontSmoothing: 'antialiased',
                fontSize: '16px',
                lineHeight: '1.5rem',
                '::placeholder': {
                  // color: '#495057',
                  // fontFamily: 'Nunito, sans-serif',
                }
              }
            }
          }}
        />
        <div className="text-right mt-3">
          <Button onClick={ event => this.submitPaymentForm(event) } 
            disabled={this.submitIsDisabled()}>
            {this.props.btnLabel || 'Pay Now'}
          </Button>
        </div>
      </div>
    );
  }
}


