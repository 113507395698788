import React, { Component } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Container, Input, Button } from "../../Bootstrap";

export class ChangePassword extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      errors: {},
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    console.log(event);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ 
      form: Object.assign(this.state.form, {
        [name]: value 
      })
    });
  }

  handleSubmit(event) {
    console.log(this.state.form);
    event.preventDefault();
  }

  render() {
    const errors = {};
    const password = "";
    return <React.Fragment>
      <h3>Change Password</h3>
      
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col md={8} lg={6}>
            <Form.Group>
              <Form.Label>Old Password</Form.Label>
              <Input
                name="old_password"
                defaultValue=""
                variant={"alt"}
                onChange={this.handleChange}
                type="password"
                placeholder="Current Password"
              />
              {errors.confirm_password && (
                <small className="text-danger">
                  {errors.confirm_password.message}
                </small>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Input
                name="password"
                autoComplete="new-password"
                defaultValue={password}
                variant={"alt"}
                onChange={this.handleChange}
                ref={React.createRef({
                  required: "You must specify a password",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                  maxLength: {
                    value: 32,
                    message: "Password maximum length is 32 characters",
                  },
                  validate: {
                    lowerCase: (value) => /[a-z]/.test(value),
                    upperCase: (value) => /[A-Z]/.test(value),
                    digit: (value) => /[0-9]/.test(value),
                  },
                })}
                type="password"
                placeholder="Password"
              />
              {errors.password 
                && errors.password.type === "digit" && (
                <small className="text-danger">
                  Password must have at least 1 number
                </small>
              )}
              {errors.password &&
                errors.password.type === "lowerCase" && (
                  <small className="text-danger">
                    Password must have at least 1 lowercase letter
                  </small>
                )}
              {errors.password &&
                errors.password.type === "upperCase" && (
                  <small className="text-danger">
                    Password must have at least 1 uppercase letter
                  </small>
                )}
              {errors.password && (
                <small className="text-danger">
                  {errors.password.message}
                </small>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirm Password</Form.Label>
              <Input
                name="confirm_password"
                autoComplete="new-password"
                variant={"alt"}
                onChange={this.handleChange}
                type="password"
                placeholder="Confirm Password"
              />
              {errors.confirm_password && (
                <small className="text-danger">
                  {errors.confirm_password.message}
                </small>
              )}
            </Form.Group>

            <Button type="submit" id="submit" size="med">
              Update
            </Button>
          </Col>
        </Row>
      </Form>

    </React.Fragment>
  }
}