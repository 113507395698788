
import React, { Component } from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components/macro";

//google tag manager
import TagManager from "react-gtm-module";
if (process.env.REACT_APP_GTM && process.env.REACT_APP_GTM != '') {
  let tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM
  }
  TagManager.initialize(tagManagerArgs);
}


// Config
import theme from "./config/theme";

// Assets
import './sass/style.scss';
import "./App.css";

// Views
import SuperAdmin from "./components/Account-Mangement/SuperAdmin";
import Login from "./views/Login/Login";
import Signup from "./views/SignUp/SignUp";
import Home from "./components/Home/Home";
import Profile from "./components/Account-Mangement/ProfileHooks";
import ProfileAdmin from "./components/Account-Mangement/ProfileAdminHooks";
import AddEvent from "./components/Event/CreateEvent";
import EditEvent from "./components/Event/EditEvent";
import ViewEvent from "./components/Event/ViewEvent";
import Coupon from "./components/Account-Mangement/Coupon";
import AddCoupon from "./components/Account-Mangement/AddCoupon";
import EditCoupon from "./components/Account-Mangement/EditCoupon";
import Users from "./components/Account-Mangement/Users";
import AddUser from "./components/Account-Mangement/AddUserHooks";
import LandingPage from "./components/Landing/LandingPage";
import Activation from "./components/ActivationPage/Activation";
import ForgettenPassword from "./components/Login/ForgottenPassword";
import PasswordReset from "./components/Login/PasswordReset";
import Error404 from "./views/Errors/Error404";

import { ProfileContainer } from "./components/Account-Mangement/profile/profile-container";
import { CartContainer } from "./components/Cart/cart-container";

import { CartCheckout } from 'components/Cart/cart-checkout';


export class ProtectedRoute extends Component {
  render() {
    const { component: Component, ...props } = this.props;
    const signedIn = !!localStorage.getItem("signedIn");

    // HACK: This fixes a bug where localStorage's signedIn state is inconsistent with redux's loggedOut state and causes a redirect loop.
    // The long term solution is to be able to have a single source of truth and move redirect logic out of the render() function in views/Login/Login.js.
    const storeRedux = JSON.parse(localStorage.getItem("reduxState"));
    if (storeRedux) {
      storeRedux.loggedOut = !signedIn;
      localStorage.setItem("reduxState", JSON.stringify(storeRedux));
    }

    return (
      <Route
        {...props}
        render={(props) =>
          signedIn ? <Component {...props} /> : <Redirect to="/Login" />
        }
      />
    );
  }
}

class App extends Component {
  render() {
    const { is_Admin } = this.props;
    let Routes;

    // if (is_Admin) {
    //   Routes = (
    //     <ProtectedRoute exact path="/ProfileCompany" component={ProfileAdmin} />
    //   );
    // } else {
    //   Routes = <ProtectedRoute exact path="/ProfileUser" component={Profile} />;
    // }

    return (
      <BrowserRouter basename={"/"}>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route path={"/checkout/:priceId"} component={CartCheckout} />
            <Route path={"/checkout"} component={CartCheckout} />

            <ProtectedRoute path="/profile" component={ProfileContainer} />

            <Route path={"/confirmation/:token"} component={Activation} />
            <Route path={"/Login"} component={Login} />
            <Route exact path={"/recover"} component={ForgettenPassword} />
            <Route path="/signup" component={Signup} />
            <Route path={"/passwordReset/:token"} component={PasswordReset} />
            <ProtectedRoute exact path="/Home" component={Home} />
            <ProtectedRoute exact path="/AddEvent" component={AddEvent} />
            <ProtectedRoute exact path="/ModifyEvent" component={EditEvent} />
            <ProtectedRoute exact path="/ViewEvent" component={ViewEvent} />
            
            <ProtectedRoute exact path="/Users" component={Users} />
            <ProtectedRoute exact path="/Coupon" component={Coupon} />
            <ProtectedRoute exact path="/SuperAdmin" component={SuperAdmin} />
            <ProtectedRoute exact path="/addUser" component={AddUser} />
            <ProtectedRoute exact path="/AddCoupon" component={AddCoupon} />
            <ProtectedRoute exact path="/EditCoupon/:couponid" component={EditCoupon} />
            {Routes}
            <Route exact path={"/"} component={LandingPage} />
            <Route path="*" component={Error404} />
          </Switch>
        </ThemeProvider>
      </BrowserRouter>
    );
  }
}
//we recieve the state of the store
const mapStateToProps = (state) => ({
  is_Admin: state.isAdmin,
  isSuperUser: true,
  menuCollapsed: state.menuCollapsed,
  logged_Out: state.loggedOut,
  loggedIN: state.session,
  Zone: state.userZone,
  companyID: state.companyID,
  creditScore: state.creditScore,
  eventID: state.eventID,
  payment: state.payment,
  role: state.role,
  email: state.email,
  session: state.session,
  response: state.response,
});

export default connect(mapStateToProps)(App);
