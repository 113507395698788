import React, { useState, useEffect } from "react";
import {Navbar, Nav, Alert} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sessionActions from "../actions/sessionActions";
import "./NavigationBar.css";
import { withRouter } from "react-router-dom";

// Assets
import { ReactComponent as ToggleIcon } from "../../assets/img/hamburger_icon.svg";
import { ReactComponent as Logo } from "../../assets/img/logo_navigation.svg";
import { ReactComponent as LogoWaves } from "../../assets/img/navigation_wave.svg";
import { ReactComponent as NavWaves } from "../../assets/img/login_waves_mobile.svg";

// Components
import { Container } from "../Bootstrap";
import { ThemedNavbar, NavHomeLink, ThemedNavItem } from "./Navigation.styles";



const NavigationBar = (props) => {
  // Props destructuring
  const { loggedOut, isAdmin, role, isSuperUser, isViewingAsAdmin } = props;

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    props.actions.menuCollapse(!menuOpen);
  }, [menuOpen]);

  const handleLogout = (e) => {
    e.preventDefault();
    props.actions.logOutSuccess();
  };

  const showMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  let myNav;
  let userRoute;
  let ProfileRoute;
  let couponRoute;
  let superAdminRoute;

  if (isSuperUser) {
    couponRoute = (
      <Nav.Link eventKey="/Coupon" href="/Coupon">
        <span>Coupons</span>
      </Nav.Link>
    );
    superAdminRoute = (
      <Nav.Link eventKey="/SuperAdmin" href="/SuperAdmin">
        <span>Super Admin</span>
      </Nav.Link>
    );
  }

  ProfileRoute = (
    <Nav.Link eventKey="/profile/personal-details" href="/profile/personal-details">
      <span>Profile</span>
    </Nav.Link>
  );

  if (isAdmin || role === "admin") {
    userRoute = (
      <Nav.Link eventKey="/Users" href="/Users">
        <span>Users</span>
      </Nav.Link>
    );
  }
  if (loggedOut) {
    myNav = "";
  } else {
    myNav = (
      <ThemedNavbar variant="" expand="lg" className={menuOpen && "menuOpen"}>
        <Container>
          <NavHomeLink href="/Home" className="mr-0">
            <Logo />
            <LogoWaves className="waves" />
          </NavHomeLink>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={showMenu}>
            <ToggleIcon />
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav activeKey={props.location.pathname} className="w-100">
              <ThemedNavItem
                className="accent"
                disabled={props.creditScore === 0 ? true : false}>
                <Nav.Link
                  disabled={props.creditScore === 0 ? true : false}
                  eventKey="/AddEvent"
                  href="/AddEvent">
                  <span>Create new</span>
                </Nav.Link>
              </ThemedNavItem>
              <ThemedNavItem>
                <Nav.Link eventKey="/Home" href="/Home">
                  <span>Dashboard</span>
                </Nav.Link>
              </ThemedNavItem>
              <ThemedNavItem>{ProfileRoute}</ThemedNavItem>
              <ThemedNavItem>{userRoute}</ThemedNavItem>
              <ThemedNavItem>{couponRoute}</ThemedNavItem>
              <ThemedNavItem>{superAdminRoute}</ThemedNavItem>
              <ThemedNavItem className="ml-lg-auto pr-lg-0">
                <Nav.Link href="#" onClick={handleLogout}>
                  <span>Logout</span>
                </Nav.Link>
              </ThemedNavItem>
            </Nav>
            <NavWaves className="d-block d-lg-none navbar-waves" />
          </Navbar.Collapse>
        </Container>
      </ThemedNavbar>
    );
  }
  return (<>
    { isViewingAsAdmin && <Alert variant={'danger'} style={{ marginBottom: 0 }}>You are currently viewing this users account. Be careful and remember to log out.</Alert>}
    <div>{myNav}</div>
  </>);
};

function mapStateToProps(state) {
  return {
    loggedOut: state.loggedOut,
    isAdmin: state.isAdmin,
    isViewingAsAdmin: state.isViewingAsAdmin,
    role: state.role,
    menuCollapsed: state.menuCollapsed,
    isSuperUser: state.isSuperUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavigationBar));
