import * as types from "../../components/actions/actionTypes";
import intitalState from "./initalState";
function sessionReducer(state = intitalState, action) {
  // Useful if you want to debug state related changes.
  // console.log("State");
  // console.log(JSON.stringify(action));
  // console.log(JSON.stringify(state));
  switch (action.type) {
    case types.SET_PRICING:
      return {
        ...state,
        pricing: action.payload,
      };
    case types.SET_EVENT_ATTENDEE_COUNTS:
      return {
        ...state,
        eventAttendees: action.payload,
      };
    case types.SET_SUPERUSER:
      return {
        ...state,
        isSuperUser: action.payload,
      };
    case types.LOG_IN_SUCCESS_COMPANY:
      return {
        session: localStorage.signedIn,
        loggedOut: false,
        isAdmin: true,
        isViewingAsAdmin: !!action.isViewingAsAdmin,
        menuCollapsed: false,
        userZone: action.payload,
        eventID: "",
        role: "null",
        creditScore: action.credit,
        payment: action.payment,
        response: "ok",
      };
    case types.LOG_IN_SUCCESS_USER:
      return {
        session: localStorage.signedIn,
        loggedOut: false,
        isAdmin: false,
        isViewingAsAdmin: !!action.isViewingAsAdmin,
        menuCollapsed: false,
        userZone: action.payload,
        creditScore: action.credit,
        eventID: "",
        role: action.role,
        email: action.email,
        payment: action.payment,
        response: "ok",
      };
    case types.LOG_IN_FAILED:
      return {
        ...state,
        response: action.response,
        loginFailCount: state.loginFailCount+1
      };
    case types.ADD_EVENT:
      return {
        ...state,
        creditScore: action.credit,
        eventID: "",
      };
    case types.MODIFY_EVENT:
      return {
        ...state,
        eventID: action.eventID,
      };
    case types.VIEW_EVENT:
      return {
        ...state,
        eventID: action.eventID,
      };
    case types.LOG_OUT:
      return {
        session: localStorage.signedIn,
        isAdmin: false,
        isSuperUser: false,
        isViewingAsAdmin: false,
        menuCollapsed: false,
        loggedOut: true,
        userZone: "",
        creditScore: 0,
        eventID: "",
        payment: 0,
        role: "",
        email: "",
      };
    case types.UPDATE_SUCCESS:
        return {
          ...state,
          userZone: action.userZone,
        };
    case types.ADD_CARD:
      return {
        ...state,
        payment: action.payment,
      };
    case types.MENU_COLLAPSED:
      return {
        ...state,
        menuCollapsed: action.menuCollapsed,
      };

    default:
      return state;
  }
}
export default sessionReducer;
