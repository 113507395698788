import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import axios from "axios";
import map from "lodash/map";
import { Redirect } from "react-router-dom";
import Section from "./../Section";
//Layout
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

// Thirdparty
import {
  Container,
  Input,
  Checkbox,
  Switch,
  Label,
  Button,
  TextArea,
} from "../Bootstrap";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";

var myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

const AddCoupon = (props) => {
  // States
  const [couponCode, setCode] = useState("");
  const [couponValue, setValue] = useState("");
  const [maxRedemption, setMaxRedemption] = useState(null);
  const [couponComment, setComment] = useState("");
  const [isEnabled, setEnabled] = useState(1);
  const [redirect, setRedirect] = useState(false);

  const [applyToEventCost, setApplyToEventCost] = useState(true);
  const [applyToSurveyCost, setApplyToSurveyCost] = useState(false);
  const [applyToAttendeeCost, setApplyToAttendeeCost] = useState(false);
  const [applyToCompanyOnly, setApplyToCompanyOnly] = useState(false);

  // Transitional States
  const [oneCheckboxChecked, setOneCheckboxChecked] = useState(true);

  // Prevent Multiple Submission
  const [isLoading, setIsLoading] = useState(false);

  // Use React Hook Form
  const { register, errors, setError, handleSubmit, watch } = useForm();

  const onSubmit = (e) => {
    if (!props.isSuperUser) return;
    setIsLoading(true);

    // Validate if coupong applied at one item at least
    let arrayOfCheckboxes = [
      applyToEventCost,
      applyToSurveyCost,
      applyToAttendeeCost,
    ];
    if (!arrayOfCheckboxes.some((item) => item === true)) {
      setOneCheckboxChecked(false);
      setIsLoading(false);
      return false;
    } else {
      setOneCheckboxChecked(true);
    }

    const request = new Request(myServer + "/api/v1/coupon", {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify({
        code: couponCode,
        type: "percentage",
        value: couponValue,
        comment: couponComment,
        enabled: isEnabled,
        user_id: 5,
        max_redemptions: maxRedemption,
        applies_base_cost: applyToEventCost ? 1 : 0,
        applies_survey_cost: applyToSurveyCost ? 1 : 0,
        applies_attendee_cost: applyToAttendeeCost ? 1 : 0,
        applies_company_only: applyToCompanyOnly ? 1 : 0,
      }),
    });
    fetch(request)
      .then(function (response) {
        if (response.status == 201) {
          setRedirect(true);
        } else if (response.status == 422) {
          setError("name", {
            type: "manual",
            message: "Coupon code was previously used",
          });
        } else {
          console.log(response);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  if (redirect === true) {
    return <Redirect to="/Coupon"></Redirect>;
  }
  return (
    <DefaultLayout>
      <Container>
        <React.Fragment>
          <Section className="mt-5 pt-lg-5" sectionTitle="Create a Coupon">
            <Row>
              <Col md={6} lg={4}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group>
                    <Input
                      name="name"
                      ref={register({
                        required: true,
                      })}
                      type="text"
                      placeholder="Code name"
                      defaultValue={couponCode}
                      variant={"alt"}
                      onChange={(e) => setCode(e.target.value)}
                    />
                    {errors.name?.type === "required" && (
                      <small className="text-danger">
                        Code name is required
                      </small>
                    )}
                    {errors.name?.types.type === "manual" && (
                      <small className="text-danger">
                        Coupon code was previously used
                      </small>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Input
                      name="name"
                      ref={register({
                        required: false,
                      })}
                      type="number"
                      placeholder="Max redemption times"
                      min="1"
                      defaultValue={couponCode}
                      variant={"alt"}
                      onChange={(e) => setMaxRedemption(e.target.value)}
                    />
                    <small>
                      If this field is empty, the coupon can be used an
                      unlimited number of times.
                    </small>
                  </Form.Group>
                  <Form.Group>
                    <InputGroup className="mb-2">
                      <Input
                        name="value"
                        ref={register({
                          required: "You must enter code value",
                          validate: {
                            length: (value) =>
                              /^[1-9][0-9]?$|^100$/.test(value),
                          },
                        })}
                        type="number"
                        placeholder="Code value"
                        defaultValue={couponValue}
                        variant={"alt"}
                        onChange={(e) => setValue(e.target.value)}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>

                    {errors.value && (
                      <small className="text-danger">
                        {errors.value.message}
                      </small>
                    )}
                    {errors.value && errors.value.type === "length" && (
                      <small className="text-danger">
                        You must enter value less than or equal to 100
                      </small>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <TextArea
                      name="comment"
                      ref={register({
                        required: true,
                      })}
                      placeholder="Comment"
                      defaultValue={couponComment}
                      variant={"alt"}
                      onChange={(e) => setComment(e.target.value)}
                    />
                    {errors.comment?.type === "required" && (
                      <small className="text-danger">Comment is required</small>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Label>This coupon applies to:</Label> <br />
                    <Checkbox
                      custom
                      className="d-block"
                      inline="true"
                      label="Base event cost"
                      id="eventCost"
                      onChange={() => {
                        setApplyToEventCost(!applyToEventCost);
                      }}
                      value="unlimited"
                      checked={applyToEventCost}
                    />
                    <Checkbox
                      custom
                      className="d-block"
                      inline="true"
                      label="Survey cost"
                      id="surveyCost"
                      onChange={() => {
                        setApplyToSurveyCost(!applyToSurveyCost);
                      }}
                      value="unlimited"
                      checked={applyToSurveyCost}
                    />
                    <Checkbox
                      custom
                      className="d-block"
                      inline="true"
                      label="Attendee cost"
                      id="attendeeCost"
                      onChange={() => {
                        setApplyToAttendeeCost(!applyToAttendeeCost);
                      }}
                      value="unlimited"
                      checked={applyToAttendeeCost}
                    />
                    {oneCheckboxChecked ? null : (
                      <small className="text-danger">
                        Please check one of these items at least
                      </small>
                    )}
                  </Form.Group>
                  <Form.Group className="d-flex align-items-center">
                    <Label className="mr-4 pr-lg-4">Coupon enabled?</Label>
                    <Switch
                      type="switch"
                      id="enabledSwitch"
                      label=""
                      checked={isEnabled}
                      onChange={(e) => {
                        if (e.target.checked === false) {
                          setEnabled(0);
                        } else {
                          setEnabled(1);
                        }
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="d-flex align-items-center">
                    <Label className="mr-4 pr-lg-4">Restrict by Company</Label>
                    <Switch
                      type="switch"
                      id="applyToCompanyOnlySwitch"
                      label=""
                      checked={applyToCompanyOnly}
                      onChange={(e) => {
                        if (e.target.checked === false) {
                          setApplyToCompanyOnly(0);
                        } else {
                          setApplyToCompanyOnly(1);
                        }
                      }}
                    />
                  </Form.Group>
                  <Button
                    disabled={isLoading}
                    type="submit"
                    id="submit"
                    size="med"
                  >
                    Submit
                  </Button>
                </Form>
              </Col>
            </Row>
          </Section>
        </React.Fragment>
      </Container>
    </DefaultLayout>
  );
};

function mapStateToProps(state) {
  return {
    isAdmin: state.isAdmin,
    isSuperUser: true,
    logged_in: state.session,
    loggedOut: state.loggedOut,
    companyID: state.companyID,
    userZone: state.userZone,
    creditScore: state.creditScore,
    eventID: state.eventID,
    role: state.role,
    email: state.email,
  };
}

export default connect(mapStateToProps, null)(AddCoupon);
