export default {
  pricing: {
    eventBaseCost: process.env.REACT_APP_EVENTBASECOST,
    pricePerAttendee: process.env.REACT_APP_PRICEPERATTENDEE,
    surveyCost: process.env.REACT_APP_SURVEYCOST,
  },
  eventAttendees: 0,
  session: false,
  isAdmin: false,
  isViewingAsAdmin: false,
  isSuperUser: false,
  loggedOut: true,
  userZone: "",
  menuCollapsed: false,
  // companyID: '',
  creditScore: 0,
  eventID: "",
  payment: 0,
  loginFailCount: 0,
  role: "",
  email: "",
  response: "",
};
