import React, { useRef, useLayoutEffect } from "react";

// Components
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const SurveyChart = ({ answers, index, title, ...props }) => {
  const chart = useRef(null);

  useLayoutEffect(() => {
    let x = am4core.create(`question-${index}`, am4charts.PieChart);
    x.logo.disabled = true;

    // Add data
    x.data = answers;

    /*
    1. (red) #ef6d53
    2. (sea foam) #BAE2D9
    3. (teal) #2ea39e
    4. (rose) #ffc7bc
    5. (creamsicle) #fdb687
    */

    // Add and configure Series
    var pieSeries = x.series.push(new am4charts.PieSeries());
    pieSeries.colors.list = [
      am4core.color("#ef6d53"),
      am4core.color("#BAE2D9"),
      am4core.color("#2ea39e"),
      am4core.color("#ffc7bc"),
      am4core.color("#fdb687"),
    ];
    pieSeries.dataFields.value = "percentString";
    pieSeries.dataFields.category = "answer";
    pieSeries.legendSettings.valueText = "{percentString}";

    // Let's cut a hole in our Pie chart the size of 40% the radius
    x.innerRadius = am4core.percent(40);

    // Disable ticks and labels
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

    // Disable tooltips
    pieSeries.slices.template.tooltipText = "";

    // Add a legend
    x.legend = new am4charts.Legend();

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, []);

  return (
    <div
      style={{ flex: "1 1 33.3%" }}
      className="d-flex justify-content-center flex-column align-items-center"
    >
      <h4 className="text-center"> {title} </h4>
      <div id={`question-${index}`} style={{ height: "500px" }}></div>
    </div>
  );
};

export default SurveyChart;
