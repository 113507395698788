import React, { Component } from "react";

import { Button } from "../../components/Bootstrap";
import LoginLayout from "../../layouts/LoginLayout/LoginLayout";
import {
  FormContainer,
  MainContent,
  TabsContainer,
} from "../../layouts/LoginLayout/LoginLayout.styles";
import { LinkContainer } from "react-router-bootstrap";

var myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

export default class Activation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
    };
  }

  componentDidMount() {
    let self = this;
    let pathname = this.props.location.pathname.substr(1);

    let code = pathname.split(":");

    const request = new Request(myServer + "/api/v1/activateAccount", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ code: code[1] }),
    });
    fetch(request).then((response) => {
      return response.json().then((res) => {
        self.setState({ success: res.message === "success" });
      });
    });
  }
  render() {
    const string = this.state.success
      ? "Your Account Has Been Activated"
      : "Activation Key Is Invalid";
    return (
      <LoginLayout>
        <MainContent>
          <FormContainer>
            <div style={{ textAlign: "center" }}>
              <h3 style={{ marginBottom: "1em" }}>{string}</h3>
              <LinkContainer to="/login">
                <Button size="md" color="dark">
                  Sign In
                </Button>
              </LinkContainer>
            </div>
          </FormContainer>
        </MainContent>
      </LoginLayout>
    );
  }
}
