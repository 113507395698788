import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import axios from "axios";
import map from "lodash/map";
import { Redirect } from "react-router-dom";
import Section from "./../Section";
//Layout
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

// Thirdparty
import {
  Container,
  Input,
  Checkbox,
  Switch,
  Label,
  Button,
  Select,
  TextArea,
  ListGroup,
} from "../Bootstrap";
import { Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";

var myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}
var centerText = {
  textAlign: "center",
};

const AddUser = (props) => {
  // States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [role, setRole] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [roles, setRoles] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [response, setResponse] = useState("");
  const [responseEmailMessage, setResponseEmailMessage] = useState("");

  // Prevent Multiple Submission
  const [isLoading, setIsLoading] = useState(false);

  // Use React Hook Form
  const { register, errors, handleSubmit, watch } = useForm();

  const onSubmit = (data, e) => {
    setIsLoading(true);
    e.preventDefault();
    setTimeZone("America/New_York");
    axios
      .post(
        myServer + "/api/v1/userSignup",
        {
          Name: name,
          email: email,
          userType: role,
        },
        { withCredentials: true }
      )
      .then(function (response) {
        if (response.data["message"] === "success") {
          setIsLoading(false);
          setRedirect(true);
        } else if (response.data["message"] === "already exists") {
          setIsLoading(false);
          setResponseEmailMessage(
            "There is already an account with this email."
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const helper = () => {
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 5000);
  };

  useEffect(() => {
    helper();
  }, [response]);

  // Component did Mount
  useEffect(() => {
    const request = new Request(`${myServer}/api/v1/getRoles`, {
      method: "GET",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    });
    fetch(request).then((response) => {
      response.json().then((res) => {
        var tempArr = [];
        for (let i = 0; i < res.array.length; i++) {
          tempArr.push(res.array[i].role);
        }
        setRoles(tempArr);
      });
    });
  }, []);

  if (redirect === true) {
    return <Redirect to="/Users"></Redirect>;
  }

  const Roles = map(roles, (val, key) => (
    <option key={key} value={val}>
      {val}
    </option>
  ));

  return (
    <DefaultLayout>
      <Container>
        <React.Fragment>
          <Section className="mt-5 pt-lg-5" sectionTitle="Create a User">
            <Row>
              <Col md={6} lg={4}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group>
                    <Input
                      name="fullName"
                      ref={register({
                        required: true,
                      })}
                      type="text"
                      placeholder="Full name"
                      defaultValue={name}
                      variant={"alt"}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {errors.fullName?.type === "required" && (
                      <small className="text-danger">
                        Full name is required
                      </small>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Input
                      variant={"alt"}
                      name="email"
                      ref={register({
                        required: true,
                        pattern: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                      })}
                      placeholder="Enter Email"
                      defaultValue={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email?.type === "required" && (
                      <small className="text-danger">Email is required</small>
                    )}
                    {errors.email?.type === "pattern" && (
                      <small className="text-danger">
                        Please provide a valid email
                      </small>
                    )}
                    {responseEmailMessage && (
                      <small className="text-danger">{responseEmailMessage}</small>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Select
                      variant={"alt"}
                      custom
                      as="select"
                      ref={register({
                        required: true,
                      })}
                      name="role"
                      defaultValue={role}
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <option value="">User Type</option>
                      {Roles}
                    </Select>
                    {errors.role?.type === "required" && (
                      <small className="text-danger">Role is required</small>
                    )}
                  </Form.Group>
                  <Button
                    disabled={isLoading}
                    type="submit"
                    id="submit"
                    size="med"
                  >
                    Submit
                  </Button>
                </Form>
              </Col>
            </Row>
          </Section>
        </React.Fragment>
      </Container>
    </DefaultLayout>
  );
};

function mapStateToProps(state) {
  return {
    isAdmin: state.isAdmin,
    logged_in: state.session,
    loggedOut: state.loggedOut,
    companyID: state.companyID,
    userZone: state.userZone,
    creditScore: state.creditScore,
    eventID: state.eventID,
    role: state.role,
    email: state.email,
  };
}

export default connect(mapStateToProps, null)(AddUser);
