import React, { useState, useRef } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import {useForm} from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import theme from "../../config/theme";

// Layout
import LoginLayout from "../../layouts/LoginLayout/LoginLayout";

// Components
import { Form, Alert, Modal } from "react-bootstrap";
import Link from "../../components/Link/Link";
import { Input, Button } from "../../components/Bootstrap";
import { Paragraph } from "../../components/Typography";
import {
  FormContainer,
  LoginButtonContainer,
  Title,
  FormGroupContainer,
  MainContent,
} from "../../layouts/LoginLayout/LoginLayout.styles";

import * as StorageService from 'utils/storage.service';

//google tag manager
import TagManager from "react-gtm-module";

let myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

export default function Signup(props) {
  /**
   * Hooks
   */
  const isDesktop = useMediaQuery({
    query: `(min-device-width: ${theme.breakpoints.md}px)`,
  });

  //States
  const [redirect, setRedirect] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [response, setResponse] = useState("");
  const [nextSteps, setNextSteps] = useState(false);
  const [disable, setDisable] = useState(false);
  const [sent, setSent] = useState("hidden");

  // Input States
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [industryName, setIndustryName] = useState("");
  const [email, setEmail] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [agreeTerms, setAgreeTerms] = useState("");

  const [terms, setTerms] = useState("");

  // Use React Hook Form
  const { register, errors, handleSubmit, watch } = useForm();

  // Use reference to get the current value of Password field
  const passwordField = useRef({});
  passwordField.current = watch("password", "");

  const onSubmitHandler = (data, e) => {
    // This is default for every user
    setTimeZone("America/New_York");

    e.preventDefault();

    if (!agreeTerms) {
      return false;
    }

    const picture = "image_placeholder.png";

    axios
      .post(myServer + "/api/v1/signup", {
        Name: companyName,
        email: email,
        password: password,
        TimeZone: timeZone,
        Logo: picture,
        fullName: fullName,
        industryName: industryName
      })
      .then(function (response) {
        let tagManagerArgs = {
          dataLayer: {
            event: 'signup',
            signUp: 0
          },
        };

        if (response.data["message"] === "success") {
          setNextSteps(true);
          tagManagerArgs.dataLayer.signUp = 1;
        } else if (response.data["message"] === "already exists") {
          setResponse(
            "The email already exists. Please double check and try again."
          );
          helper();
        }

        if (process.env.REACT_APP_GTM && process.env.REACT_APP_GTM != '') {
          TagManager.dataLayer(tagManagerArgs);
        }
      })
      .catch((err) => alert(err));
  };

  const helper = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const resendEmail = () => {
    const request = new Request(myServer + "/api/v1/reSendActivationEmail", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        email: email,
      }),
    });

    return fetch(request).then((response) => {
      return response.json().then((res) => {
        if (res.message === "success") {
          setSent("visible");
          setDisable(true);
          setTimeout(() => {
            setSent("hidden");
            setDisable(false);
          }, 5000);
        }
      });
    });
  };

  const [show, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    setAgreeTerms(false);
  };
  const handleShowModal = () => {
    setShowModal(true);

    const request = new Request(myServer + "/api/v1/terms", {
      method: "GET",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    fetch(request).then(function (response) {
      response.json().then((res) => {
        setTerms(res.html);
      });
    });
  };
  const agreeToTerms = () => {
    setDisable(false);
    setShowModal(false);
  };

  if (redirect === true) {
    return (
      <Redirect
        to={{
          pathname: "/Login",
          state: {referrer: 'signup'}
        }}
      ></Redirect>
    );
  }

  const nextStep = (
    <>
      <div className="row justify-content-center">
        <div className="image-container">
          <label>
            <div>
              <h4 className="text-center">
                <strong>Successfully Signed Up!</strong>
              </h4>
            </div>
          </label>
          <div className="d-flex justify-content-center links">
            Didn't Receive One?{" "}
            <button
              disabled={disable}
              style={{
                background: "none",
                border: "none",
                padding: "0",
                color: "#069",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => resendEmail()}
              className="ml-2"
            >
              Resend Email
            </button>
          </div>
          {sent && <p>Email Resent!</p>}
        </div>
      </div>
    </>
  );


  const next = StorageService.get('next');
  if (/*next && */nextSteps) {
    // return <Redirect to={next}></Redirect>;
    return (
      <Redirect
        to={{
          pathname: "/Login",
          state: {referrer: 'signup'}
        }}
      ></Redirect>
    );
  }

  const saasModal = (
    <Modal
      show={show}
      onHide={handleCloseModal}
      backdrop="static"
      dialogClassName="modalTerms"
      scrollable="true"
      size="xl"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Terms of Use</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          dangerouslySetInnerHTML={{
            __html: terms
          }}></div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={agreeToTerms}>Close</Button>
      </Modal.Footer>
    </Modal>
  );

  const form = (
    <>
      {showAlert && <Alert variant={"danger"}>{response}</Alert>}
      <Form onSubmit={handleSubmit(onSubmitHandler)}>
        <Form.Group>
          <Input
            name="fullName"
            ref={register({
              required: true,
            })}
            type="text"
            placeholder="Full Name"
            defaultValue={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          {errors.fullName?.type === "required" && (
            <small className="text-danger">Full name is required</small>
          )}
        </Form.Group>
        <Form.Group>
          <Input
            name="email"
            ref={register({
              required: true,
              pattern: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
            })}
            placeholder="Email Address"
            autoComplete="off"
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email?.type === "required" && (
            <small className="text-danger">Email is required</small>
          )}
          {errors.email?.type === "pattern" && (
            <small className="text-danger">Please provide a valid email</small>
          )}
        </Form.Group>
        <Form.Group>
          <Input
            name="company_name"
            type="text"
            placeholder="Company Name"
            defaultValue={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          {errors.company_name?.type === "required" && (
            <small className="text-danger">Company name is required</small>
          )}
        </Form.Group>
        <Form.Group>
          <Input
            name="industry_name"
            type="text"
            placeholder="Industry"
            defaultValue={industryName}
            onChange={(e) => setIndustryName(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Input
            name="password"
            ref={register({
              required: "You must specify a password",
              minLength: {
                value: 8,
                message: "Password must have at least 8 characters",
              },
              maxLength: {
                value: 32,
                message: "Password maximum length is 32 characters",
              },
              validate: {
                lowerCase: (value) => /[a-z]/.test(value),
                upperCase: (value) => /[A-Z]/.test(value),
                digit: (value) => /[0-9]/.test(value),
              },
            })}
            autoComplete="new-password"
            defaultValue={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
          />
          {errors.password && errors.password.type === "digit" && (
            <small className="text-danger">
              Password must have at least 1 number
            </small>
          )}
          {errors.password && errors.password.type === "lowerCase" && (
            <small className="text-danger">
              Password must have at least 1 lowercase letter
            </small>
          )}
          {errors.password && errors.password.type === "upperCase" && (
            <small className="text-danger">
              Password must have at least 1 uppercase letter
            </small>
          )}
          {errors.password && (
            <small className="text-danger">{errors.password.message}</small>
          )}
        </Form.Group>
        <Form.Group>
          <Input
            name="password_repeat"
            defaultValue={confirmPassword}
            ref={register({
              validate: (value) =>
                value === passwordField.current || "The passwords do not match",
            })}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
            placeholder="Confirm Password"
          />
          {errors.password_repeat && (
            <small className="text-danger">
              {errors.password_repeat.message}
            </small>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Check inline label="Agree To" name="agreeTerms" type="checkbox" defaultChecked={agreeTerms} id="agreeTermsCheckbox" onChange={(e) => setAgreeTerms(e.target.checked)} />
          <Link onClick={handleShowModal} style={{
              cursor: "pointer",
              color: "#2ea39e",
              display: "inline-block"
            }}>
            Terms of Use
          </Link>
        </Form.Group>
        <Form.Group className="pt-4">
          <LoginButtonContainer>
            <Button
              disabled={!agreeTerms}
              className="btn btn-md btn-primary btn-sm-block"
              type="submit"
              id="submit"
              size="lg"
              color="primary"
            >
              Create Presentii Account
            </Button>
          </LoginButtonContainer>
        </Form.Group>
      </Form>
      {saasModal}
    </>
  );

  return (
    <LoginLayout>
      <MainContent>
        <FormContainer>
          <Title>Create an Account</Title>
          <Paragraph className="text-center">
            Creating an account with Presentii is fast and simple with no
            purchase necessary. Gain access to your dashboard.
          </Paragraph>
          { next 
            ? (<Paragraph className="text-center">
                <em>You will be redirected to the checkout.</em>
              </Paragraph>) 
            : '' 
          }
          <FormGroupContainer>
            {nextSteps ? nextStep : form}
            {!isDesktop && (
              <div>
                <div className="pt-5 mb-2">
                  <Link href="/login">Sign In</Link>
                </div>
              </div>
            )}
            <div className="pt-3 mb-2">
              <Link href="/login">If you already have an account click here to login.</Link>
            </div>
          </FormGroupContainer>
        </FormContainer>
      </MainContent>
    </LoginLayout>
  );
}
