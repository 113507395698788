import React from "react";

import { Modal } from "react-bootstrap";
import AlertModal from "./../Modals/AlertModal";
import { Button } from "./../Bootstrap";

// Assets
import ArchiveEventIcon from "./../../assets/img/archive_icon.png";

const PreArchiveModal = ({ archiveModal, ...props }) => {
  return (
    <AlertModal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdropClassName="modal-backdrop--medium"
      {...props}
    >
      <Modal.Body>
        <div className="text-center">
          <img className="mb-3" src={ArchiveEventIcon} alt="Archive Event" />
        </div>
        <h4 className="alert-modal__title text-uppercase text-center font-weight-bold">
          Are you sure you want <br />
          to archive this event?
        </h4>
      </Modal.Body>
      <Modal.Footer>
        <Button color="secondary" onClick={props.onHide}>
          cancel
        </Button>
        <Button
          disabled={props.isLoading}
          onClick={archiveModal}
          color={props.variant}
        >
          continue
        </Button>
      </Modal.Footer>
    </AlertModal>
  );
};

export default PreArchiveModal;
