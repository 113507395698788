import React, { useState, useEffect, useRef } from "react";
import * as sessionActions from "../actions/sessionActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import PaymentTab from "./paymentTab";
import CardSection from "../Home/components/CardSection";
import Modal from "react-modal";

// Assets
import LogoPlaceholder from "./../../assets/img/logo_placeholder.svg";

//Layout
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

// ThirdParty
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Container, Input, Button } from "./../Bootstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement(document.getElementById("root"));

let myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

const Profile = (props) => {
  // States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [addCard, setAddCard] = useState(false);
  const [name, setName] = useState("");
  const [displayLogo, setDisplayLogo] = useState("");

  // Prevent MultipleSubmits
  const [isLoading, setIsLoading] = useState(false);

  // Use React Hook Form
  const { register, errors, handleSubmit, watch, setError, clearError } = useForm();

  // Use reference to get the current value of Password field
  const passwordField = useRef({});
  passwordField.current = watch("password", "");

  // Component Did Mount
  useEffect(() => {
    const request = new Request(`${myServer}/api/v1/Profile`, {
      method: "GET",
      credentials: "include",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    fetch(request)
      .then(function (response) {
        response.json().then((res) => {
          var picture;
          if (Object.is(res.data.Logo, "image_placeholder.png")) {
            picture = `${myServer}/Uploads/image_placeholder.svg`;
          } else {
            picture = `${myServer}/Uploads/Users/${res.data.companyID}/images/${res.data.Logo}`;
          }

          setEmail(res.data.email);
          setName(res.data.name);
          setTimeZone("America/New_York");
          setDisplayLogo(picture);
        });
      })
      .catch((error) => {
        return error;
      });
  }, []);

  // Functions
  const closeWindow = () => {
    setAddCard(false);
  };
  const openWindow = () => {
    setAddCard(true);
  };

  const onSubmit = (data, e) => {
    setIsLoading(true);
    e.preventDefault();

    var user = {
      email: email,
      TimeZone: "America/New_York",
      fullName: name,
    };

    props.actions.updateUser(user, null).then((s) => {
      setRedirect(true);
      setIsLoading(false);
    });
  };

  const onSubmitPassword = (data, e) => {
    setIsLoading(true);
    e.preventDefault();

    var user = {
      oldPassword: oldPassword,
      newPassword: password,
    };

    props.actions.updatePassword(user, null).then((s) => {
      if (s === "success") {
        clearError("oldpassword");
        setRedirect(true);
      } else {
        setError(
          "oldpassword",
          s
        );
      }

      setIsLoading(false);
    });
  }

  let role = props.role;
  let companyID = props.companyID;

  if (redirect === true) {
    return <Redirect to="/Profile"></Redirect>;
  }

  if (addCard === true) {
    var popUp = (
      <Modal
        isOpen={addCard}
        style={customStyles}
        onRequestClose={() => closeWindow()}
        contentLabel="Add a Credit Card"
      >
        <h3>Add a Credit Card</h3>
        <CardSection
          token={companyID}
          close={() => closeWindow()}
          role={role}
        ></CardSection>
        <br></br>
      </Modal>
    );
  } else {
    popUp = "";
  }

  let displayToRender;

  let formProfile = (
    <React.Fragment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6} lg={4}>
            <Form.Group>
              <Form.Label>Your Name</Form.Label>
              <Input
                name="name"
                variant={"alt"}
                type="text"
                autoComplete="username"
                ref={register({
                  required: "Name field is required",
                })}
                placeholder="Name"
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
              ></Input>
              {errors.name?.type === "required" && (
                <small className="text-danger">Name is required</small>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Email address</Form.Label>
              <Input
                name="email"
                variant={"alt"}
                ref={register({
                  required: true,
                  pattern: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                })}
                placeholder="Enter Email"
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Input>
              {errors.email?.type === "required" && (
                <small className="text-danger">Email is required</small>
              )}
              {errors.email?.type === "pattern" && (
                <small className="text-danger">
                  Please provide a valid email
                </small>
              )}
            </Form.Group>

            <Button disabled={isLoading} type="submit" id="submit" size="med">
              Update
            </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
  
  let formPassword = (
    <React.Fragment>
      <Form onSubmit={handleSubmit(onSubmitPassword)}>
        <Row>
          <Col md={6} lg={4}>

            <Form.Group>
              <Form.Label>Old Password</Form.Label>
              <Input
                name="old_password"
                defaultValue=""
                variant={"alt"}
                type="password"
                onChange={(e) => setOldPassword(e.target.value)}
                placeholder="Current Password"
                ref={register({
                  required: "You must specify a password",
                })}
              />
              {errors.oldpassword?.type === "invalid password" && (
                <small className="text-danger">Current password is incorrect</small>
              )}
              {errors.oldpassword?.type === "password unchanged" && (
                <small className="text-danger">Current password and new password are the same</small>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Input
                name="password"
                variant={"alt"}
                defaultValue={password}
                autoComplete="new-password"
                ref={register({
                  required: "You must specify a password",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                  maxLength: {
                    value: 32,
                    message: "Password maximum length is 32 characters",
                  },
                  validate: {
                    lowerCase: (value) => /[a-z]/.test(value),
                    upperCase: (value) => /[A-Z]/.test(value),
                    digit: (value) => /[0-9]/.test(value),
                  },
                })}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Password"
              ></Input>
              {errors.password && errors.password.type === "digit" && (
                <small className="text-danger">
                  Password must have at least 1 number
                </small>
              )}
              {errors.password && errors.password.type === "lowerCase" && (
                <small className="text-danger">
                  Password must have at least 1 lowercase letter
                </small>
              )}
              {errors.password && errors.password.type === "upperCase" && (
                <small className="text-danger">
                  Password must have at least 1 uppercase letter
                </small>
              )}
              {errors.password && (
                <small className="text-danger">{errors.password.message}</small>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirm Password</Form.Label>
              <Input
                name="confirm_password"
                variant={"alt"}
                autoComplete="new-password"
                defaultValue={confirmPassword}
                ref={register({
                  validate: (value) =>
                    value === passwordField.current ||
                    "The passwords do not match",
                })}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                placeholder="Confirm Password"
              ></Input>
              {errors.confirm_password && (
                <small className="text-danger">
                  {errors.confirm_password.message}
                </small>
              )}
            </Form.Group>

            <Button disabled={isLoading} type="submit" id="submit" size="med">
              Update
            </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );

  if (props.role == "admin") {
    displayToRender = (
      <React.Fragment>
        <Tabs>
          <TabList>
            <Tab>Profile</Tab>
            <Tab>Password</Tab>
            <Tab>Payments</Tab>
          </TabList>
          <TabPanel>{formProfile}</TabPanel>
          <TabPanel>{formPassword}</TabPanel>
          {popUp}
          <TabPanel>
            <PaymentTab addCard={() => openWindow()}></PaymentTab>
          </TabPanel>
        </Tabs>
      </React.Fragment>
    );
  } else {
    displayToRender = (
      <React.Fragment>
        <Tabs>
          <TabList>
            <Tab>Profile</Tab>
            <Tab>Password</Tab>
          </TabList>
          <TabPanel>{formProfile}</TabPanel>
          <TabPanel>{formPassword}</TabPanel>
        </Tabs>
      </React.Fragment>
    );
  }

  if (redirect === true) {
    return <Redirect to="/Home"></Redirect>;
  }

  return (
    <DefaultLayout>
      <Container className="py-5">
        <React.Fragment>{displayToRender}</React.Fragment>
      </Container>
    </DefaultLayout>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    isAdmin: state.isAdmin,
    logged_in: state.session,
    loggedOut: state.loggedOut,
    companyID: state.companyID,
    userZone: state.userZone,
    creditScore: state.creditScore,
    eventID: state.eventID,
    role: state.role,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
