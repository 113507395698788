import * as React from "react";
import styled from "styled-components/macro";
import { Form } from "react-bootstrap";

const Wrapper = styled(Form.Check)`
  min-height: ${({ theme }) => theme.forms.switch.height};
  padding-left: ${({ theme }) => theme.forms.switch.width};
  .custom-control-label {
    &::before {
      top: 0;
      left: -${({ theme }) => theme.forms.switch.width};
      width: ${({ theme }) => theme.forms.switch.width};
      height: ${({ theme }) => theme.forms.switch.height};
      border-radius: ${({ theme }) => theme.forms.switch.height};
    }
    &::after {
      top: 2px;
      left: calc(-${({ theme }) => theme.forms.switch.width} + 2px);
      width: calc(${({ theme }) => theme.forms.switch.height} - 4px);
      border-radius: ${({ theme }) => theme.forms.switch.height};
      height: calc(${({ theme }) => theme.forms.switch.height} - 4px);
    }
  }
  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      background-color: ${({ theme }) => theme.colors.alt.base};
      border-color: ${({ theme }) => theme.colors.alt.base};
    }
    &::after {
      transform: translateX(2.1429rem);
    }
  }
`;

const Switch = (props) => {
  return <Wrapper type="switch" {...props} />;
};

export default Switch;
