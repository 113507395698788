import * as React from "react";
import styled from "styled-components/macro";
import { Form } from "react-bootstrap";

const Wrapper = styled(Form.Label)`
  font-weight: ${({ theme }) => theme.fontWeights.montserrat.medium};
  letter-spacing: 0.08rem;
`;

const Label = (props) => {
  return <Wrapper className="form-label" {...props} />;
};

export default Label;
