import styled from "styled-components/macro";
import theme from "./../../config/theme";

const {
  colors: { primary, alt },
  fontWeights: { montserrat },
  breakpoints,
} = theme;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 38.0714rem;
  text-align: center;
  padding: 4rem 0;

  @media (min-width: ${breakpoints.md}px) {
    min-height: 100vh;
    justify-content: center;
  }
`;

export const ErrorNumber = styled.h1`
  color: ${primary.base};
  font-size: 4rem;
  font-weight: ${montserrat.black};
  letter-spacing: 0.08rem;

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 8.3714rem;
  }
`;

export const ErrorDescription = styled.h4`
  color: ${alt.base};
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 0.08rem;
  font-weight: ${montserrat.black};

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 2.1664rem;
  }
`;
export const ErrorInstructions = styled.p`
  color: #333;
  font-size: 0.8rem;
  letter-spacing: 0.1rem;

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 1.1264rem;
  }
`;
