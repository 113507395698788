import styled from "styled-components/macro";
import { Table } from "react-bootstrap";
import { darken } from "../../utils/color";

export const TableWrapper = styled.div`
  .table {
    thead {
      th {
        border-bottom-width: 0;
        vertical-align: middle;

        &:first-child {
          padding-left: 1.2rem;
          @media print {
            padding-left: 0;
          }
        }
        &:last-child {
          padding-right: 1.2rem;
          @media print {
            padding-right: 0;
          }
        }
      }
    }
    thead.thead-dark {
      th {
        background-color: ${({ theme }) => theme.colors.grey.heavy};
        &:nth-child(even) {
          background-color: ${({ theme }) =>
            darken(theme.colors.grey.heavy, 0.05)};
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.9rem;
    }
  }
  th {
    &:nth-child(odd) {
      background-color: ${({ theme }) => theme.colors.alt.base};
      color: ${({ theme }) => theme.colors.alt.text};
    }
    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.alt.darken};
      color: ${({ theme }) => theme.colors.alt.text};
    }
  }
  .table-striped {
    tbody {
      tr {
        td {
          vertical-align: middle;
          border-top-width: 0;
          font-weight: ${({ theme }) => theme.fontWeights.montserrat.medium};
          padding: 0.42rem 0.9rem;

          &:first-child {
            padding-left: 1.2rem;
            @media print {
              padding-left: 0;
            }
          }
          &:last-child {
            padding-right: 1.2rem;
            @media print {
              padding-right: 0;
            }
          }
          &:nth-child(even) {
            background-color: rgba(0, 0, 0, 0.03);
          }
        }
        &:nth-child(odd) {
          background-color: ${({ theme }) => theme.colors.grey.lighter};
        }
        &:nth-child(even) {
          background-color: ${({ theme }) => theme.colors.grey.base};
        }
      }
    }
  }
`;
