import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams, useHistory, useLocation
} from "react-router-dom";

import { Container, Row, Col } from 'react-bootstrap';

import * as CartService from 'utils/cart.service';
import * as StorageService from 'utils/storage.service';
import * as AuthService from 'utils/auth.service';


/*
 *  The primary container for the page
 */
export function CartCheckout(props) 
{
  const { priceId } = useParams();

  const search = useLocation().search;
  const additionalUsers = new URLSearchParams(search).get('additionalUsers');

  return <Container>
    {(!priceId) ? <NoPriceSelected /> : <PriceSelected priceId={priceId} additionalUsers={additionalUsers}/>}
  </Container>
}

/*
 *  If you somehow land here without a plan selected,
 *    it should redirect you back to the plans page.
 */
function NoPriceSelected() {
  return <p className="lead">You must select a plan.</p>
}

/*
 *  
 */
function PriceSelected(props) 
{
  const history = useHistory();

  // if we're logged in already
  if (AuthService.isLoggedIn()) {
    // acknowledge the redirect request
    StorageService.remove('next');

    // get the price obj
    CartService.findPrice(props.priceId)
      .then(price => {
        if (!price) { throw props.priceId; }
        // save it in the cart
        CartService.selectPrice(price, props.additionalUsers);
        // and find it's associate product
        CartService.findProduct(price.product.id)
          .then(product => {
            // then save it in the cart too
            CartService.selectProduct(product, props.additionalUsers);
          })
          .then(() => {
            // then do the redirect
            history.push('/profile/cart/payment');
          });
      })
      .catch(err => {
        console.log(`That plan does not exist!`);
        history.push('/Home');
      });

  // otherwise
  } else {
    // request to be redirected back here when you've logged in.
    StorageService.add('next', `/checkout/${props.priceId}?additionalUsers=${props.additionalUsers}`);
    // and ask them to log in.
    history.push('/Login');
  }

  return null;
}
