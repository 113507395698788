import styled from "styled-components/macro";

export default styled.small`
  font-size: 0.8571rem;
  letter-spacing: 0.05rem;

  &.text-muted {
    color: "#8e8e8e";
  }
`;
