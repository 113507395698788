import React from "react";
import Select from "react-select";

import theme, { colors } from "../../config/theme";

export const customStyles = {
  indicatorsContainer: (provided, state) => ({
    ...provided,
    paddingRight: "2px",
    background: "#b5b5b5",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "8px 12px",
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "1rem",
    minHeight: theme.forms.inputHeight,
    backgroundColor: theme.colors.grey.base,
    borderColor: theme.colors.grey.base,
    boxShadow: state.isFocused ? theme.forms.boxShadow : null,
    "&:hover": {
      borderColor: theme.colors.grey.base,
    },
  }),
  indicatorSeparator: (provided, state) => ({
    backgroundColor: "transparent",
  }),
};

const customTheme = (baseTheme) => ({
  ...baseTheme,
  borderRadius: baseTheme.borderRadius.base,
  colors: {
    ...baseTheme.colors,
    primary: colors.primary.base,
    primary25: theme.colors.grey.light,
    primary50: theme.colors.grey.base,
  },
});

export default ({ styles, ...props }) => (
  <Select
    styles={{ ...customStyles, ...styles }}
    theme={customTheme}
    {...props}
  />
);
