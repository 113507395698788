import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// SubComps
import Footer from "./Footer.styles";
import { Container } from "../Bootstrap";

const FooterBar = (props) => {
  const [currentYear, setCurrentYear] = useState(null);

  // componentDidMount
  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <div>
      <Footer>
        <Container>
          <span className="footer_copyright">
            &copy; Presentii {currentYear}
          </span>
        </Container>
      </Footer>
    </div>
  );
};

export default withRouter(FooterBar);
