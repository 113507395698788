import React, {useState, useEffect} from "react";
import {NavLink} from 'react-bootstrap';
import {DateTime} from 'luxon';
import * as CartService from "utils/cart.service";
import * as ProductService from 'utils/products.service';

// this component renders the user's current subscription,
// data from props

//utilities
const formatDate = (date) => {
  return DateTime.fromMillis(date * 1000).toFormat('LLL dd, yyyy');
};

export default function Subscription(props) {
  const [product, setProducts] = useState([]);
  const [pending, setPending] = useState(true);

  const fetchProduct = async () => {
    const prod = await ProductService.findProduct(props.subscription.plan.product);
    setProducts(prod);
    setPending(false);
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  if(pending) {
    return (
      <div className={`card border-0 rounded-0 col-md-4 text-center px-0 plan-subscribed`}>
        <div className="card-body">
          <h3 className="card-title">Loading...</h3>
        </div>
      </div>
    );
  }

  const price = CartService.getValidPrices(props.subscription.plan.interval, product.prices);
  const subBasePrice = price.tiers[0].flat_amount
    ? (price.tiers[0].flat_amount / 100) //if tier has flat_amount for base
    : (price.tiers[1].unit_amount / 100); //else use next tier unit_amount as base
  const subUserPrice = (price.tiers[1].unit_amount / 100);
  const extraUsers = props.subscription.quantity - product.metadata.included_users;
  const subPrice = (subBasePrice + (extraUsers * subUserPrice)).toFixed(2).replace(/[.,]00$/, "");

  return (
    <div className={`card border-0 rounded-0 col-md-4 text-center px-0 plan-${product.name.toLowerCase()}`}>
      <div className="card-body">
        <h3 className="card-title">{product.name}</h3>
        <div className="card-subtitle">
          <p>
            ${subPrice}
            <span>/{props.subscription.plan.interval}</span>
          </p>
        </div>
        <table className="table table-striped">
          <tbody>
            <tr><td>
              Users: {props.subscription.quantity}
            </td></tr>
            <tr><td>
              Start Date: {formatDate(props.subscription.start_date)}
            </td></tr>
            <tr><td>
              Next Billing Date: {formatDate(props.subscription.current_period_end)}
            </td></tr>
          </tbody>
        </table>
        {props.onCancel
          ? <button
            className='btn rounded-0'
            onClick={() => props.onCancel(props.subscription.id)}
          >
            Confirm Cancel
          </button>
          : <NavLink
            to='/profile/my-subscription/cancel'
            href='/profile/my-subscription/cancel'
            className='btn rounded-0'
          >
            Cancel Subscription
          </NavLink>
        }
      </div>
    </div>
  );
}
