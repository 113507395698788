import React from 'react';

import { Row, Col, NavLink, Button } from 'react-bootstrap';

import * as CartService from 'utils/storage.service';

export function CartFinished (props)
{
  return <React.Fragment>
    <p>job's done!!!</p>
  </React.Fragment>
}
