import React from "react";

import { Modal } from "react-bootstrap";
import AlertModal from "./../Modals/AlertModal";
import { Button } from "./../Bootstrap";

const PreFinishModal = ({ endSession, ...props }) => {
  return (
    <AlertModal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdropClassName="modal-backdrop--medium"
      {...props}
    >
      <Modal.Body>
        <h4 className="alert-modal__title text-uppercase text-center font-weight-bold">
          Are you sure you want to exit?
          <br />
          Exiting now will end your session.
        </h4>
      </Modal.Body>
      <Modal.Footer>
        <Button color="secondary" onClick={props.onHide}>
          continue recording
        </Button>
        <Button onClick={endSession} color={props.variant}>
          end session
        </Button>
      </Modal.Footer>
    </AlertModal>
  );
};

export default PreFinishModal;
