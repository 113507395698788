import { ListGroup } from "react-bootstrap";
import styled, { css } from "styled-components/macro";
import theme from "./../../config/theme";

const {
  colors: { alt, grey },
  fontWeights: { montserrat },
} = theme;

// ListGroup base styles
export const listGroupBase = css`
  .list-group-item {
    border: 3px solid ${grey.base};
    margin-bottom: -3px;
    padding: 1.6429rem 2.2143rem;
    letter-spacing: 0.05rem;

    &:first-child {
      border-radius: 0;
    }
    &:last-child {
      border-radius: 0;
    }
  }

  .list-group-item-alt {
    .list-group-item__title,
    .list-group-item__price {
      color: ${alt.base};
      font-weight: ${montserrat.black};
    }
  }

  .list-group-item-secondary {
    background: ${grey.base};
    .list-group-item__title,
    .list-group-item__price {
      font-weight: ${montserrat.black};
    }
  }
`;

export default styled(ListGroup)`
  ${listGroupBase}
`;
