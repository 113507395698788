import React, {useState, useEffect} from "react";
import * as BillingService from 'utils/billing.service';
import Subscription from './SubscriptionCard';

// this is the My Subscription tab on Profile /profile/my-subscription
// this component gets information about the current subscription(s)
// and renders the information using <Subscription/> card component

export default function SubscriptionDetails(props) {
  const [subscriptions, setSubscriptions] = useState([]);
  const [pending, setPending] = useState(true);

  const fetchSubscription = async () => {
    const sub = await BillingService.listCurrentSubscriptions();
    setSubscriptions(sub);
    setPending(false);
  };

  useEffect(() => {
    fetchSubscription();
  }, []);

  if(pending) {
    return (
      <h3>Subscription Details</h3>
    );
  }

  if(!subscriptions | subscriptions.length === 0) {
    return (
      <>
        <h3>Subscription Details</h3>
        <h5>You have no active subscriptions.</h5>
      </>
    );
  }

  return (
    <>
      <h3>Subscription Details</h3>
      <div className='product-cards'>
        {subscriptions.map((sub) =>
          <Subscription
            key={sub.id}
            subscription={sub}
          />
        )}
      </div>
    </>
  );
}
