import styled from "styled-components/macro";

export const ToolTipObject = styled.div`
  width: 2.2143rem;
  height: 2.2143rem;
  flex: 0 0 auto;
  border-radius: 3.5714rem;
  font-size: 1.5rem;
  margin-left: 1rem;
  background-color: ${({ theme }) => theme.colors.grey.base};

  ${({ rightAlign }) =>
    rightAlign &&
    `
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(115%, -50%);
    z-index: 1;

    @media screen and (max-width: 576px) {
      transform: translate(-10%, -50%);
      z-index: 0;
    }
  `}
`;
