import React from "react";

// SubComps
import { Title, ThemedSection } from "./Section.styles";

const Section = (props) => {
  return (
    <ThemedSection {...props}>
      {props.sectionTitle && (
        <Title smallMargin={props.smallMargin} className="text-uppercase">
          {props.sectionTitle}
        </Title>
      )}
      {props.children}
    </ThemedSection>
  );
};

export default Section;
