import styled from "styled-components/macro";

export default styled.footer`
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding: 1rem 0;
  background-color: ${({ theme }) => theme.colors.grey.heavy};
  text-align: right;
  font-size: 0.7143rem;
  color: #fff;
  z-index: 9999;

  .footer_copyright {
    font-weight: 400;
  }
`;
