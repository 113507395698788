import React, { useRef, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import * as sessionActions from "../actions/sessionActions";
import moment from "moment";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {Form, Col, Row, Toast, Alert, Spinner} from "react-bootstrap";
import "./Events.css";
import * as BillingService from 'utils/billing.service';
import * as ProductService from 'utils/products.service';
// Assets
import EventPlaceholder from "./../../assets/img/image_placeholder.svg";
// Layout
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
// Components
import {Container, Input, Button, TextArea, Switch, Label} from "../Bootstrap";
import Section from "./../Section/index";
import ToolTip from "./../ToolTip/index";
import Link from "./../Link/Link";
// Thirdparty
import { useForm } from "react-hook-form";
import SurveyForm from "./../SurveyForm";

let myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

let Questions = [];
var fd;
let currentImage;
let typingTimeout;

const ModifyEvent = (props) => {
  // Event Cost States
  const [addSurveyQuestions, setAddSurveyQuestions] = useState(true);
  const [presenterName, setPresenterName] = useState("");

  //sub data
  const [subscription, setSubscription] = useState(undefined);
  // States
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [displayLogo, setDisplayLogo] = useState("");
  const [logoPath, setLogoPath] = useState("");
  const [entranceKey, setEntranceKey] = useState("");
  const [errorColor, setErrorColor] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [loadingPlan, setLoadingPlan] = useState(true);
  const [submitBtnText, setSubmitBtnText] = useState('Save Event');
  const [deleteBtnText, setDeleteBtnText] = useState('Delete Event');
  //dates
  const now = new Date();
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
  const dateToSet = now.toDateString();
  const [scheduledStartDate, setScheduleStartDate] = useState(dateToSet);
  const maxDate = process.env.REACT_APP_MAXDATE;
  //unique event keys
  const [validKey, setValidKey] = useState(null);
  const [generatedKey, setGeneratedKey] = useState(false);
  // Event MultipleSubmission State
  const [isLoading, setIsLoading] = useState(false);
  // SurveyRefactor
  const [questionsArray, setQuestionsArray] = useState([]);
  const [questionsErrors, setQuestionsErrors] = useState(false);

  // Refs
  const fileInput = useRef(null);

  //use React Hook Form
  const {register, errors, triggerValidation, handleSubmit} = useForm({
    submitFocusError: false,
    mode: "onBlur"
  });

  const fetchSubscription = async () => {
    const sub = await BillingService.listCurrentSubscriptions(); //get user's sub info
    if(sub.length > 0) {
      const prod = await ProductService.findProduct(sub[0].plan.product); //get sub's product info
      setSubscription(prod);
    }
    setLoadingPlan(false);
  };

  const onSubmitHandler = (data, e) => {
    setIsLoading(true);
    setSubmitBtnText('Working...');
    e.preventDefault();

    var offsetFinal = "-05:00";

    if(addSurveyQuestions) {
      if(questionsArray.length === 0) {
        setQuestionsErrors(true);
        setIsLoading(false);
        return false;
      } else {
        setQuestionsErrors(false);
        setIsLoading(false);
      }
    }

    if (logoPath !== "") {
      const request2 = new Request(`${myServer}/api/v1/Event/image`, {
        credentials: "include",
        method: "PUT",
        headers: new Headers({
          event: props.eventID,
        }),
        body: fd,
      });
      fetch(request2)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          return error;
        });
    }

    var check = true;

    //remove empty questions and answers
    for (let i = 0; i < questionsArray.length; i++) {
      if (questionsArray[i].text == '') {
        questionsArray.splice(i, 1);
      } else {
        for (let j = 0; j < questionsArray[i].options.length; j++) {
          if (questionsArray[i].options[j] == '') {
            questionsArray[i].options.splice(j, 1);
          }
        }
        //invalid number of questions
        if (questionsArray[i].options.length < 2) {
          return;
        }
      }
    }

    const startDateOffset = new Date(scheduledStartDate).getTimezoneOffset() * 60; // timezone offset 240 * 60 = 14400
    const startDate = ((new Date(scheduledStartDate).getTime()) / 1000) + startDateOffset;
    const request = new Request(`${myServer}/api/v1/Event`, {
      method: "PUT",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        title: title,
        eventText: text,
        scheduledStartTime: startDate,
        presenterName: presenterName,
        TimeZone: "America/New_York",
        Logo: currentImage,
        offset: offsetFinal,
        Questions: questionsArray,
        eventID: props.eventID,
        estimatedAttendees: subscription.metadata.participants,
        survey: check,
      }),
    });

    fetch(request)
      .then(function (response) {
        response.json().then((res) => {
          if (res.message === "Updated") {
            // Refactor This
            setToastMsg("Updated Event");
            setShowToast(true);
            props.actions.UpdateScore(1);
            setTimeout(() => setRedirect(true), 5000);
          }
        });
      })
      .catch((error) => {
        setIsLoading(false);
        return error;
      });
  };

  const changeKey = async (e) => {
    if(typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setEntranceKey(e.target.value);
    typingTimeout = setTimeout(async function() {
      const request = new Request(`${myServer}/api/v1/checkKey`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({key: entranceKey, eventID: Number(props.eventID)}),
      });
      await fetch(request).then((response) => {
        response.json().then((res) => {
          if(res.message === "already used") {
            setValidKey(false);
            setGeneratedKey(true);
            setErrorColor("red");
          } else if(res.message === "success") {
            setValidKey(true);
            setGeneratedKey(false);
            setErrorColor("");
          } else {
            setValidKey(false);
            setGeneratedKey(true);
          }
        });
      });
    }, 500);
  };

  const deleteEventHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setDeleteBtnText('Working...');
    const deleteEventRequest = new Request(`${myServer}/api/v1/Event`, {
      method: "DELETE",
      credentials: "include",
      headers: new Headers({
        eventid: props.eventID,
      }),
    });

    fetch(deleteEventRequest)
      .then(function (response) {
        response.json().then((res) => {
          if (res.message === "Updated") {
            // Refactor This
            setToastMsg("Event deleted successfully");
            setShowToast(true);
            setIsLoading(false);
            setTimeout(() => setRedirect(true), 5000);
          } else {
            setIsLoading(false);
          }
        });
      })
      .catch((error) => {
        setIsLoading(false);
        return error;
      });
  };

  const handleClickFileInput = () => {
    fileInput.current.click();
  };

  // componentDidMount
  useEffect(() => {
    fetchSubscription();

    async function loadEventData() {
      var event = props.eventID;

      const request = new Request(`${myServer}/api/v1/Event`, {
        method: "GET",
        credentials: "include",
        headers: ({ "Content-Type": "application/json" }, { eventID: event }),
      });
      fetch(request)
        .then(function (response) {
          response.json().then((res) => {
            var copy = Array.from(res.data);

            //Delete duplicated questions by Question ID
            var result = copy.reduce(
              (acc, o) => (
                (acc[o.questionID] = (acc[o.questionID] || 0) + 1), acc
              ),
              {}
            );

            // Get number of questions
            var numOfQuestions = Object.keys(result).length;
            var optionsArray;

            for (let j = 0; j < numOfQuestions; j++) {
              optionsArray = [];
              for (let i = 0; i < copy.length; i++) {
                if (copy[i].questionID == Object.keys(result)[j]) {
                  Questions[j] = {
                    id: copy[i].questionID,
                    text: copy[i].questionText,
                    type: copy[i].type,
                  };
                  var text = {
                    text: copy[i].optionText,
                    id: copy[i].answerOptionID,
                  };
                  optionsArray.push(text);
                }
              }
              optionsArray.push({
                id: '',
                text: ''
              });
              if (Questions.length > 0) {
                Questions[j].options = optionsArray;
              }
            }
            Questions.push({
              id: '',
              text: '',
              type: 'Multiple Choice',
              options: [{
                id: '',
                text: ''
              }]
            });

            setQuestionsArray(Questions);

            var currentImage = res.data[0].Logo;

            if (currentImage !== "image_placeholder.png" && currentImage != null) {
              if (res.usingCompany === "true") {
                setDisplayLogo(
                  `${myServer}/Uploads/Users/${res.data[0].companyID}/images/${currentImage}`
                );
              } else {
                setDisplayLogo(
                  `${myServer}/Uploads/Events/${res.data[0].eventID}/images/${currentImage}`
                );
              }
            } else if (currentImage != null) {
              setDisplayLogo(`${myServer}/Uploads/Events/${currentImage}`);
            }

            setTitle(res.data[0].Title);
            setText(res.data[0].eventText);
            setPresenterName(res.data[0].presenterName);
            const date = new Date(0);
            date.setUTCSeconds(res.data[0].scheduledStartTime);
            date.setMinutes(date.getMinutes());
            const dateToSet = moment(date).format('YYYY-MM-DD');

            setScheduleStartDate(dateToSet);
            setAddSurveyQuestions(res.data[0].survey);
            setEntranceKey(res.data[0].entranceKey);
          });
        })
        .catch((error) => {
          return error;
        });
    }

    loadEventData();

    setDisplayLogo(EventPlaceholder);
  }, []);

  const fileSelectedHandler = (e) => {
    var fileInput =  document.getElementById('inputGroupFile01');        
    var filePath = fileInput.value; 
    // Allowing file type 
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i; 
    if (!allowedExtensions.exec(filePath)) { 
      alert('Please upload .jpg, .jpeg or .png files'); 
      fileInput.value = ''; 
      return false; 
    }  
    else {
      e.preventDefault();
      if(e.target.files[0]) {
        fd = new FormData();
        fd.append("Logo", e.target.files[0]);
        setLogoPath(e.target.files[0].name);
        setDisplayLogo(URL.createObjectURL(e.target.files[0]));

      }
    }
  };

  const planKeyword = {
    color: '#2ea39e',
    fontWeight: '700'
  };

  const spinner = (
    <div className="d-flex justify-content-center">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );

  if (redirect) {
    return <Redirect to="/Home"></Redirect>;
  }

  if(loadingPlan) {
    return (
      <DefaultLayout>
        <Container>
          <Section sectionTitle="Edit Event">
            {spinner}
          </Section>
        </Container>
      </DefaultLayout>
    );
  }

  if(subscription === undefined) {
    return (
      <DefaultLayout>
        <Container>
          <Section sectionTitle="Create Event">
            <p>Register today to take advantage of events!</p>
            <Link href="/profile/cart/products">
              See our plans to get started!
            </Link>
          </Section>
        </Container>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <Container>
        <Form onSubmit={handleSubmit(onSubmitHandler)}>
          <Row>
            <Col xs={12} lg={9} className="pr-lg-5">
              <Section sectionTitle="Edit Event" bordered="sm">
                <p>Event level: <span style={planKeyword}>{subscription.name}</span>.</p>
                <p>All fields with an asterisk (<span className="text-danger font-weight-normal">*</span>) are mandatory.</p>
                <Form.Row>
                  <Col xs={12} lg={6} className="pr-lg-3">
                    <Form.Group>
                      <Input
                        ref={register({
                          required: true,
                          maxLength: 150,
                        })}
                        variant={"alt"}
                        name="title"
                        maxLength="150"
                        type="text"
                        placeholder="Event Title"
                        defaultValue={title}
                        onChange={(e) => setTitle(e.target.value)}
                      ></Input>
                      {errors.title?.type === "required" && (
                        <small className="text-danger">
                          Event title is required
                        </small>
                      )}
                      {errors.title?.type === "maxLength" && (
                        <small className="text-danger">
                          Event Title max length is 150
                        </small>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Input
                        ref={register({
                          maxLength: 120,
                        })}
                        variant={"alt"}
                        name="presenterName"
                        maxLength="120"
                        type="text"
                        placeholder="Presenter Name"
                        defaultValue={presenterName}
                        onChange={(e) => {
                          setPresenterName(
                            e.target.value
                          );
                        }}
                      />
                      {errors.presenterName?.type === "maxLength" && (
                        <small className="text-danger">
                          Presenter name max length is 120
                        </small>
                      )}
                    </Form.Group>
                    <Form.Group className="position-relative">
                      <Input
                        style={{borderColor: errorColor}}
                        variant={"alt"}
                        maxLength="20"
                        name="entranceKey"
                        type="text"
                        isValid={validKey}
                        placeholder="Event Key*"
                        defaultValue={entranceKey}
                        onFocus={(e) => changeKey(e)}
                        onChange={(e) => changeKey(e)}
                        onBlur={(e) => changeKey(e)}
                        onKeyUp={(e) => changeKey(e)}
                        onClick={(e) => changeKey(e)}
                        ref={register({
                          required: true,
                          maxLength: 20,
                          validate: {validateEventKey: (value) => validKey}
                        })}
                      ></Input>
                      {errors.entranceKey?.type === "required" && (
                        <small className="text-danger">
                          Event Key is required
                        </small>
                      )}
                      {errors.entranceKey?.type === "maxLength" && (
                        <small className="text-danger">
                          Event Key max length is 20
                        </small>
                      )}
                      {errors.entranceKey?.type === "validateEventKey" && (
                        <small className="text-danger">
                          The Entrance Key has already been used, please use another Key.
                        </small>
                      )}
                      <ToolTip
                        rightAlign
                        tooltipText="Your attendees will be able to login easily on their personal smart devices using your unique event key."
                      ></ToolTip>
                    </Form.Group>
                    <Form.Group>
                      <Input
                        ref={register({
                          required: true,
                        })}
                        name="Date"
                        type="date"
                        variant={"alt"}
                        value={scheduledStartDate}
                        max={maxDate}
                        required
                        onChange={(e) => setScheduleStartDate(e.target.value)}
                      ></Input>
                      {errors.Date?.type === "required" && (
                        <small className="text-danger">
                          Event date is required
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={6} className="pl-lg-5">
                    <Form.Group>
                      <TextArea
                        ref={register({
                          maxLength: 500,
                        })}
                        maxLength="500"
                        as="textarea"
                        rows="6"
                        variant="alt"
                        name="Text"
                        type="text"
                        placeholder="Event Description (500 characters) to be shared with attendees when they provide feedback."
                        defaultValue={text}
                        onChange={(e) => setText(e.target.value)}
                      ></TextArea>
                      <small className="text-muted">{text.length} / 500</small>
                      {errors.Text?.type === "maxLength" && (
                        <small className="text-danger">
                          Description max length is 500
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row className="flex-column-reverse flex-lg-row">
                  <Col xs={12} lg={6} className="pr-lg-3">
                    <Form.Group className="mb-4">
                      <div className="d-flex align-items-center position-relative mb-4">
                        <Label className="mb-0">Event Image:</Label>
                        <ToolTip tooltipText="Plan the details for your upcoming presentation including event image. This unique event detail will be shared with your attendees as they log in to your presentation."></ToolTip>
                      </div>
                      <div className="d-flex flex-column flex-md-row">
                        <div className="flex-grow-1 flex-shrink-1">
                          <img
                            className="rounded img-fluid w-100 mb-2"
                            src={displayLogo}
                            alt=""
                            width="100"
                            height="80"
                          />
                        </div>
                        <div className="flex-grow-1 pl-2 text-md-right text-sm-left">
                          <input
                            ref={fileInput}
                            type="file"
                            className="custom-file-input d-none"
                            id="inputGroupFile01"
                            aria-describedby="inputGroupFileAddon01"
                            name="Logo"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => fileSelectedHandler(e)}
                          />
                          <Button color="alt" onClick={handleClickFileInput}>
                            upload image
                          </Button>
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={6} className="pl-lg-5">
                    <p>Maximum number of event participants: <span style={planKeyword}>
                      {subscription.metadata.participants}
                    </span>.</p>
                  </Col>
                </Form.Row>
              </Section>
              <Section
                sectionTitle="Add survey questions"
                bordered="sm"
              >
                <Form.Row>
                  <Col md={6} className="mb-4">
                    <Form.Group className="d-flex align-items-center">
                      <span className="mr-4">
                      Ask up to <span style={planKeyword}>{subscription.metadata.survey_questions}</span> multiple choice
                      questions following your presentation
                      </span>
                    </Form.Group>
                  </Col>
                </Form.Row>

                <SurveyForm
                  questions={questionsArray}
                  setQuestions={setQuestionsArray}
                  questionsErrors={questionsErrors}
                  subscription={subscription}
                />
              </Section>

            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Section className="mb-3">
                <div>
                  <Button
                    className="mr-1"
                    disabled={isLoading}
                    color="alt"
                    type="submit"
                    size="med"
                  >
                    {submitBtnText}
                  </Button>
                  <Button
                    disabled={isLoading}
                    onClick={(e) => deleteEventHandler(e)}
                    variant="danger"
                  >
                    {deleteBtnText}
                  </Button>
                </div>
              </Section>
            </Col>
            <Col xs={12}>
              <Section>
                <Link href="https://presentii.com/faq/">
                  Need Help? Check out our FAQs
                </Link>
              </Section>
            </Col>
          </Row>
        </Form>

        {/* Toast */}
        <div
          style={{
            position: "fixed",
            width: "350px",
            bottom: "80px",
            right: "40px",
          }}
        >
          <Toast
            className="presentii-toast"
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
          >
            <Toast.Header>
              <strong className="mr-auto">Presentii</strong>
            </Toast.Header>
            <Toast.Body>{toastMsg}</Toast.Body>
          </Toast>
        </div>
      </Container>
    </DefaultLayout>
  );
};

function mapStateToProps(state) {
  return {
    isAdmin: state.isAdmin,
    logged_in: state.session,
    loggedOut: state.loggedOut,
    companyID: state.companyID,
    userZone: state.userZone,
    creditScore: state.creditScore,
    eventID: state.eventID,
    payment: state.payment,
    pricing: state.pricing,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ModifyEvent);
