import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Redirect } from "react-router-dom";
var myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

import { useMediaQuery } from "react-responsive";
import theme from "../../config/theme";

// Assets
import { ReactComponent as Logo } from "../../assets/img/logo_navigation.svg";
import { ReactComponent as LoginBg } from "../../assets/img/login_waves_desktop.svg";
import { ReactComponent as LoginBgMobile } from "../../assets/img/login_waves_mobile.svg";

// Components
import LoginLayout from "../../layouts/LoginLayout/LoginLayout";
import { Paragraph } from "../../components/Typography";
import {
  Wrapper,
  MainContent,
  LeftColumn,
  RightColumn,
  FormContainer,
  LogoContainer,
  TabsContainer,
  BackgroundContainer,
  BodyStyles,
  Title
} from "./PasswordReset.styles";


export default class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      password2: "",
      redirect: false,
      token: "",
      response: "",
      showAlert: false,
      invalidPassword: false,
      acceptedTerms: true
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    var self = this;
    if (self.state.password !== self.state.password2) {
      this.setState(
        {
          response:
            "The Passwords you have entered do not match. Please double-check and try again",
        },
        () => {
          this.setState(
            {
              showAlert: true,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  showAlert: false,
                });
              }, 5000);
            }
          );
        }
      );
    } else {
      let self = this;
      const request = new Request(myServer + "/api/v1/resetPass", {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: self.state.token,
          Pass: self.state.password,
        }),
      });
      fetch(request).then((response) => {
        response.json().then((res) => {
          if (res.message === "success") {
            self.setState({
              redirect: true,
            });
          }
        });
      });
    }
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });

    let responseText = "";
    let oneLowercase = /[a-z]/.test(e.target.value);
    let oneUpperCase = /[A-Z]/.test(e.target.value);
    let oneDigit = /[0-9]/.test(e.target.value);
    let lengthInvalid = false;

    if (!oneLowercase) {
      responseText += "Password must have at least 1 lowercase letter. ";
    }
    if (!oneUpperCase) {
      responseText += "Password must have at least 1 uppercase letter. ";
    }
    if (!oneDigit) {
      responseText += "Password must have at least 1 number. ";
    }
    if (e.target.value.length < 8) {
      lengthInvalid = true;
      responseText += "Password must have at least 8 characters. ";
    } else if (e.target.value.length > 32) {
      lengthInvalid = true;
      responseText += "Password maximum length is 32 characters";
    }

    if (!oneLowercase || !oneUpperCase || !oneDigit || lengthInvalid) {
      this.setState({
        invalidPassword: true,
        showAlert: true,
        response: responseText
      });
    } else {
      this.setState({
        invalidPassword: false,
        showAlert: false,
        response: ""
      });
    }
  }

  componentDidMount() {
    let self = this;
    let pathname = this.props.location.pathname.substr(1);

    let code = pathname.split(":");

    const request = new Request(myServer + "/api/v1/checkResetPassCode", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: code[1],
      }),
    });
    return fetch(request).then((response) => {
      return response.json().then((res) => {
        if (res.message === "success") {
          self.setState(
            {
              redirect: false,
              token: code[1],
              acceptedTerms: res.data ? res.data.acceptedTerms : false
            },
            () => {}
          );
        } else {
          self.setState({
            redirect: true,
          });
        }
      });
    });
  }
  render(props) {
    if (this.state.redirect === true) {
      return <Redirect to="/Login"></Redirect>;
    }

    return (
      <React.Fragment>

            <LoginLayout>

              <MainContent>
                <FormContainer>
                  <Title>Reset Password</Title>
                  <Paragraph className="text-center">
                    Enter your new password twice
                  </Paragraph>

                  <Form onSubmit={this.onSubmit}>
                    <Form.Group>
                      <Form.Control
                        name="password"
                        value={this.state.password}
                        required
                        onChange={this.onChange}
                        type="password"
                        placeholder="New Password"
                      ></Form.Control>

                    </Form.Group>

                    {this.state.showAlert ? (
                      <div
                        style={{ color: "red" }}
                        width="20px"
                        height="5px"
                        variant="danger"
                      >
                        <p>{this.state.response}</p>
                      </div>
                    ) : (
                      ""
                    )}

                    <Form.Group>
                      <Form.Control
                        name="password2"
                        value={this.state.password2}
                        required
                        onChange={this.onChange}
                        type="password"
                        placeholder="Confirm New Password"
                      ></Form.Control>
                    </Form.Group>

                    {!this.state.acceptedTerms ? (
                      <React.Fragment>
                        <div
                          style={{ color: "red" }}
                          width="20px"
                          height="5px"
                          variant="danger"
                        >
                          <p>You need to accept the terms of use</p>
                        </div>
                      </React.Fragment>
                    ) : (
                      ""
                    )}

                    <Button
                      disabled={this.state.invalidPassword}
                      className="btn btn-md btn-primary btn-block"
                      onClick={this.onSubmit}
                      id="submit"
                      size="md"
                    >
                      Update Password
                    </Button>
                  </Form>

                </FormContainer>
              </MainContent>

            </LoginLayout>

      </React.Fragment>
    );
  }
}
