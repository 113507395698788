import React, { useState } from "react";
import { Form } from "react-bootstrap";

// Thirdparty
import { Input, Button } from "../../components/Bootstrap";
import { useForm } from "react-hook-form";
import LoginLayout from "../../layouts/LoginLayout/LoginLayout";
import {
  FormContainer,
  FormGroupContainer,
  LoginButtonContainer,
  MainContent,
  TabsContainer,
  Title,
} from "../../layouts/LoginLayout/LoginLayout.styles";
import Link from "../../components/Link/Link";
import { Paragraph } from "../Typography";
import { LinkContainer } from "react-router-bootstrap";

var myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

const ForgottenPassword = () => {
  // States
  const [sent, setSent] = useState("hidden");

  // Prevent Multiple Submission
  const [isLoading, setIsLoading] = useState(false);

  // Input States
  const [email, setEmail] = useState("");

  // Use React Hook Form
  const { register, errors, handleSubmit } = useForm();

  const onSubmitHandler = (data, e) => {
    setIsLoading(true);
    e.preventDefault();
    const request = new Request(myServer + "/api/v1/forgotPass", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        email: email,
      }),
    });
    return fetch(request).then((response) => {
      return response
        .json()
        .then((res) => {
          if (res.message === "success") {
            setSent("visible");
            setIsLoading(false);
          } else {
            setIsLoading(false);
            console.log("The email doesn't exist");
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    });
  };

  let checkMail = (
    <p style={{ visibility: sent }}>Email Sent! check your email.</p>
  );

  return (
    <LoginLayout>
      <MainContent>
        <FormContainer>
          <Title>RECOVER PASSWORD</Title>
          <Paragraph className="text-center">
            Enter your existing email address.
          </Paragraph>
          <FormGroupContainer>
            <Form onSubmit={handleSubmit(onSubmitHandler)}>
              <Form.Group>
                <Input
                  name="email"
                  ref={register({
                    required: true,
                    pattern: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                  })}
                  placeholder="Email address"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Input>
                {errors.email?.type === "required" && (
                  <small className="text-danger">Email is required</small>
                )}
                {errors.email?.type === "pattern" && (
                  <small className="text-danger">
                    Please provide a valid email
                  </small>
                )}
              </Form.Group>
              <Form.Group className="pt-4">
                <LoginButtonContainer>
                  <Button
                    className="btn btn-md btn-primary mx-auto mx-md-0 d-block d-md-inline"
                    type="submit"
                    id="submit"
                    size="lg"
                    color="primary"
                    disabled={isLoading}
                  >
                    Recover password
                  </Button>
                </LoginButtonContainer>
              </Form.Group>
              {checkMail}
              <div>
                <div className="pt-3 mb-2">
                  <Link href="/signup">Need an Account? Sign Up.</Link>
                </div>
                <div className="links mb-2 pt-3">
                  <Link href="/login">If you already have an account click here to login.</Link>
                </div>
              </div>
            </Form>
          </FormGroupContainer>
        </FormContainer>
      </MainContent>
    </LoginLayout>
  );
};

export default ForgottenPassword;
