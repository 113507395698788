/*
 *  
 */
export function add (key, item) {
  localStorage.setItem(key, JSON.stringify(item));
  return item;
}

export function get (key) {
  return JSON.parse(localStorage.getItem(key));
}

export function remove (key) {
  localStorage.removeItem(key);
}
