/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useState, useEffect} from "react";
import {bindActionCreators} from "redux";
import * as sessionActions from "../actions/sessionActions";
import * as BillingService from 'utils/billing.service';
import * as ProductService from 'utils/products.service';
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {Form, Col, Row, Toast, Alert, Spinner} from "react-bootstrap";
import "./Events.css";
// Components
import {Container, Input, Switch, Label, Button, TextArea} from "../Bootstrap";
import Section from "./../Section/index";
import ToolTip from "./../ToolTip/index";
import Link from "./../Link/Link";
// Thirdparty
import {useForm} from "react-hook-form";
import SurveyForm from "./../SurveyForm";
// Layout
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
// Assets
import EventPlaceholder from "./../../assets/img/image_placeholder.svg";

// this component is the /AddEvent page
// takes input values from form and creates an event in the DB
// also allows for optional creation of event's survey questions (max # per sub plan)

var fd;
var currentImage;
let typingTimeout;
let myServer = "";
if(process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

const AddEvent = (props) => {
  //sub data
  const [subscription, setSubscription] = useState(undefined);
  //form states
  const [title, setTitle] = useState("");
  const [presenterName, setPresenterName] = useState("");
  const [text, setText] = useState("");
  const [addSurveyQuestions, setAddSurveyQuestions] = useState(true);
  const [displayLogo, setDisplayLogo] = useState("");
  const [logoPath, setLogoPath] = useState("");
  const [submitBtnText, setSubmitBtnText] = useState('Create Event');
  //dates
  const now = new Date();
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
  const dateToSet = now.toDateString();
  const [scheduledStartDate, setScheduledStartDate] = useState(dateToSet);
  const maxDate = process.env.REACT_APP_MAXDATE;
  //survey
  const [questionsArray, setQuestionsArray] = useState([]);
  const [questionsErrors, setQuestionsErrors] = useState(false);
  //unique event keys
  const [entranceKey, setEntranceKey] = useState("");
  const [validKey, setValidKey] = useState(null);
  const [generatedKey, setGeneratedKey] = useState(false);
  //info and errors msg
  const [errorColor, setErrorColor] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  //loading & etc
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(true);
  const [redirect, setRedirect] = useState(false);
  //Refs
  const fileInput = useRef(null);
  //use React Hook Form
  const {register, errors, triggerValidation, handleSubmit} = useForm({
    submitFocusError: false,
    mode: "onBlur"
  });

  //get current sub
  const fetchSubscription = async () => {
    const sub = await BillingService.listCurrentSubscriptions(); //get user's sub info
    if(sub.length > 0) {
      const prod = await ProductService.findProduct(sub[0].plan.product); //get sub's product info
      setSubscription(prod);
    }
    setLoadingPlan(false);
  };

  // componentDidMount
  useEffect(() => {
    setDisplayLogo(EventPlaceholder);
    fetchSubscription();
  }, []);

  // Handlers
  const onSubmitHandler = async (data, e) => {
    e.preventDefault();
    setIsLoading(true);
    setSubmitBtnText('Working...');

    const offsetFinal = "-05:00";

    //remove empty questions and answers
    let newQuestions = [...questionsArray];
    for (let i = 0; i < newQuestions.length; i++) {
      if (questionsArray[i].text == '') {
        questionsArray.splice(i, 1);
      } else {
        for (let j = 0; j < questionsArray[i].options.length; j++) {
          if (questionsArray[i].options[j].text == '') {
            questionsArray[i].options.splice(j, 1);
          }
        }
      }
    }

    const checkDuplicatedKey = new Request(`${myServer}/api/v1/checkKey`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({key: entranceKey}),
    });
    await fetch(checkDuplicatedKey).then((response) => {
      response.json().then((res) => {
        if(res.message === "already used") {
          setValidKey(false);
          setGeneratedKey(true);
          setErrorColor("red");
        } else if(res.message === "success") {
          setValidKey(true);
          setGeneratedKey(false);
          setErrorColor("");
        }
      });
    });

    if(validKey === false) {
      setAlertMsg(
        `The Entrance Key ${entranceKey} has already been used, please use another Key.`
      );
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setIsLoading(false);
      }, 5000);
    } else {
      var check = true;

      //remove empty questions and answers
      for (let i = 0; i < questionsArray.length; i++) {
        if (questionsArray[i].text == '') {
          questionsArray.splice(i, 1);
        } else {
          for (let j = 0; j < questionsArray[i].options.length; j++) {
            if (questionsArray[i].options[j] == '') {
              questionsArray[i].options.splice(j, 1);
            }
          }
          //invalid number of questions
          if (questionsArray[i].options.length < 2) {
            return;
          }
        }
      }

      const startDateOffset = new Date(scheduledStartDate).getTimezoneOffset() * 60; // timezone offset 240 * 60 = 14400
      const startDate = ((new Date(scheduledStartDate).getTime()) / 1000) + startDateOffset;
      const body = {
        eventText: text,
        scheduledStartTime: startDate,
        title: title,
        presenterName: presenterName,
        TimeZone: "America/New_York",
        offset: offsetFinal,
        Questions: questionsArray,
        estimatedAttendees: Number(subscription.metadata.participants),
        surveyQuestions: check,
        addComments: 1,
        entranceKey: entranceKey,
      };

      if(fd === undefined) {
        const request = new Request(`${myServer}/api/v1/Events`, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            ...body,
            Logo: currentImage,
          }),
        });
        fetch(request)
          .then(function(response) {
            response.json().then((res) => {
              if(res.message === "Created Event") {
                setToastMsg("Event created succesfully");
                setShowToast(true);
                setIsLoading(false);
                setSubmitBtnText('Redirecting...');
                setTimeout(() => setRedirect(true), 5000);
              } else {
                setIsLoading(false);
              }
            });
          })
          .catch((error) => {
            setIsLoading(false);
            setShowToast(true);
            setToastMsg(`Event creation failed. ${error}`);
            console.error(error);
          });
      } else { //else if trying to use company Logo
        const request = new Request(`${myServer}/api/v1/Events`, {
          method: "POST",
          credentials: "include",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            ...body,
            Logo: logoPath,
          }),
        });
        fetch(request)
          .then(function(response) {
            response.json().then((res) => {
              const request2 = new Request(`${myServer}/api/v1/Event/image`, {
                method: "PUT",
                credentials: "include",
                headers: new Headers({
                  event: res.eventID,
                  typeOfUpload: "event",
                }),
                body: fd,
              });
              fetch(request2).then(function(response) {
                response.json().then((res) => {
                  if(res.message === "success") {
                    setToastMsg("Event created succesfully");
                    setShowToast(true);
                    setTimeout(() => setRedirect(true), 5000);
                  }
                });
              });
            });
          })
          .catch((error) => {
            setIsLoading(false);
            setToastMsg(`${error}`);
            setShowToast(true);
            console.error(error);
          });
      }
    }
  };

  const handleClickFileInput = () => {
    fileInput.current.click();
  };

  const changeKey = async (e) => {
    if(typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setEntranceKey(e.target.value);
    typingTimeout = setTimeout(async function() {
      const request = new Request(`${myServer}/api/v1/checkKey`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({key: entranceKey}),
      });
      await fetch(request).then((response) => {
        response.json().then((res) => {
          if(res.message === "already used") {
            setValidKey(false);
            setGeneratedKey(true);
            setErrorColor("red");
          } else if(res.message === "success") {
            setValidKey(true);
            setGeneratedKey(false);
            setErrorColor("");
          } else {
            setValidKey(false);
            setGeneratedKey(true);
          }
        });
      });
    }, 500);
  };

  const onChangeTitle = async (e) => {
    if(typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTitle(e.target.value);
    typingTimeout = setTimeout(async function() {
      if(!validKey || generatedKey) {
        const request = new Request(myServer + "/api/v1/generateKey", {
          method: "POST",
          headers: new Headers({"Content-Type": "application/json"}),
          body: JSON.stringify({title}),
        });
        await fetch(request).then((response) => {
          response.json().then((res) => {
            if(res.status === 200 && res.data.entranceKey !== "") {
              setEntranceKey(res.data.entranceKey);
              setGeneratedKey(true);
            }
          });
        });
      }
    }, 2000);
  };

  const fileSelectedHandler = (e) => {
    const fileInput = document.getElementById('inputGroupFile01');
    const filePath = fileInput.value;
    // Allowing file type 
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if(!allowedExtensions.exec(filePath)) {
      alert('Please upload .jpg, .jpeg or .png files');
      fileInput.value = '';
      return false;
    }
    else {
      e.preventDefault();
      if(e.target.files[0]) {
        fd = new FormData();
        fd.append("Logo", e.target.files[0]);
        setLogoPath(e.target.files[0].name);
        setDisplayLogo(URL.createObjectURL(e.target.files[0]));
      }
    }
  };

  const planKeyword = {
    color: '#2ea39e',
    fontWeight: '700'
  }

  const spinner = (
    <div className="d-flex justify-content-center">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );

  if(redirect) {
    return <Redirect to="/Home"></Redirect>;
  }

  if(loadingPlan) {
    return (
      <DefaultLayout>
        <Container>
          <Section sectionTitle="Create Event">
            {spinner}
          </Section>
        </Container>
      </DefaultLayout>
    );
  }

  if(subscription === undefined) {
    return (
      <DefaultLayout>
        <Container>
          <Section sectionTitle="Create Event">
            <p>Register today to take advantage of event creation!</p>
            <Link href="/profile/cart/products">
              See our plans to get started!
            </Link>
          </Section>
        </Container>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <Container>
        <Form onSubmit={handleSubmit(onSubmitHandler)}>
          <Row>
            <Col xs={12} lg={9} className="pr-lg-5">
              <Section sectionTitle="Create Event" bordered="sm">
                <p>Event level: <span style={planKeyword}>{subscription.name}</span>.</p>
                <p>All fields with an asterisk (<span className="text-danger font-weight-normal">*</span>) are mandatory.</p>

                <Form.Row>

                  <Col xs={12} lg={6} className="pr-lg-3">

                    <Form.Group>
                      <Input
                        ref={register({required: true, maxLength: 150})}
                        variant={"alt"}
                        name="title"
                        autoComplete="off"
                        maxLength="150"
                        type="text"
                        placeholder="Event Title*"
                        defaultValue={title}
                        onChange={(e) => {onChangeTitle(e);}}
                        onBlur={(e) => {onChangeTitle(e);}}
                      />
                      {errors.title?.type === "required" && (
                        <small className="text-danger">
                          Event title is required
                        </small>
                      )}
                      {errors.title?.type === "maxLength" && (
                        <small className="text-danger">
                          Event Title max length is 150
                        </small>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <Input
                        ref={register({maxLength: 120})}
                        variant={"alt"}
                        name="presenterName"
                        maxLength="120"
                        type="text"
                        placeholder="Presenter Name"
                        defaultValue={presenterName}
                        onChange={(e) => {setPresenterName(e.target.value);}}
                      />
                      {errors.presenterName?.type === "maxLength" && (
                        <small className="text-danger">
                          Presenter name max length is 120
                        </small>
                      )}
                    </Form.Group>

                    <Form.Group className="position-relative">
                      <Input
                        style={{borderColor: errorColor}}
                        variant={"alt"}
                        maxLength="20"
                        name="entranceKey"
                        type="text"
                        isValid={validKey}
                        placeholder="Event Key*"
                        defaultValue={entranceKey}
                        onFocus={(e) => changeKey(e)}
                        onChange={(e) => changeKey(e)}
                        onBlur={(e) => changeKey(e)}
                        onKeyUp={(e) => changeKey(e)}
                        onClick={(e) => changeKey(e)}
                        ref={register({
                          required: true,
                          maxLength: 20,
                          validate: {validateEventKey: (value) => validKey}
                        })}
                      ></Input>
                      {errors.entranceKey?.type === "required" && (
                        <small className="text-danger">
                          Event Key is required
                        </small>
                      )}
                      {errors.entranceKey?.type === "maxLength" && (
                        <small className="text-danger">
                          Event Key max length is 20
                        </small>
                      )}
                      {errors.entranceKey?.type === "validateEventKey" && (
                        <small className="text-danger">
                          The Entrance Key has already been used, please use another Key.
                        </small>
                      )}
                      <ToolTip
                        rightAlign
                        tooltipText="Your attendees will be able to login easily on their personal smart devices using your unique event key."
                      ></ToolTip>
                    </Form.Group>

                    <Form.Group>
                      <Input
                        ref={register({
                          required: true,
                        })}
                        name="Date"
                        type="date"
                        variant={"alt"}
                        value={scheduledStartDate}
                        min={dateToSet}
                        max={maxDate}
                        onChange={(e) => setScheduledStartDate(e.target.value)}
                      ></Input>
                      {errors.Date?.type === "required" && (
                        <small className="text-danger">
                          Event date is required
                        </small>
                      )}
                    </Form.Group>
                  </Col>

                  <Col xs={12} lg={6} className="pl-lg-5">
                    <Form.Group>
                      <TextArea
                        ref={register({
                          maxLength: 500,
                        })}
                        maxLength="500"
                        as="textarea"
                        rows="6"
                        variant="alt"
                        name="Text"
                        type="text"
                        placeholder="Event Description (500 characters) to be shared with attendees when they provide feedback."
                        defaultValue={text}
                        onChange={(e) => setText(e.target.value)}
                      ></TextArea>
                      <small className="text-muted">{text.length} / 500</small>
                      {errors.Text?.type === "maxLength" && (
                        <small className="text-danger">
                          Description max length is 500
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row className="flex-column-reverse flex-lg-row">
                  <Col xs={12} lg={6} className="p4-lg-3">
                    <Form.Group className="mb-4">
                      <div className="d-flex align-items-center position-relative mb-4">
                        <Label className="mb-0">Event Image:</Label>
                        <ToolTip tooltipText="Plan the details for your upcoming presentation including event image. This unique event detail will be shared with your attendees as they log in to your presentation."></ToolTip>
                      </div>
                      <div className="d-flex flex-column flex-md-row">
                        <div className="flex-grow-1 flex-shrink-1">
                          <img
                            className="rounded img-fluid w-100 mb-2"
                            src={displayLogo}
                            alt=""
                            width="100"
                            height="80"
                          />
                        </div>
                        <div className="flex-grow-1 pl-2 text-md-right text-sm-left">
                          <input
                            ref={fileInput}
                            type="file"
                            className="custom-file-input d-none"
                            id="inputGroupFile01"
                            aria-describedby="inputGroupFileAddon01"
                            name="Logo"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => fileSelectedHandler(e)}
                          />
                          <Button color="alt" onClick={handleClickFileInput}>
                            Upload image
                          </Button>
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={6} className="pl-lg-5">
                    <p>Maximum number of event participants: <span style={planKeyword}>
                      {subscription.metadata.participants}
                    </span>.</p>
                  </Col>
                </Form.Row>
              </Section>
              <Section sectionTitle="Add survey questions" bordered="sm">
                <Form.Row>
                  <Col md={6} className="mb-4">
                    <Form.Group className="d-flex align-items-center">
                      <span className="mr-4">
                        Ask up to <span style={planKeyword}>{subscription.metadata.survey_questions}</span> multiple choice
                        questions following your presentation
                      </span>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <SurveyForm
                  maxQuestions={subscription.metadata.survey_questions}
                  questions={questionsArray}
                  setQuestions={setQuestionsArray}
                  questionsErrors={questionsErrors}
                  subscription={subscription}
                />
              </Section>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Section className="mb-3">
                {showAlert && <Alert variant="danger">{alertMsg}</Alert>}
                <Button color="alt" type="submit" size="med" disabled={isLoading}>
                  {submitBtnText}
                </Button>
              </Section>
            </Col>
            <Col xs={12}>
              <Section>
                <Link href="https://presentii.com/faq/#">
                  Need Help? Check out our FAQs
                </Link>
              </Section>
            </Col>
          </Row>
        </Form>

        {/* Toast */}
        <div
          style={{
            position: "fixed",
            width: "350px",
            bottom: "80px",
            right: "40px",
          }}
        >
          <Toast
            className="presentii-toast"
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
          >
            <Toast.Header>
              <strong className="mr-auto">Presentii</strong>
            </Toast.Header>
            <Toast.Body>{toastMsg}</Toast.Body>
          </Toast>
        </div>
      </Container>
    </DefaultLayout>
  );
};

function mapStateToProps(state) {
  return {
    isAdmin: state.isAdmin,
    logged_in: state.session,
    loggedOut: state.loggedOut,
    companyID: state.companyID,
    role: state.role,
    userZone: state.userZone,
    creditScore: state.creditScore,
    eventID: state.eventID,
    payment: state.payment,
    pricing: state.pricing,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEvent);
