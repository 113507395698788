import React, { Component, useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sessionActions from "../actions/sessionActions";
import Section from "../Section";
import { Link } from "react-router-dom";
import { TableWrapper } from "../Home/Home.styles";
import EventsCollapse from "./components/EventsCollapse";

// Layout
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

//Components
import {
  Table,
  Card,
  Accordion,
  useAccordionToggle,
  AccordionContext,
  Row,
  Col,
} from "react-bootstrap";
import { Container, Button } from "../Bootstrap";

var myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

function ActiveToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const decorativeOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      className={`event-collapse__header ${isCurrentEventKey && "active"}`}
      onClick={decorativeOnClick}
    >
      {children}
    </div>
  );
}

const Coupon = (props) => {
  const [coupons, setCoupons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // componentDidMount
  useEffect(() => {
    getCoupons();
  }, []);

  //Get Coupons
  const getCoupons = () => {
    if (!props.isSuperUser) return;
    const request = new Request(myServer + "/api/v1/coupons", {
      method: "GET",
      credentials: "include",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    fetch(request).then(function (response) {
      response.json().then((res) => {
        setCoupons(res.data);
      });
    });
  };
  const deleteCoupon = (e) => {
    if (!props.isSuperUser) return;
    setIsLoading(true);
    let couponId = e.target.value;
    const request = new Request(myServer + "/api/v1/coupon/" + couponId, {
      method: "DELETE",
      credentials: "include",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    fetch(request).then(function (response) {
      if (response.ok) {
        getCoupons();
        setIsLoading(false);
      }
    });
  };
  return (
    <DefaultLayout>
      <Container>
        <Row className="mt-5 pt-lg-5 justify-content-end">
          <Col xs="auto">
            <Button
              color="alt"
              className="float-none"
              id="AddUser"
              href="/AddCoupon"
            >
              Add a Coupon
            </Button>
          </Col>
        </Row>

        <Section sectionTitle="Coupons">
          <TableWrapper className="d-none d-lg-block">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Code</th>
                  <th>Value</th>
                  <th>Comment</th>
                  <th>Max Redemptions</th>
                  <th>Times Redeemed</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {coupons.map((coupon, index) => (
                  <tr key={coupon.id}>
                    <td>{index}</td>
                    <td>{coupon.code}</td>
                    <td>{coupon.value}</td>
                    <td>
                      {coupon.comment ? coupon.comment : "Without comment"}
                    </td>
                    <td>
                      {coupon.max_redemptions
                        ? coupon.max_redemptions
                        : "Without limit"}
                    </td>
                    <td>{coupon.times_redeemed}</td>

                    <td>
                      <Button
                        color="alt"
                        className="mr-1"
                        name="finished"
                        disabled={coupon.times_redeemed > 0 ? true : false}
                        value={coupon.id}
                        href={`/EditCoupon/${coupon.id}`}
                      >
                        edit
                      </Button>
                      <Button
                        disabled={!props.isSuperUser || isLoading}
                        name={coupon.code}
                        variant="danger"
                        value={coupon.id}
                        onClick={deleteCoupon}
                      >
                        delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableWrapper>

          <EventsCollapse variant="alt" className="d-block d-lg-none">
            {coupons.map((coupon, index) => (
              <Card key={`collapseCard${coupon.id}`}>
                <Card.Header>
                  <ActiveToggle eventKey={coupon.id}>
                    #{index} {coupon.code}
                  </ActiveToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={coupon.id}>
                  <Card.Body>
                    <div className="d-flex flex-column">
                      <p className="event-collapse__details-item">
                        <span>Value: </span>
                        <strong>{coupon.value}</strong>
                      </p>
                      <p className="event-collapse__details-item">
                        <span>Comment: </span>
                        <strong>
                          {coupon.comment ? coupon.comment : "Without comment"}
                        </strong>
                      </p>
                      <p className="event-collapse__details-item">
                        <span>Max Redemptions: </span>
                        <strong>
                          {coupon.max_redemptions
                            ? coupon.max_redemptions
                            : "Without limit"}
                        </strong>
                      </p>
                      <p className="event-collapse__details-item">
                        <span>Times Redeemed: </span>
                        <strong>{coupon.times_redeemed}</strong>
                      </p>

                      <Button
                        disabled={!props.isSuperUser || isLoading}
                        name={coupon.code}
                        variant="danger"
                        value={coupon.id}
                        onClick={deleteCoupon}
                      >
                        Delete
                      </Button>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </EventsCollapse>
        </Section>
      </Container>
    </DefaultLayout>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    isAdmin: state.isAdmin,
    isSuperUser: state.isSuperUser,
    logged_in: state.session,
    loggedOut: state.loggedOut,
    companyID: state.companyID,
    userZone: state.userZone,
    creditScore: state.creditScore,
    eventID: state.eventID,
    role: state.role,
    email: state.email,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
