import styled, { css } from "styled-components";

// Components
const PlayPauseButton = styled.button`
  /* reset Style */
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  background-color: ${({ theme }) => theme.colors.primary.base};
  color: ${({ theme }) => theme.colors.primary.text};
  border: 1px solid ${({ theme }) => theme.colors.primary.darken};
  width: 60px;
  height: 60px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 30px;

    &.playpause-btn__play {
      margin-left: 5px;
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.primary.base};
  }
`;

export default PlayPauseButton;
