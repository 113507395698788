import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sessionActions from "../actions/sessionActions";

// Layout
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

//Components
import {
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Container, Button } from "../Bootstrap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import PropTypes from "prop-types";

let myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

const SuperAdmin = (props) => {
  const [users, setUsers] = useState([]);
  const [stats, setStats] = useState([]);
  const [recent, setRecent] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {}, []);

  const searchUsers = (e) => {
    let string = e.target.value;
    if (string.length < 3) {
      return setUsers([]);
    }
    const request = new Request(
      myServer + `/api/v1/GetAllUsers?string=${string}`,
      {
        method: "GET",
        credentials: "include",
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    fetch(request)
      .then((response) => response.json())
      .then((json) => setUsers(json.users))
      .catch((error) => {
        return error;
      });
  };

  const getOverviewStats = () => {
    const request = new Request(
      myServer + `/api/v1/GetSiteOverview`,
      {
        method: "GET",
        credentials: "include",
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );

    fetch(request)
      .then((response) => response.json())
      .then((json) => {
        setStats([json.siteOverview])
        setRecent(json.siteOverview.usersRecent)
        setEvents(json.siteOverview.userEventsScheduled)
      })
      .catch((error) => {
        return error;
      });
  };

  const loginAsUser = (userID) => {
    let confirmation = confirm(
      "This will log you out and log you in as another user for a temporary session. Is that okay?"
    );
    if (confirmation === true) {
      const {
        actions,
        history,
      } = props;
      actions.logInUser({ userID }, () => {
        history.push("/Home");
      });
    }
  };

  return (
    <DefaultLayout>
      <Container>
        <Tabs>
          <TabList>
            <Tab>Overview</Tab>
            <Tab>All Users</Tab>
          </TabList>
          <TabPanel>
            <h2>Overview</h2>
            <p>Results exclude users with emails under the presentii.com and adhomecreative.com domains.</p>

            {stats.map(({ userCountTotal, userCountTotalActive, userEventsScheduled, userEventsCompleted, userEventsIncomplete }) => {
              return (
                <ul key={userCountTotal}>
                  <li><strong>Total Users: </strong>
                  <span>{userCountTotal}</span></li>
                  <li><strong>Total Active Users: </strong>
                  <span>{userCountTotalActive}</span></li>
                  <li><strong>Events Scheduled: </strong>
                  <span>{userEventsScheduled.length}</span></li>
                  <li><strong>Events Completed: </strong>
                  <span>{userEventsCompleted}</span></li>
                  <li><strong>Events Incomplete: </strong>
                  <span>{userEventsIncomplete}</span></li>
                </ul>
              );
            })}

            <h3>Recent Users</h3>
            <p>Users that have registered within the last 14 days.</p>

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Created</th>
                  <th>Role</th>
                  <th>Activated Company</th>
                  <th>Activated User</th>
                  <th>Number of Events</th>
                </tr>
              </thead>
              <tbody>
                {recent.map(({ userID, companyName, name, email, created, role, activatedUser, activatedCompany, eventCount }) => {
                  return (
                    <tr key={userID}>
                      <td>{companyName}</td>
                      <td>{name}</td>
                      <td>{email}</td>
                      <td>{created}</td>
                      <td>{role}</td>
                      <td>{activatedCompany}</td>
                      <td>{activatedUser}</td>
                      <td>{eventCount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <h3>Scheduled Events</h3>
            <p>Events that are scheduled to happen in the future. A user may override the start time.</p>

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Event Title</th>
                  <th>Entrance Key</th>
                  <th>Presenter</th>
                  <th>Date</th>
                  <th>Attendees</th>
                  <th>Start</th>
                  <th>End</th>
                </tr>
              </thead>
              <tbody>
                {events.map(({ companyName, eventID, title, presenterName, entranceKey, scheduledStartTime, estimatedAttendees, startTime, endTime }) => {
                  return (
                    <tr key={eventID}>
                      <td>{companyName}</td>
                      <td>{title}</td>
                      <td>{entranceKey}</td>
                      <td>{presenterName}</td>
                      <td>{scheduledStartTime}</td>
                      <td>{estimatedAttendees}</td>
                      <td>{startTime}</td>
                      <td>{endTime}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <button color="alt" className="btn btn-primary mb-4" onClick={getOverviewStats}>Refresh</button>

          </TabPanel>
          <TabPanel>
            <h2>All Users</h2>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Search by user email address with at least 3 characters"
                onChange={searchUsers}
              />
            </InputGroup>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>User ID</th>
                  <th>Email</th>
                  <th>CompanyID</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map(({ userID, email, companyID }) => {
                  return (
                    <tr key={userID}>
                      <td>{userID}</td>
                      <td>{email}</td>
                      <td>{companyID}</td>
                      <td>
                        <Button onClick={loginAsUser.bind(this, userID)}>
                          Login as User
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TabPanel>
        </Tabs>
      </Container>
    </DefaultLayout>
  );
};

SuperAdmin.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdmin);
