export const LOG_IN_SUCCESS_COMPANY = "LOG_IN_SUCCESS";
export const LOG_IN = "LOG_IN";
export const LOG_IN_FAILED = "LOG_IN_FAILED";
export const UPDATE_SUCCESS = "UPDATE_SUCCES";
export const LOG_OUT = "LOG_OUT";
export const LOG_IN_SUCCESS_USER = "LOG_IN_SUCCESS_USER";
export const ADD_EVENT = "ADD_EVENT";
export const MODIFY_EVENT = "MODIFY_EVENT";
export const VIEW_EVENT = "VIEW_EVENT";
export const ADD_CARD = "ADD_CARD";
export const MENU_COLLAPSED = "MENU_COLLAPSED";
export const SET_PRICING = "SET_PRICING";
export const SET_SUPERUSER = "SET_SUPERUSER";
export const SET_EVENT_ATTENDEE_COUNTS = "SET_EVENT_ATTENDEE_COUNTS";
