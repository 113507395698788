export const colors = {
  text: {
    base: "#4f4f50",
    light: "#8d8e8f",
  },
  primary: {
    base: "#ef6d53",
    text: "#FFFFFF",
    disabled: "#FAAB9B",
  },
  alt: {
    base: "#2ea39e",
    darken: "#2c9d98",
    text: "#FFFFFF",
  },
  grey: {
    base: "#ebebeb",
    light: "#f0f0f0",
    lighter: "#f6f6f6",
    medium: "#797979",
    dark: "#e4e4e4",
    darker: "#949494",
    heavy: "#4f4f50",
  },
};

const fontWeights = {
  montserrat: {
    regular: "400",
    medium: "500",
    extraBold: "800",
    black: "900",
  },
};

const theme = {
  colors,
  fontWeights,
  breakpoints: {
    xxs: 0,
    xs: 321,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1281,
    xxl: 1670,
  },
  borderRadius: {
    base: 0,
    input: 0,
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
  forms: {
    focusOutlineAlpha: 0.25,
    inputHeight: "2.7rem",
    borderColor: "#d7d7d7",
    focusBorderColor: "#e0e0e0",
    placeholderColor: colors.text.light,
    textColor: colors.text.base,
    switch: {
      width: "4.1429rem",
      height: "2rem",
    },
  },
};

export default theme;
