import React, {useState} from "react";
import {Row, Col} from "react-bootstrap";

import * as ProductService from 'utils/products.service';
import {ProductCard} from './ProductCard';

export function CartProducts(props) {
  const [pending, setPending] = useState(true);
  const [products, setProducts] = useState();

  if(!products) {
    ProductService.searchProducts()
      .then(products => {setProducts(products);})
      .then(() => setPending(false))
      .catch(console.log);
  }

  return <>
    {
      pending
        ? <p>Loading...</p>
        : (products?.length) ? Results(products) : NoResults()
    }
  </>;
}

function Results(products) {
  return (
    <>
      <Col md={6}>
        <h2>Register</h2>
        <p>
          Choose between paying monthly or paying yearly.
          A 15% off discount will apply to yearly subscriptions.
          Upgrade at any point to a yearly subscription to save.
        </p>
      </Col>
      <Row className='product-cards'>
        {products.map(product =>
          <Col md={4} key={`${product.id}-card`}>
            <ProductCard
              addUsers={true}
              interval='month'
              key={product.id}
              product={product}
            />
          </Col>
        )}
      </Row>
    </>
  );
}

function NoResults() {
  return (<p>Loading...</p>);
}
