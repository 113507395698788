import styled from "styled-components/macro";

export const Wrapper = styled.div`
  padding-bottom: 3.0714rem; /*Footer height*/
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    ${({ menuCollapsed }) =>
      !menuCollapsed &&
      `
      // overflow: hidden;
      min-height: 100vh;
    `}
  }
`;
