import React, { Component, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sessionActions from "../actions/sessionActions";
import Section from "./../Section";
import { Link } from "react-router-dom";
import { TableWrapper } from "../Home/Home.styles";
import EventsCollapse from "./components/EventsCollapse";

// Layout
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

//Components
import {
  Table,
  Card,
  Accordion,
  useAccordionToggle,
  AccordionContext,
} from "react-bootstrap";
import { Container, Button } from "./../Bootstrap";

var myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

function ActiveToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const decorativeOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      className={`event-collapse__header ${isCurrentEventKey && "active"}`}
      onClick={decorativeOnClick}
    >
      {children}
    </div>
  );
}
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      companyName: "",
      usre: "",
    };
    this.deactivateUser = this.deactivateUser.bind(this);
    this.resendUserActivation = this.resendUserActivation.bind(this);
    this.getUserRole = this.getUserRole.bind(this);
    this.canDeactivate = this.canDeactivate.bind(this);
  }
  componentDidMount() {
    let self = this;
    const request = new Request(myServer + "/api/v1/getUsers", {
      method: "GET",
      credentials: "include",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    fetch(request).then(function (response) {
      response.json().then((res) => {
        if (!res.users) {
          self.setState({
            companyName: res.companyName,
          });
        } else {
          self.setState({
            user: res.user,
            users: res.users,
            companyName: res.users[0].companyName,
          });
        }
      });
    });
  }

  async deactivateUser(e) {
    let self = this;
    var target = e.target.value;
    const request = new Request(myServer + "/api/v1/deactivateUser", {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        token2: target,
      }),
    });
    await fetch(request).then(function (response) {
      response.json().then((res) => {
        self.setState({
          users: res.users,
        });
      });
    });
  }

  async resendUserActivation(e) {
    let self = this;
    var target = e.target.value;
    const request = new Request(myServer + "/api/v1/reSendActivationEmail", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "email": target
      }),
    });
    await fetch(request).then(function (response) {
      response.json().then((res) => {
        if (res.message == "success") {
          alert("activation email resent");
        }
      });
    });
  }

  getUserRole(id) {
    if (id === 1) {
      return "user";
    } else {
      return "admin";
    }
  }
  canDeactivate(user, index) {
    if (this.props.role == "admin") {
      if (user.email == this.props.email) {
        return true;
      } else if (index === 0) {
        return true;
      } else {
        return false;
      }
    } else if (index === 0) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <DefaultLayout>
        <Container>
          <Button color="alt" id="AddUser" href="/addUser">
            Add a User
          </Button>
          <Section
            className="mt-5 pt-lg-5"
            sectionTitle={this.state.companyName + "'s Users"}
          >
            <TableWrapper className="d-none d-lg-block">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>User Email</th>
                    <th>Role</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.users.map((user, index) => (
                    <tr key={user.userID}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{this.getUserRole(user.role)}</td>
                      <td>
                        { (user.activated == 1)
                          ? <Button
                            disabled={this.canDeactivate(user, index)}
                            name={user.userID}
                            variant="danger"
                            value={user.userID}
                            onClick={this.deactivateUser}
                          >
                            Deactivate
                          </Button>
                        : <Button
                          name={user.userID}
                          value={user.email}
                          onClick={this.resendUserActivation}
                        >
                          Resend Email
                        </Button>
                      }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableWrapper>

            <EventsCollapse variant="alt" className="d-block d-lg-none">
              {this.state.users.map((user, index) => (
                <Card key={`collapseCard${user.userID}`}>
                  <Card.Header>
                    <ActiveToggle eventKey={user.userID}>
                      #{index} {user.name}
                    </ActiveToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={user.userID}>
                    <Card.Body>
                      <div className="d-flex flex-column">
                        <p className="event-collapse__details-item">
                          <span>Name: </span>
                          <strong>{user.name}</strong>
                        </p>
                        <p className="event-collapse__details-item">
                          <span>Email: </span>
                          <strong>{user.email}</strong>
                        </p>
                        <p className="event-collapse__details-item">
                          <span>Role: </span>
                          <strong>{this.getUserRole(user.role)}</strong>
                        </p>
                        <Button
                          disabled={this.canDeactivate(user, index)}
                          name={user.email}
                          variant="danger"
                          value={user.userID}
                          onClick={this.deactivateUser}
                        >
                          Deactivate
                        </Button>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </EventsCollapse>
          </Section>
        </Container>
      </DefaultLayout>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    isAdmin: state.isAdmin,
    logged_in: state.session,
    loggedOut: state.loggedOut,
    companyID: state.companyID,
    userZone: state.userZone,
    creditScore: state.creditScore,
    eventID: state.eventID,
    role: state.role,
    email: state.email,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
