import styled from "styled-components/macro";

import { Container as BSContainer } from "react-bootstrap";

export default styled(BSContainer)`
  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    max-width: 1442px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    max-width: 500px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}px) {
    max-width: 85%;
  }
`;
