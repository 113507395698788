import React, { Component } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Container, Input, Button } from "../../Bootstrap";


export class PersonalDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      form: {},
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      form: {
        name: this.props.profile?.name || "",
        email: this.props.profile?.email || "",
        companyName: this.props.profile?.company?.companyName || "",
        industryName: this.props.profile?.company?.industryName || "",
      }
    })
  }

  onChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ 
      form: Object.assign(this.state.form, {
        [name]: value 
      })
    });
  }

  async onSubmit(event) {
    event.preventDefault();
    
    this.setState({ pending: true });
    
    await this.updateProfile();

    this.setState({ pending: false });
  }

  updateProfile() {
    const host = process.env.REACT_APP_SERVER;
    const url = `${host}/api/v2/profile`;

    const request = new Request(url, {
      method: 'PUT',
      credentials: 'include',
      headers: new Headers({'Content-Type': 'application/json'}),
      body: JSON.stringify(this.state.form)
    });

    return fetch(request)
      // .then(response => response.json())
      .catch(console.log);
  }

  render() {
    return <React.Fragment>
      <h3>Personal Details</h3>

      <Form onSubmit={this.onSubmit}>
        <Row>
          <Col md={8} lg={6}>
            <Form.Group>
              <Form.Label>Your Name</Form.Label>
              <Input
                name="name"
                type="text"
                placeholder="Name"
                variant={"alt"}
                value={this.state.form.name}
                onChange={this.onChange}
                disabled={this.state.pending}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Email address</Form.Label>
              <Input
                name="email"
                type="email"
                placeholder="Enter Email"
                variant={"alt"}
                value={this.state.form.email}
                onChange={this.onChange}
                disabled={this.state.pending}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Company</Form.Label>
              <Input
                name="companyName"
                type="text"
                placeholder="Company"
                variant={"alt"}
                value={this.state.form.companyName}
                onChange={this.onChange}
                disabled={this.state.pending || this.props.profile?.role !== 2}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Industry</Form.Label>
              <Input
                name="industryName"
                type="text"
                placeholder="Industry"
                variant={"alt"}
                value={this.state.form.industryName}
                onChange={this.onChange}
                disabled={this.state.pending || this.props.profile?.role !== 2}
              />
            </Form.Group>

            <Button type="submit" id="submit" size="med"
              disabled={this.state.pending}>
              { this.state.pending ? 'Updating...' : 'Update' }
            </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  }
}
