import React, { useState } from 'react';
import {Row, Col, NavLink} from 'react-bootstrap';
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import * as BillingService from 'utils/billing.service';
import * as CartService from 'utils/cart.service';
import { CreditCardForm } from './credit-card-form';
import {ProductCard} from './ProductCard';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

export function CartPayment(props) {
  const [pending, setPending] = useState(false);
  const [summary, setSummary] = useState();

  const product = CartService.getProduct();
  const extraUsers = CartService.getProductQuantity();
  const totalUsers = +product.metadata.included_users + extraUsers;
  const price = CartService.getPrice();
  const finalPrice = (price.tiers[0].flat_amount + (price.tiers[1].unit_amount * extraUsers)) / 100;

  if (!product) {
    window.location.href = '/profile/cart/products';
  } else if (!price) {
    window.location.href = '/profile/cart/prices';
  }

  if (price && !summary) {
    CartService.calculate({price_id: price.id, quantity: totalUsers})
      .then(setSummary)
      .catch(err => setSummary(true))
  }

  const performPayment = async (card) => {
    setPending(true);

    await BillingService.createPaymentMethod(card.id);

    const result = await CartService.checkout({
      price_id: price.id,
      payment_method: card.id,
      quantity: totalUsers,
    });

    setPending(false);

    if (result) { 
      CartService.clearCart(); 
      window.location.href = '/profile/my-subscription';
    }
  }

  return (
    <>
      <Row className="mt-5 product-cards">
        <Col md={4}>
          <ProductCard
            buttons={false}
            extraUsers={extraUsers}
            interval={price.recurring.interval}
            key={product.id}
            product={product}
            price={price}
          />
        </Col>

        <Col md={4}>
          <table className="table table-lg">
            <thead></thead>
            <tbody>
              <tr>
                <td className="lead">Subtotal:</td>
                <td className="lead text-right">${summary?.subtotal || finalPrice}</td>
              </tr>
              <tr>
                <td className="lead">Taxes:</td>
                <td className="lead text-right">{summary?.taxes ? `$${summary?.taxes}` : 'Calculating...'}</td>
              </tr>
              <tr>
                <td className="lead">Total:</td>
                <td className="lead text-right">{summary?.total ? `$${summary?.total}` : 'Calculating...'}</td>
              </tr>
            </tbody>
          </table>
        </Col>

        <Col md={4}>
          <h2>Enter Payment Details</h2>
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {(elements, stripe) => (
                <CreditCardForm
                  elements={elements}
                  stripe={stripe}
                  onCardCollected={performPayment}
                  pending={pending}>
                </CreditCardForm>
              )}
            </ElementsConsumer>
          </Elements>
        </Col>
      </Row>

      <NavLink to="/profile/cart/prices"
        href="/profile/cart/prices"
        className="btn btn-outline-secondary d-inline-block mb-4"
        onClick={event => CartService.clearPrice()}>
        Go Back
      </NavLink>

    </>
  )
}
