import React from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { Spinner } from "react-bootstrap";
import { Button } from "../../Bootstrap";
import * as sessionActions from "../../actions/sessionActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Form for stripe to take in card
class Form extends React.Component {
  myRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = { cardElement: "", isLoading: false };
    this.setCard = this.setCard.bind(this);
  }
  setCard(e) {
    var element = this.myRef.current.getElement();
    this.setState({ cardElement: element });
  }
  addCard = (ev) => {
    this.setState({ isLoading: true });
    let self = this;
    ev.preventDefault();
    if (this.props.stripe) {
      self.props.stripe
        .createPaymentMethod("card", self.state.cardElement)
        .then((payload) => {
          if (payload.error) {
            // console.log(payload.error)
          } else {
            self.props.handler && self.props.handler();
            self.props.actions.AddCard(payload);
          }
        })
        .finally(() => {
          self.setState({ isLoading: false });
        });
    } else {
    }
  };
  render() {
    return (
      <form onSubmit={this.addCard}>
        <CardElement
          onChange={this.setCard}
          ref={this.myRef}
          hidePostalCode={true}
        />
        <br></br>
        <Button color="alt" disabled={this.state.isLoading} type="submit">
          {this.state.isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Add"
          )}
        </Button>
      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(injectStripe(Form));
