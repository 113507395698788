import React, { Component } from "react";
import "./LandingPage.css";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
    };
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  componentDidMount() {
    if (this.props.loggedIn === true) {
      this.setState({
        loggedIn: true,
      });
    }
  }

  render() {
    var forward;
    const { history } = this.props;
    if (this.state.loggedIn === false) {
      history.push("/Home");
    }
    return (
      <div className="cover-container d-flex h-100 p-3 mx-auto flex-column">
        {forward}
        <h1 className="cover-heading">Presentii</h1>
        <Link to="/Login">Login</Link>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    loggedOut: state.loggedOut,
    loggedIn: state.loggedIn,
    isAdmin: state.isAdmin,
    role: state.role,
  };
}

export default connect(mapStateToProps, null)(withRouter(LandingPage));
