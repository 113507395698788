//TODO: Implement Google Login
//import GoogleLogin from 'react-google-login';

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {Redirect} from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import theme from "../../config/theme";

// Actions
import * as sessionActions from "../../components/actions/sessionActions";
import * as StorageService from 'utils/storage.service';

// Layout
import LoginLayout from "../../layouts/LoginLayout/LoginLayout";

// Components
import { Form, Alert } from "react-bootstrap";
import Link from "../../components/Link/Link";
import { Input, Button } from "../../components/Bootstrap";
import { Paragraph } from "../../components/Typography";
import {
  FormContainer,
  LoginButtonContainer,
  Title,
  FormGroupContainer,
  MainContent,
} from "../../layouts/LoginLayout/LoginLayout.styles";

//google tag manager
import TagManager from "react-gtm-module";

const Login = (props) => {

  let referrer;
  if(props.location.state !== undefined) {
    referrer = props.location.state.referrer;
  } else {
    referrer = "/";
  }

  /**
   * Hooks
   */
  const isDesktop = useMediaQuery({
    query: `(min-device-width: ${theme.breakpoints.md}px)`,
  });

  /**
   * Local state
   */
  const [user, setUser] = useState({
    email: "",
    password: "",
    response: "",
    showAlert: false,
  });
  const [response, setResponse] = useState("");
  const [showAlert, setShowAlert] = useState("");

  const success = null;

  // prevent Multiple Submissions
  const [isLoading, setIsLoading] = useState(false);

  // Inputs States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { register, errors, handleSubmit } = useForm();

  const onSubmitHandler = async (data, e) => {
    setIsLoading(true);
    e.preventDefault();

    await props.actions.logInUser(user);
  };

  useEffect(() => {
    user.email = email;
  }, [email]);

  useEffect(() => {
    user.password = password;
  }, [password]);

  // Show errors on submit depending of backend response
  const handleShowError = (errorString) => {
    setResponse(humanErrorMessage(errorString));
    setShowAlert(true);
    setIsLoading(false);
    setTimeout(() => {
      setShowAlert(false);
      setResponse("");
    }, 5000);

    if (process.env.REACT_APP_GTM && process.env.REACT_APP_GTM != '') {
      const tagManagerArgs = {
        dataLayer: {
          event: 'login',
          login: 0
        },
      }
      TagManager.dataLayer(tagManagerArgs);
    }
  };

  // Backend Response
  useEffect(() => {
    if (props.response!=="" && props.response!==undefined) {
      handleShowError(props.response);
    }
  }, [props.response, props.loginFailCount]);

  const { loggedOut } = props;
  var activated;
  if (success) {
    activated = <div>Your Account has been Activated</div>;
  } else {
    activated = "";
  }

  if (loggedOut === false) {
    if (process.env.REACT_APP_GTM && process.env.REACT_APP_GTM != '') {
      const tagManagerArgs = {
        dataLayer: {
          event: 'login',
          login: 1
        },
      }
      TagManager.dataLayer(tagManagerArgs);
    }

    const next = StorageService.get('next');
    if (next) {
      return <Redirect to={next}></Redirect>;
    } else if(referrer === 'signup') {
      return (
        <Redirect
          to={{
            pathname: "/profile/cart/products",
            state: {referrer: 'signup'}
          }}
        ></Redirect>
      );
    } else {
      return <Redirect to="/Home"></Redirect>;
    }
  }

  return (
    <LoginLayout>
      <MainContent>
        <FormContainer>
          <Title>Sign In</Title>
          <Paragraph className="text-center">
            Sign in to your existing Presentii account.
          </Paragraph>
          { StorageService.get('next') ? (
            <Paragraph className="text-center">
              <em>You will be redirected to the checkout page.</em>
            </Paragraph>
          ) : '' }
          {showAlert && <Alert variant="danger">{response}</Alert>}
          {referrer === 'signup' && <Alert variant="success">Sign up successful.</Alert>}
          <FormGroupContainer>
            <Form onSubmit={handleSubmit(onSubmitHandler)}>
              {activated}
              <Form.Group>
                <Input
                  name="email"
                  ref={register({
                    required: true,
                    pattern: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                  })}
                  placeholder="Email Address"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email?.type === "required" && (
                  <small className="text-danger">Email is required</small>
                )}
                {errors.email?.type === "pattern" && (
                  <small className="text-danger">
                    Please provide a valid email
                  </small>
                )}
              </Form.Group>
              <Form.Group>
                <Input
                  name="password"
                  ref={register({
                    required: true,
                  })}
                  defaultValue={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder="Password"
                />
                {errors.password?.type === "required" && (
                  <small className="text-danger">Password is required</small>
                )}
                {errors.password?.type === "pattern" && (
                  <small className="text-danger">
                    Password must have at least 1 Uppercase letter, 1 Lowercase
                    letter, 1 digit, and{" "}
                  </small>
                )}
              </Form.Group>
              <Form.Group className="pt-4">
                <LoginButtonContainer>
                  <Button
                    className="btn btn-md btn-primary mx-auto mx-md-0 d-block d-md-inline"
                    type="submit"
                    id="submit"
                    size="lg"
                    color="primary"
                    disabled={isLoading}
                  >
                    Login
                  </Button>
                </LoginButtonContainer>
              </Form.Group>
              <div>
                <div className="pt-5 mb-2">
                  <Link href="/signup">Need an Account? Sign Up.</Link>
                </div>
                <div className="links mb-2 pt-1">
                  <Link href="/recover">Forgot your Password?</Link>
                </div>
                <div className="links mb-2 pt-1">
                  <Link href={process.env.REACT_APP_PUBLIC}>
                    Attending an Event?
                  </Link>
                </div>
                {!isDesktop && (
                  <div className="links pt-1">
                    <Link href="/signup">Sign Up</Link>
                  </div>
                )}
              </div>
            </Form>
          </FormGroupContainer>
        </FormContainer>
      </MainContent>
    </LoginLayout>
  );
};

function mapStateToProps(state) {
  return {
    logged_in: state.session,
    loggedOut: state.loggedOut,
    companyID: state.companyID,
    response: state.response,
    loginFailCount: state.loginFailCount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

/**
 * Takes an error string from the server, and makes it more human.
 *
 * @returns {string}
 */
function humanErrorMessage(input) {

  switch(input) {
    case "user not found":
      return "The email you entered did not match our records. Please double-check and try again.";
    case "wrong password":
      return "The password you entered did not match. Please double-check and try again.";
    case "account not activated":
      return "Check Your email! You must verify your account before you are able to login.";
    case "Deactivated account" :
      return "The user is no longer active. Contact your adminstrator for assistance.";
    default :
      return input;
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Login);
