import styled from "styled-components/macro";

export const Title = styled.h3`
  font-weight: ${({ theme }) => theme.fontWeights.montserrat.black};
  font-size: 1.4286rem;
  color: ${({ theme }) => theme.colors.grey.heavy};
  margin-bottom: ${(prop) => (prop.smallMargin ? "1.1429rem" : "2.8571rem")};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: ${(prop) => (prop.noTitleSm ? "none" : "block")};
  }
`;

export const ThemedSection = styled.section`
  margin-bottom: ${(prop) => (prop.bordered ? "2.3571rem" : "5rem")};
  /* border-bottom: ${(prop) => (prop.bordered ? "4px solid #ebebeb" : "0")}; */
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    border-bottom: ${(prop) => prop.bordered === "sm" && "4px solid #ebebeb"};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    border-bottom: ${(prop) => prop.bordered === "lg" && "4px solid #ebebeb"};
    border-bottom: ${(prop) => prop.bordered === "no-lg" && "0"};
  }
`;
