import styled from "styled-components";
import theme from "./../../config/theme";

const {
  colors: { primary, grey },
  fontWeights: { montserrat },
  forms,
} = theme;

export const FormQuestion = styled.div`
  margin-bottom: 2.4286rem;

  .form-question {
    &__item {
      color: ${primary.base};
      text-transform: uppercase;
      letter-spacing: 0.08rem;
      line-height: 1.56;
      font-weight: ${montserrat.black};
    }
    &__title {
      font-weight: ${montserrat.extraBold};
      letter-spacing: 0.08rem;
    }
    &__options {
      padding-left: 1rem;
    }
  }

  .question-text {
    border-bottom: solid 1px transparent;
    transition: border-bottom 0.5s ease-out;
    cursor: pointer;
    min-width: 200px;
    min-height: 1.3rem;
    display: inline-block;

    &:hover {
      border-bottom: solid 1px ${grey.heavy};
    }
  }

  input {
    background-color: transparent;
    border: none;
    border-bottom: solid 1px ${grey.light};
    transition: border-bottom 0.2s ease-out;
    transition: background-color 0.2s ease-out;

    &:hover {
      border-bottom: solid 1px ${grey.medium};
    }
  }
`;

export const QuestionOption = styled.div`
  position: relative;
  margin-bottom: 1rem;

  .question-option__delete-button {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    min-width: initial;
    transform: translateX(200%);
    /* width: 2.4286rem; */
    height: ${forms.inputHeight};
    min-height: ${forms.inputHeight};
    background-color: ${grey.heavy};
    border-width: 0;
  }
  .question-option__edit-button,
  .question-option__save-button {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    min-width: initial;
    transform: translateX(110%);
    /* width: 2.4286rem; */
    height: ${forms.inputHeight};
    min-height: ${forms.inputHeight};
    background-color: ${grey.heavy};
    border-width: 0;
  }
`;

export const QuestionAddOption = styled.div`
  position: relative;
  margin-bottom: 3rem;

  .question-add-option {
    &__errors {
      font-weight: ${montserrat.extraBold};
      position: absolute;
      bottom: 0;
      transform: translateY(110%);
    }

    &__add-button {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      min-width: initial;
      transform: translateX(120%);
      /* width: 2.4286rem; */
      height: ${forms.inputHeight};
      min-height: ${forms.inputHeight};
      background-color: ${grey.heavy};
      border-width: 0;
    }
  }

  .option-text {
    border-bottom: solid 1px transparent;
    transition: border-bottom 0.5s ease-out;
    cursor: pointer;
    min-width: 200px;
    min-height: 1.3rem;
    display: inline-block;

    &:hover {
      border-bottom: solid 1px ${grey.heavy};
    }
  }  
`;
