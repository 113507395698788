import React from "react";

// Components
import { Wrapper } from "./DefaultLayout.styles";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import FooterBar from "../../components/Footer/Footer";

const LoginLayout = ({ children, ...props }) => {
  return (
    <Wrapper {...props}>
      <NavigationBar />
      {children}
      <FooterBar />
    </Wrapper>
  );
};

export default LoginLayout;
