import React from "react";
import {
  ErrorWrapper,
  ErrorNumber,
  ErrorDescription,
  ErrorInstructions,
} from "./ErrorMessage.styles";

const ErrorMessage = ({ number, description, instructions }) => {
  return (
    <React.Fragment>
      <ErrorWrapper>
        <ErrorNumber>{number}</ErrorNumber>
        <ErrorDescription>{description}</ErrorDescription>
        <ErrorInstructions>{instructions}</ErrorInstructions>
      </ErrorWrapper>
    </React.Fragment>
  );
};

export default ErrorMessage;
