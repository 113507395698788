import * as React from "react";
import styled from "styled-components";
import { Accordion } from "react-bootstrap";
import theme from "./../../../config/theme";

const {
  colors: { primary, alt, grey },
  fontWeights: { montserrat },
} = theme;

const Wrapper = styled(Accordion)`
  .event-collapse {
    &__header {
      padding: 29px 31px 27px;
      position: relative;
      cursor: pointer;

      &.active {
        &:after {
          transform: rotate(-180deg);
        }
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 31px;
        top: 50%;
        transform: translateY(-50%);
        transform-origin: top;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 9px solid white;
        transition: transform 250ms ease-out;
      }
    }
    &__details-item {
      &.price {
        strong {
          color: ${primary.base};
        }
      }
    }
  }
  .card {
    margin-bottom: 17px;
    border-radius: 0;
    border: 0;
  }
  .card-header {
    border-radius: 0;
    background-color: ${({ variant }) =>
      variant === "alt" ? alt.base : grey.heavy};
    color: ${alt.text};
    font-weight: ${montserrat.extraBold};
    padding: 0;
  }
  .card-body {
    background-color: ${grey.base};
    padding: 23px 31px 27px;
  }
`;

const EventsCollapse = React.forwardRef((props, ref) => {
  return <Wrapper {...props}>{props.children}</Wrapper>;
});

export default EventsCollapse;
