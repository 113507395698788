import * as React from "react";
import styled from "styled-components/macro";
import { Form } from "react-bootstrap";

const Wrapper = styled(Form.Control)`
  border-radius: ${({ theme }) => theme.borderRadius.input};
  border-width: 1px;
  border-color: ${({ theme }) => theme.forms.borderColor};
  color: ${({ theme }) => theme.forms.textColor};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 500;
  font-size: 0.9186rem;
  letter-spacing: 1px;
  resize: none;
  width: 100%;
  padding: 0.9286rem 1.3571rem;
  background-clip: border-box;

  ${({ variant, theme }) =>
    variant === "alt" && `background-color: ${theme.colors.grey.base};`}

  &:focus {
    ${({ variant, theme }) =>
      variant === "alt" && `background-color: ${theme.colors.grey.base};`}

    color: ${({ theme }) => theme.forms.textColor};
    border-color: ${({ theme }) => theme.forms.focusBorderColor};
    box-shadow: ${({ theme }) => theme.forms.boxShadow};
  }

  ::-webkit-autofill,
  ::-webkit-autofill:hover,
  ::-webkit-autofill:focus {
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }

  ::placeholder {
    color: ${({ theme }) => theme.forms.placeholderColor};
  }

  &.is-invalid {
    background-image: none;
    padding-right: 0.75rem;

    &:focus {
    }
  }

  &:disabled,
  &[readonly] {
    background-color: #f2f2f2;
    border-color: #d6d6d6;
    color: grey;

    &:focus {
      box-shadow: none;
    }
  }
`;

const TextArea = React.forwardRef((props, ref) => {
  return <Wrapper className="form-control" as="textarea" {...props} />;
});

export default TextArea;
