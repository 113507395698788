import {
  LOG_IN_SUCCESS_COMPANY,
  LOG_IN_SUCCESS_USER,
  SET_PRICING,
  SET_SUPERUSER,
} from "../components/actions/actionTypes";

/*
 * Simple middleware to authenticate if we're a superuser, then dispatch
 * an appropriate action into the stack.
 *
 * Logout reset state is covered in the reducer.
 */
const superUserMiddleware = (store) => {
  /* "Fire and forget" function try authenticating with current session cookie */
  function authenticateSuperUserStatus() {
    const checkAdminRequest = new Request("/api/v1/auth", {
      method: "GET",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });

    fetch(checkAdminRequest)
      .then((resp) => resp.json())
      .then(({ data }) => {
        store.dispatch({
          type: SET_SUPERUSER,
          payload: data ? data.isSuperUser : false,
        });

        if (data) {
          store.dispatch({ type: SET_PRICING, payload: data.pricing });
        }
      });
  }

  authenticateSuperUserStatus();

  return (next) => (action) => {
    switch (action.type) {
      case LOG_IN_SUCCESS_USER:
      case LOG_IN_SUCCESS_COMPANY:
        authenticateSuperUserStatus();
        break;

      default:
        break;
    }
    return next(action);
  };
};

export default superUserMiddleware;
