import React from "react";

import {
  Redirect,
} from "react-router-dom";

import * as CartService from 'utils/cart.service';

export function CartContainer(props) {

  const product = CartService.getProduct();
  const price = CartService.getPrice();

  if (props.profile.company.subscription
    && props.profile.company.subscription.status === 'active'
  ) {
    return <Redirect to="/profile/my-subscription" />;
  } else if (product && price) {
    return <Redirect to="/profile/cart/payment" />;
  } else if (product) {
    return <Redirect to="/profile/cart/prices" />;
  } else {
    return <Redirect to="/profile/cart/products" />;
  }
}
