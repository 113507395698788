import React from "react";
import { Elements } from "react-stripe-elements";
import Form from "./Form";

const CardSection = ({ close, role, handler }) => (
  <Elements>
    <Form close={close} role={role} handler={handler} />
  </Elements>
);

export default CardSection;
