import React from "react";
import { Button } from "../../Bootstrap";
import { Table } from "react-bootstrap";
import { TableWrapper } from "../Home.styles";
import Section from "../../Section";

var moment = require("moment-timezone");
var dateFormat = require("dateformat");

var myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

const resetArchive = (e) => {
  const eventID = e.target.value;
  let request = new Request(myServer + "/api/Events", {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      event: eventID,
    }),
  });
  fetch(request).then(function (response) {
    response.json().then((res) => {
      if (res.message === "success") {
        setTimeout(() => {
          window.location.reload();
        }, 300);
      }
    });
  });  
}

const ArchiveTable = (props) => {
  return (
    <React.Fragment>
      <Section sectionTitle="Archived Events">
        <TableWrapper className="d-none d-lg-block">
          <Table striped responsive hover>
            <thead className="thead-dark">
              <tr>
                <th>Event Name</th>
                <th>Event Key</th>
                <th>Presenter</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Restore</th>
              </tr>
            </thead>
            <tbody>
              {props.events.map((event, index) => (
                <tr key={event.eventID}>
                  <td>{event.Title}</td>
                  <td class="text-uppercase">{event.entranceKey}</td>
                  <td>{event.presenterName}</td>
                  <td>
                    {event.startTime
                      ? moment
                          .tz(event.startTime, event.TimeZone)
                          .format("MMM ddd, YYYY h:m:ss a ")
                      : ""}
                  </td>
                  <td>
                    {event.endTime
                      ? dateFormat(event.endTime, "mmmm d, yyyy h:MM:ss TT")
                      : ""}
                  </td>
                  <td>
                    <Button
                      color="secondary"
                      name="finished"
                      value={event.eventID}
                      onClick={resetArchive}
                    >
                      restore
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      </Section>
    </React.Fragment>
  );
};

export default ArchiveTable;
