const host = process.env.REACT_APP_SERVER;

import * as StorageService from 'utils/storage.service';

export function fetchProfile() {
  const request = new Request(`${host}/api/v2/profile`, {
    method: 'GET',
    credentials: 'include',
    headers: new Headers({'Content-Type': 'application/json'})
  });


  return fetch(request)
    .then(response => response.json())
    .then(response => StorageService.add('user', response));
}