import React, {useState} from "react";
import {Nav} from "react-bootstrap";
import {Container} from "../../Bootstrap";
import {
  BrowserRouter as Router, Route,
  Switch, Link, NavLink,
  useParams, useRouteMatch
} from "react-router-dom";
import DefaultLayout from "layouts/DefaultLayout/DefaultLayout";
import { ProtectedRoute } from "App";
import { PersonalDetails } from "./personal-details";
import { ChangePassword } from './change-password';
import SubscriptionDetails from './subscription-details';
import SubscriptionCancel from './subscription-cancel';
import {BillingDetails} from './billing-details';
import {CartContainer} from 'components/Cart/cart-container';
import { CartProducts } from 'components/Cart/cart-products';
import CartPrices from 'components/Cart/cart-prices';
import { CartPayment } from 'components/Cart/cart-payment';
import {CartFinished} from 'components/Cart/cart-finished';
import * as UserService from 'utils/user.service';
import * as StorageService from 'utils/storage.service';
import 'sass/style.scss';
import './product-cards.css';

export function ProfileContainer(props) {
  const [pending, setPending] = useState(true);
  const [profile, setProfile] = useState();
  
  if (!profile) {
    UserService.fetchProfile()
      .then(profile => {
        setProfile(profile);
        StorageService.add('user', profile);
      })
      .then(() => setPending(false))
      .catch(() => {
        setPending(false);
        StorageService.remove('user');
      });
  }

  if (pending) {
    return <p><small><em>Please wait...</em></small></p>
  }

  if (!pending && !profile) {
    return <p className="lead">Something has gone wrong.</p>
  }

  return (
    <DefaultLayout>
      <Container>
        <ProfileNavigation profile={profile} />
        <Switch>
          <ProtectedRoute exact path="/profile/personal-details">
            <PersonalDetails profile={profile} />
          </ProtectedRoute>
          {profile.role === 2
            ? <React.Fragment>
              <ProtectedRoute path="/profile/cart" exact>
                <CartContainer profile={profile} />
              </ProtectedRoute>
              <ProtectedRoute path="/profile/cart/products">
                <CartProducts profile={profile} />
              </ProtectedRoute>
              <ProtectedRoute path="/profile/cart/prices">
                <CartPrices profile={profile} />
              </ProtectedRoute>
              <ProtectedRoute path="/profile/cart/payment">
                <CartPayment profile={profile} />
              </ProtectedRoute>
              <ProtectedRoute path="/profile/cart/finished">
                <CartFinished profile={profile} />
              </ProtectedRoute>
              <ProtectedRoute exact path="/profile/my-subscription">
                <SubscriptionDetails profile={profile} />
              </ProtectedRoute>
              <ProtectedRoute exact path="/profile/my-subscription/cancel">
                <SubscriptionCancel profile={profile} />
              </ProtectedRoute>
              <ProtectedRoute exact path="/profile/billing-details">
                <BillingDetails profile={profile} />
              </ProtectedRoute>
              <ProtectedRoute exact path="/profile/password">
                <ChangePassword profile={profile} />
              </ProtectedRoute>
            </React.Fragment> : <React.Fragment>
              <ProtectedRoute exact path="/profile/password">
                <ChangePassword profile={profile} />
              </ProtectedRoute>
            </React.Fragment>
          }
        </Switch>
      </Container>
    </DefaultLayout>
  )
}

// Tab set for the profile section
function ProfileNavigation(props) {
  return <div className="mb-3">
    <Nav variant="tabs" defaultActiveKey="/home">
      <Nav.Item>
        <NavLink
          to="/profile/personal-details"
          className="nav-link"
          activeClassName="active"
        >
          Personal Details
        </NavLink>
      </Nav.Item>
      { props.profile.role === 2 ? (
        <>
          {(props.profile.company.subscription && props.profile.company.subscription.status === 'active')
            ? <Nav.Item>
              <NavLink
                to="/profile/my-subscription"
                  className="nav-link"
                activeClassName="active"
              >
                  My Subscription
                </NavLink>
              </Nav.Item>
            : <Nav.Item>
              <NavLink
                to="/profile/cart"
                  className="nav-link"
                activeClassName="active"
              >
                  Plans
                </NavLink>
              </Nav.Item>
          }
          <Nav.Item>
            <NavLink
              to="/profile/billing-details"
              className="nav-link"
              activeClassName="active"
            >
              Billing Details
            </NavLink>
          </Nav.Item>
        </>
      ) : '' }
      <Nav.Item>
        <NavLink
          to="/profile/password"
          className="nav-link"
          activeClassName="active"
        >
          Change Password
        </NavLink>
      </Nav.Item>
    </Nav>
  </div>
}
