import styled, { css } from "styled-components/macro";
import { Form } from "react-bootstrap";

export const baseStyle = css`
  padding-right: 1.7857rem;
  padding-left: 0;

  .custom-control-label {
    &::before {
      top: 0;
      right: -1.7857rem;
      left: initial;
      width: 1.4286rem;
      height: 1.4286rem;
      background-color: ${({ theme }) => theme.colors.grey.base};
      border-color: ${({ theme }) => theme.colors.grey.base};
    }
    &::after {
      right: -1.7857rem;
      left: initial;
      top: 0;
      width: 1.4286rem;
      height: 1.4286rem;
    }
  }
  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      background-color: ${({ theme }) => theme.colors.grey.base};
      border-color: ${({ theme }) => theme.colors.grey.base};
    }
    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23ef6d53' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
  }
`;

export default styled(Form.Check)`
  ${baseStyle}
`;
