const host = process.env.REACT_APP_SERVER;

export async function searchProducts() 
{
  const request = new Request(`${host}/api/v2/products`, {
    method: 'GET',
    headers: new Headers({ "Content-Type": "application/json" }),
  });

  return fetch(request).then(response => response.json())
}

export async function findProduct ( prod_id )
{
  const request = new Request(`${host}/api/v2/products/${prod_id}`, {
    method: 'GET',
    headers: new Headers({ "Content-Type": "application/json" }),
  });

  return fetch(request).then(response => response.json())
}