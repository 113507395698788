import React from 'react';

import { Row, Col } from 'react-bootstrap';

import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import * as BillingService from 'utils/billing.service';

import { CreditCardForm } from 'components/Cart/credit-card-form';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);


export class BillingDetails extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      methods: [],
      pending: true
    };
  }

  async componentDidMount() {
    const methods = await BillingService.listPaymentMethods();

    this.setState({ 
      methods: methods.data,
      pending: false
    });
  }

  removeCard(card) {
    const methods = this.state.methods.filter(m => m.id !== card.id);
    this.setState({ methods: methods });
  }

  hasPaymentMethods() {
    return (
      <div className="form-group">
        {this.state.methods.map(i => {
          return (
            <PaymentMethod 
              method={i} 
              pending={this.state.pending} 
              onRemove={(card) => this.removeCard(i)}>
            </PaymentMethod>
          )
        })}
      </div>
    );
  }

  noPaymentMethods() {
    return <React.Fragment>
      <p className="lead">You currently have no active payment methods.</p>
    </React.Fragment>
  }

  async attachPaymentMethod(card) {
    this.setState({ pending: true });
    
    // attach this card to the active user
    await BillingService.createPaymentMethod(card.id);
    
    const methods = this.state.methods;
    methods.push(card);

    this.setState({ 
      pending: false, 
      methods: methods
    });
  }

  render() {
    return <React.Fragment>
      <Row className="my-3">
        <Col md={4}>
          <h3>Add Payment Method</h3>
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
            {(elements, stripe) => (
              <CreditCardForm 
                elements={elements}
                stripe={stripe}
                onCardCollected={card => this.attachPaymentMethod(card)}
                btnLabel="Save"
                pending={this.state.pending}>
              </CreditCardForm>
            )}
            </ElementsConsumer>
          </Elements>
        </Col>
        <Col md={4}>
          <h3>My Payment Methods</h3>
          {
            this.state.methods.length
              ? this.hasPaymentMethods()
              : this.noPaymentMethods()
          }
        </Col>
      </Row>

    </React.Fragment>
  }
}

class PaymentMethod extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pending: false
    }
  }

  async removeCard(card) {
    this.setState({ pending: true });

    try {
      await BillingService.removePaymentMethod(card.id);
    } catch (err) {
      console.log(`woops`, err)
    }

    this.setState({ pending: false });

    this.props.onRemove(card);
  }

  render() {
    const i = this.props.method;

    return (
      <div className="d-flex align-items-center">

        <span className="lead">
          { i.card.brand } ending with { i.card.last4 }.
        </span>

        <button
          className="mx-1 btn badge badge-danger"
          disabled={this.state.pending}
          onClick={event => this.removeCard(i)}>
          Remove
        </button>
      
      </div>
    );
  }
}