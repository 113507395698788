import React, { useState, useEffect, useRef } from "react";
import * as sessionActions from "../actions/sessionActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import CardSection from "../Home/components/CardSection";
import PaymentTab from "./paymentTab";

// Assets
import LogoPlaceholder from "./../../assets/img/logo_placeholder.svg";
import "./Account.css";

//Layout
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

// Thirdparty
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Form, Row, Col, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Container, Input, Button } from "../Bootstrap";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
// Modal.setAppElement(document.getElementById("root"));

let myServer = "";

if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

let centerText = {
  textAlign: "center",
};

let fd;

const Profile = (props) => {
  // States
  const [id, setId] = useState();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [oldName, setOldName] = useState("");
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [logo, setLogo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [industryName, setIndustryName] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [displayLogo, setDisplayLogo] = useState("");
  const [addCard, setAddCard] = useState(false);
  const [cards, setCards] = useState([]);
  const [addClose, setAddClose] = useState(true);
  const [isThereACard, setIsThereACard] = useState(true);

  // Use React Hook Form
  const { register, errors, handleSubmit, watch } = useForm();

  // Use reference to get the current value of Password field
  const passwordField = useRef({});
  passwordField.current = watch("password", "");

  const request = new Request(`${myServer}/api/v1/Profile`, {
    method: "GET",
    credentials: "include",
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  const getCards = new Request(`${myServer}/api/v1/payment`, {
    method: "GET",
    credentials: "include",
    headers: new Headers({ "Content-Type": "application/json" }),
  });

  const closeWindow = () => {
    setAddCard(false);

    window.location.reload();
  };
  const openWindow = () => {
    setAddCard(true);
  };

  useEffect(() => {
    fetch(request)
      .then(function (response) {
        response.json().then((res) => {
          var picture;

          if (Object.is(res.data.Logo, "image_placeholder.png")) {
            picture = `${myServer}/Uploads/image_placeholder.svg`;
          } else {
            picture = `${myServer}/Uploads/Users/${res.data.companyID}/images/${res.data.Logo}`;
          }

          setEmail(res.data.companyEmail);
          setName(res.data.name);
          setTimeZone("America/New_York");
          setCompanyName(res.data.companyName);
          setIndustryName(res.data.industryName);
          setDisplayLogo(picture);
          setId(res.data.stripeNumber);
          setOldName(res.data.name);
        });
      })
      .catch((error) => {
        return error;
      });
    fetch(getCards).then(function (response) {
      response
        .json()
        .then((array) => {
          setCards(array.cards);
        })
        .catch((error) => {
          return error;
        });
    });
  }, []);

  const onSubmit = (data, e) => {
    e.preventDefault();

    var user = {
      email: email,
      TimeZone: "America/New_York",
      Logo: logo,
      companyName: companyName,
      fullName: name,
      industryName: industryName
    };

    if (fd === undefined) {
      fd = null;
    }
    props.actions.updateUser(user, fd).then((s) => {
      setRedirect(true);
    });
  };

  const onSubmitPassword = (data, e) => {
    e.preventDefault();
    if (oldPassword === password) {
      console.log('password is the same, left unchanged');
      return false;
    }

    var user = {
      oldPassword: oldPassword,
      newPassword: password,
    };

    props.actions.updatePassword(user, null).then((s) => {
      setRedirect(true);
    });
  };
  const fileSelectedHandler = (e) => {
    fd = new FormData();
    fd.append("Logo", e.target.files[0]);
    setLogo(e.target.files[0].name);
    setDisplayLogo(URL.createObjectURL(e.target.files[0]));
  };
  const newCard = () => {
    setAddCard(true);
  };

  if (redirect === true) {
    return <Redirect to="/Home"></Redirect>;
  }

  if (addCard === true) {
    var popUp = (
      <Modal
        centered
        size="md"
        show={addCard}
        style={customStyles}
        onHide={closeWindow}
        className="addPaymentModal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal__title text-uppercase text-center font-weight-bold">
            Add a Credit Card
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-4">
          <CardSection handler={closeWindow}></CardSection>
        </Modal.Body>
      </Modal>
    );
  } else {
    popUp = "";
  }

  return (
    <DefaultLayout>
      <Container className="pb-5">
        <React.Fragment>
          <Tabs className="mt-5 pt-lg-5">
            <TabList>
              <Tab>Profile</Tab>
              <Tab>Password</Tab>
              {/* <Tab>Payments</Tab> */}
            </TabList>

            <TabPanel className="mt-4">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md={8} lg={6}>
                    <Form.Group>
                      <Form.Label>Your Name</Form.Label>
                      <Input
                        name="name"
                        type="text"
                        variant={"alt"}
                        autoComplete="username"
                        ref={register({
                          required: "Name field is required",
                        })}
                        placeholder="Name"
                        defaultValue={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {errors.name?.type === "required" && (
                        <small className="text-danger">Name is required</small>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Email Address</Form.Label>
                      <Input
                        name="email"
                        variant={"alt"}
                        ref={register({
                          required: true,
                          pattern: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                        })}
                        placeholder="Email"
                        defaultValue={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors.email?.type === "required" && (
                        <small className="text-danger">Email is required</small>
                      )}
                      {errors.email?.type === "pattern" && (
                        <small className="text-danger">
                          Please provide a valid email
                        </small>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Company Name</Form.Label>
                      <Input
                        name="companyName"
                        type="text"
                        variant={"alt"}
                        placeholder="Company"
                        defaultValue={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Industry</Form.Label>
                      <Input
                        name="industryName"
                        type="text"
                        variant={"alt"}
                        placeholder="Industry"
                        defaultValue={industryName}
                        onChange={(e) => setIndustryName(e.target.value)}
                      />
                    </Form.Group>

                    <Button type="submit" id="submit" size="med">
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
            </TabPanel>

            <TabPanel className="mt-4">
            <Form onSubmit={handleSubmit(onSubmitPassword)}>
                <Row>
                  <Col md={8} lg={6}>
                    <Form.Group>
                      <Form.Label>Old Password</Form.Label>
                      <Input
                        name="old_password"
                        defaultValue=""
                        variant={"alt"}
                        type="password"
                        onChange={(e) => setOldPassword(e.target.value)}
                        placeholder="Current Password"
                      />
                      {errors.confirm_password && (
                        <small className="text-danger">
                          {errors.confirm_password.message}
                        </small>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Input
                        name="password"
                        autoComplete="new-password"
                        defaultValue={password}
                        variant={"alt"}
                        ref={register({
                          required: "You must specify a password",
                          minLength: {
                            value: 8,
                            message: "Password must have at least 8 characters",
                          },
                          maxLength: {
                            value: 32,
                            message: "Password maximum length is 32 characters",
                          },
                          validate: {
                            lowerCase: (value) => /[a-z]/.test(value),
                            upperCase: (value) => /[A-Z]/.test(value),
                            digit: (value) => /[0-9]/.test(value),
                          },
                        })}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Password"
                      />
                      {errors.password && errors.password.type === "digit" && (
                        <small className="text-danger">
                          Password must have at least 1 number
                        </small>
                      )}
                      {errors.password &&
                        errors.password.type === "lowerCase" && (
                          <small className="text-danger">
                            Password must have at least 1 lowercase letter
                          </small>
                        )}
                      {errors.password &&
                        errors.password.type === "upperCase" && (
                          <small className="text-danger">
                            Password must have at least 1 uppercase letter
                          </small>
                        )}
                      {errors.password && (
                        <small className="text-danger">
                          {errors.password.message}
                        </small>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Confirm Password</Form.Label>
                      <Input
                        name="confirm_password"
                        autoComplete="new-password"
                        defaultValue={confirmPassword}
                        variant={"alt"}
                        ref={register({
                          validate: (value) =>
                            value === passwordField.current ||
                            "The passwords do not match",
                        })}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type="password"
                        placeholder="Confirm Password"
                      />
                      {errors.confirm_password && (
                        <small className="text-danger">
                          {errors.confirm_password.message}
                        </small>
                      )}
                    </Form.Group>

                    <Button type="submit" id="submit" size="med">
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
            </TabPanel>
            {popUp}

            {/* <TabPanel>
              <PaymentTab addCard={openWindow}></PaymentTab>
            </TabPanel> */}
          </Tabs>
        </React.Fragment>
      </Container>
    </DefaultLayout>
  );
};

function mapStateToProps(state) {
  return {
    isAdmin: state.isAdmin,
    logged_in: state.session,
    loggedOut: state.loggedOut,
    companyID: state.companyID,
    userZone: state.userZone,
    creditScore: state.creditScore,
    eventID: state.eventID,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
