import styled from "styled-components";
import { Modal } from "react-bootstrap";

const AlertModal = styled(Modal)`
  .modal-session {
    max-width: 1440px;

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
      min-height: 100%;
      margin: 0;
    }
  }

  .modal-content {
    border-radius: 0;
    border: 0;

    &:before {
      display: block;
      content: "";
      position: absolute;
      background: ${({ variant, theme }) =>
        variant === "alt" ? theme.colors.alt.base : theme.colors.primary.base};
      width: 100%;
      height: 100%;
      top: 17px;
      left: 17px;
      z-index: -1;
    }
  }

  .modal-header {
    border-bottom: 0;

    .close {
      opacity: 1;
      padding: 1rem 1.7143rem;
      margin: 0 0 0 auto;

      span {
        font-size: 52px;
      }
    }
  }

  .modal-body {
    padding: 30px 30px 15px;
  }

  .modal-footer {
    padding: 15px 30px 30px;
    border-top: 0;
    justify-content: center;
  }

  .alert-modal {
    &__title {
      text-align: center;
      color: ${({ variant, theme }) =>
        variant === "alt" ? theme.colors.alt.base : theme.colors.primary.base};
    }
  }

  .session-content {
    padding: 0 7.1429rem 9.8571rem;
    @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
      padding: 0 2rem 5rem;
    }

    &__event-title {
      font-size: 2.175rem;
      text-transform: uppercase;
      font-weight: ${({ theme }) => theme.fontWeights.montserrat.black};
      letter-spacing: 1.12px;
      line-height: 0.87;
    }

    &__event-status {
      font-size: 2.175rem;
      text-transform: uppercase;
      font-weight: ${({ theme }) => theme.fontWeights.montserrat.black};
      color: #797979;
      letter-spacing: 1.12px;
      line-height: 0.87;
      margin-bottom: 3.3571rem;
    }

    &__event-steps {
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: ${({ theme }) => theme.fontWeights.montserrat.black};
      color: ${({ theme }) => theme.colors.grey.medium};
      letter-spacing: 1.12px;
      line-height: 1.5;
      margin-bottom: 3.3571rem;
      padding-bottom; 1rem;
      border-bottom: solid 1px #ebebeb;
    }

    &__event-step-heading {
      font-size: 1.875rem;
      text-transform: uppercase;
      font-weight: ${({ theme }) => theme.fontWeights.montserrat.black};
      color: ${({ theme }) => theme.colors.primary.base};
    }

    &__event-control {
      min-width: 20.2857rem;
      margin-bottom: 1rem;

      @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
        margin-bottom: 5.7857rem;
      }
    }

    &__event-description {
      font-size: 1.1429rem;
      letter-spacing: 0.7px;
      margin-top: 0.75em;
      margin-bottom: 0.5em;
    }

    &__icon-session {
      max-width: 80px;
    }

    &__icon-microphone {
      background-color: ${({ variant, theme }) =>
        variant === "alt" ? theme.colors.alt.base : theme.colors.primary.base};
      border-radius: 50%;
      padding: 0.65em;
      max-width: 50px;
    }

    &__event-share {
      font-size: 1.1429rem;
      letter-spacing: 0.7px;
    }

    &__event-link {
      font-size: 1.7857rem;
      color: #535456;
      letter-spacing: 0.7px;
    }

    &__event-key {
      color: ${({ theme }) => theme.colors.alt.base};
      font-size: 1.5rem;
      text-transform: uppercase;
      letter-spacing: 0.7px;
      font-weight: ${({ theme }) => theme.fontWeights.montserrat.black};
      @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
        margin-top: 0;
      }
    }

    &__event-details {
      @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
        text-align: center;
        max-width: 80%;
        margin: 0 auto;
        background-color: ${({ theme }) => theme.colors.grey.light};
        padding: 2.9286rem 2.3571rem;
        margin-top: 5.4286rem;
      }
    }

    &__event-attendees {
      font-size: 1.5rem;
      font-weight: ${({ theme }) => theme.fontWeights.montserrat.black};
      color: ${({ theme }) => theme.colors.grey.medium};
    }

    &__event-attending {
      color: ${({ theme }) => theme.colors.primary.base};
    }

    &__attendees-title {
      font-size: 1.2893rem;
      font-weight: ${({ theme }) => theme.fontWeights.montserrat.black};
      text-transform: uppercase;
      margin-bottom: 2.7143rem;
      @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
        margin-bottom: 0;
      }
    }
    &__attendees-number {
      color: ${({ theme }) => theme.colors.primary.base};
    }
  }
`;

export default AlertModal;
