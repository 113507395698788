import React from "react";
import { Row, Col } from "react-bootstrap";

export class AddressForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      line1: '',
      line2: '',
      city: '',
      state: '',
      postal_code: '',
      country: 'CA',
    }
  }

  onChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({ [name]: value });

    this.props.onChange(event);
  }

  render() {
    return (
      <Row>
        <Col md={12} className="form-group">
          <label htmlFor="name" 
            className="form-label sr-only">
            Your Name (as it appears on your card)
          </label>
          <input type="text" className="form-control" 
            placeholder="Your Name (as it appears on your card)"
            value={this.state.name}
            onChange={ event => this.onChange(event) }
            name="name" id="name" 
            required />
        </Col>

        <Col md={9} className="form-group">
          <label htmlFor="line1" 
            className="form-label sr-only">
            Street
          </label>
          <input type="text" className="form-control" 
            placeholder="Street"
            value={this.state.line1}
            onChange={ event => this.onChange(event) }
            name="line1" id="line1" 
            required />
        </Col>

        <Col md={3} className="form-group">
          <label htmlFor="line2" 
            className="form-label sr-only">
            Suite
          </label>
          <input type="text" className="form-control" 
            placeholder="Suite"
            value={this.state.line2}
            onChange={ event => this.onChange(event) }
            name="line2" id="line2" />
        </Col>

        <Col md={6} className="form-group">
          <label htmlFor="city" 
            className="form-label sr-only">
            City
          </label>
          <input type="text" className="form-control" 
            placeholder="City"
            value={this.state.city}
            onChange={ event => this.onChange(event) }
            name="city" id="city" 
            required />
        </Col>

        <Col md={6} className="form-group">
          <label htmlFor="state" 
            className="form-label sr-only">
            Province
          </label>
          <input type="text" className="form-control" 
            placeholder="Province"
            value={this.state.state}
            onChange={ event => this.onChange(event) }
            name="state" id="state" 
            required />
        </Col>

        <Col md={6} className="form-group">
          <label htmlFor="postal_code" 
            className="form-label sr-only">
            Postal Code
          </label>
          <input type="text" className="form-control" 
            placeholder="Postal Code"
            value={this.state.postal_code}
            onChange={ event => this.onChange(event) }
            name="postal_code" id="postal_code" 
            required />
        </Col>

        <Col md={6} className="form-group">
          <label htmlFor="country" 
            className="form-label sr-only">
            Country
          </label>
          <select type="text" className="form-control" 
            placeholder="Country"
            value={this.state.country}
            onChange={ event => this.onChange(event) }
            name="country" id="country" 
            required>
            <option value="CA">Canada</option>
            <option value="US">United States</option>
          </select>
        </Col>
      </Row>
    );
  }

}   
