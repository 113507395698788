import React, { Component, useContext } from "react";
import { connect } from "react-redux";
import "./Home.css";
import { bindActionCreators } from "redux";
import * as sessionActions from "../actions/sessionActions";
import { Redirect } from "react-router-dom";
import ReactMediaRecorder from "react-media-recorder";

//Assets
import SessionIcon from "./../../assets/img/session_icon.svg";
import MicrophoneIcon from "./../../assets/img/microphone_icon.svg";

// Layout
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

// Components
import {
  Accordion,
  Card,
  Row,
  Col,
  Modal,
  Table,
  Spinner,
  useAccordionToggle,
  AccordionContext,
} from "react-bootstrap";
import AlertModal from "./../Modals/AlertModal";
import { Button, Container } from "../Bootstrap";
import { TableWrapper } from "./Home.styles";
import ArchiveTable from "./components/ArchiveTable";
import PreStartModal from "./../Event/PreStartModal";
import PreArchiveModal from "./../Event/PreArchiveModal";
import PreFinishModal from "./../Event/PreFinishModal";
import Section from "./../Section";
import Link from "./../Link/Link";
import EventsCollapse from "./components/EventsCollapse";
import FilterByUser from "./components/FilterByUser";

var moment = require("moment-timezone");

var myServer = "";

if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

var pollingInterval;

var users = [
  {
    user: "",
    events: [
      {
        eventID: "",
        entranceKey: "",
        startTime: null,
        endTime: null,
        numOfAttendees: "",
        modify: "",
        name: "",
        archived: "",
      },
    ],
  },
];
var AllEvents = [];
var fileDataLocal;

function ActiveToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const decorativeOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      className={`event-collapse__header ${isCurrentEventKey && "active"}`}
      onClick={decorativeOnClick}
    >
      {children}
    </div>
  );
}

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      redirectToModify: false,
      redirectToView: false,
      usersArray: [],
      userEventsArray: [],
      uploading: false,
      uploadProgress: 0,
      isRecording: 0, // Prevent closing modal on session
      showDangerModal: false, // Prevent closing modal on session
      uploadRecord: "",
      readyToUpload: true,
      isSessionOn: false,
      paymentOption: false,
      showEndSession: false,
      showModal: true,
      upComing: [],
      ongoing: [],
      recent: [],
      finished: [],
      archived: [],
      showArchives: false,
      blobURL: "",
      isBlocked: false,
      loading: true,
      clock: new Date(),
      modify: false,
      dropMenuItem: "All Company Events",
      extendSession: false,
      modalDisplay: "block",
      estimatedAttendees: null,
      startModalShow: false,
      archiveModalShow: false,
      eventToArchive: null,
      // Prevent Multiple Submission
      isLoading: false,
    };

    // Refs
    this.finishRecording = React.createRef();
    this.uploadRecording = React.createRef();

    this.handleClick = this.handleClick.bind(this);
    this.viewEvent = this.viewEvent.bind(this);
    this.resetEvent = this.resetEvent.bind(this);
    this.handleSelectedUser = this.handleSelectedUser.bind(this);
    this.upload = this.upload.bind(this);
    this.endEvent = this.endEvent.bind(this);
    this.startEventAppAudio = this.startEventAppAudio.bind(this);
    this.stopEventAppAudio = this.stopEventAppAudio.bind(this);
    this.archiveEvent = this.archiveEvent.bind(this);
    this.extendSession = this.extendSession.bind(this);
  }

  extendSession(e) {
    let self = this;
    const request = new Request(myServer + "/api/v1/extendSession", {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        event: this.state.eventID,
      }),
    });
    fetch(request).then(function (response) {
      response.json().then((res) => {
        if (res.message === "success") {
          self.setState({
            extendSession: false,
            modalDisplay: "none",
          });
        }
      });
    });
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  archiveEvent(e) {
    var eventID = this.state.eventToArchive;
    let self = this;

    self.setState({
      isLoading: true,
    });

    const archiveEvent = new Request(myServer + "/api/v1/Events", {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        event: eventID,
      }),
    });
    fetch(archiveEvent).then(function (response) {
      response.json().then((res) => {
        if (res.message === "success") {
          var finished = self.state.finished;
          var archived = self.state.archived;
          for (let i = 0; i < finished.length; i++) {
            if (finished[i].eventID === eventID) {
              self.setState(
                {
                  ...self.state,
                },
                // eslint-disable-next-line no-loop-func
                () => {
                  archived.unshift(finished[i]);
                  finished.splice(i, 1);
                  self.setState({
                    finished: finished,
                    archived: archived,
                    archiveModalShow: false,
                    isLoading: false,
                  });
                }
              );
            }
          }
        }
      });
    });
  }

  getEventAttendeeCount() {
    var self = this;
    pollingInterval = setInterval(async function () {
      self.props.actions.updateAttendeeCount(self.state.eventID);
    }, 5000);
  }

  //Function to start event for presnetation Laptop
  startEventAppAudio(e) {
    let self = this;

    var array = self.state.upComing;
    const request = new Request(myServer + "/api/v1/startEvent", {
      method: "PUT",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ event: self.state.eventID }),
    });
    fetch(request).then(function (response) {
      response.json().then((res) => {
        for (let i = 0; i < self.state.upComing.length; i++) {
          if (self.state.eventID === self.state.upComing[i].eventID) {
            self.setState({
              upComing: array,
            });
          }
        }
      });
    });
  }

  //Function to stop event for presnetation Laptop
  stopEventAppAudio(e) {
    const request = new Request(myServer + "/api/v1/endEvent", {
      method: "PUT",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ event: this.state.eventID }),
    });
    fetch(request).then(function (response) {
      response.json().then((res) => {});
    });
  }

  //handle Start Button function
  startEvent(event) {
    let self = this;
    let target = self.state.eventToStart;
    self.setState({
      startModalShow: false,
      isLoading: true,
    });
    const eventID = event.eventID;

    //start interval to check attendee count
    this.getEventAttendeeCount();

    //check if the event is an app audio Event, if so we are just going to display the pop up else we continue with 3rd party audio
    if (this.state.eventRecord === 1) {
      this.setState({
        eventID,
        isSessionOn: true,
        shouldRecord: this.state.eventRecord,
        isLoading: false,
      });
      const request = new Request(myServer + "/api/v1/internalAudioEvent", {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          event: target,
        }),
      });
      fetch(request).then(function (response) {
        response.json().then((res) => {});
      });
    } else {
      this.setState({
        eventID: target,
        showEndSession: true,
        shouldRecord: this.state.eventRecord,
        modify: true,
        isLoading: false,
      });
      const request2 = new Request(myServer + "/api/v1/startEvent", {
        method: "PUT",
        credentials: "include",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({ event: target }),
      });
      fetch(request2)
        .then(function (response) {
          response.json().then((res) => {
            var array = self.state.upComing;
            for (let i = 0; i < self.state.upComing.length; i++) {
              if (target === self.state.upComing[i].eventID) {
                array[i].isOn = 1;
                self.setState({
                  upComing: array,
                  isLoading: false,
                });
              }
            }
          });
        })
        .catch((error) => {
          self.setState({
            isLoading: false,
          });
        });
    }
  }

  onHideSessionModal = () => {
    if (this.state.isRecording) {
      this.setState({
        showDangerModal: true,
      });
    } else {
      this.endSessionHandler()
    }
  };

  endSessionHandler = () => {
    let self = this;
    if (this.state.isSessionOn && this.state.isRecording) {
      const stopRecordingButton = this.finishRecording.current;

      if (stopRecordingButton) {
        stopRecordingButton.click();
      }

      this.setState({ isRecording: 2 });

      setTimeout(() => {
        self.setState({
          showDangerModal: false,
        });
      }, 500);
    } else if (this.state.isSessionOn) {
      this.setState({
        eventID: null,
        isSessionOn: false,
        shouldRecord: 0,
        showDangerModal: false,
      });
    }
  };

  endEvent(e) {
    e.preventDefault();

    let self = this;
    pollingInterval = null;

    const request = new Request(myServer + "/api/v1/endEvent", {
      method: "PUT",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ event: e.target.value }),
    });
    fetch(request).then(function (response) {
      response.json().then((res) => {
        self.setState(
          {
            upload: true,
            isSessionOn: false,
          },
          () => {
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        );
      });
    });
  }

  upload(e) {
    let self = this;
    var name = e.target.name;
    var blob = e.target.value;
    fileDataLocal = new FormData();
    var fileName = name + "recording.wav";

    this.setState({
      uploading: true,
      uploadProgress: 0,
      uploadComplete: false,
    });

    fetch(blob)
      .then((r) => r.blob())
      .then((myblob) => {
        fileDataLocal.append("Recording", myblob, fileName);

        const xhr = new XMLHttpRequest();

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            console.log(`Uploaded ${event.loaded} out of ${event.total}`);

            this.setState({
              uploadProgress: parseInt(event.loaded / event.total * 100),
            });
          }
        };

        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            const status = xhr.status;

            if (status === 0 || (status >= 200 && status < 400)) {
              const res = JSON.parse(xhr.responseText);

              if (res.message === "success") {
                self.setState(
                  {
                    uploadComplete: true,
                    showModal: false,
                  },
                  () => {
                    window.location.reload();
                  }
                );
              } else {
                self.setState({
                  uploadComplete: false,
                  uploadProgress: 0,
                  uploading: false,
                });
              }
            } else {
              self.setState({
                uploadComplete: false,
                uploadProgress: 0,
                uploading: false,
              });
            }
          }
        };

        xhr.open("PUT", "api/v1/Recording");
        xhr.withCredentials = true;
        xhr.setRequestHeader("event", name);
        xhr.setRequestHeader("type", "localRecord");
        // xhr.setRequestHeader('duration', duration);
        xhr.send(fileDataLocal);
      });
  }

  beforeunload(e) {
    if (this.state.isSessionOn && this.state.showModal === true) {
      e.preventDefault();
      e.returnValue = true;
    } else if (this.state.isSessionOn && this.state.showModal === false) {
    }
  }

  async componentDidMount() {
    window.addEventListener("beforeunload", this.beforeunload.bind(this));

    const { is_Admin, role, eventAttendees } = this.props;
    var self = this;
    let newArray = [];
    if (is_Admin === true || role === "admin") {
      const request = new Request(myServer + "/api/v1/companyEvents", {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      });

      await fetch(request)
        .then(function (response) {
          response.json().then((res) => {
            if (res.message === "cookie expired") {
              self.props.actions.logOutSuccess();
            }
            let newArray = [];
            if (res.data && res.data.length === 0) {
              const request3 = new Request(myServer + "/api/v1/getFilteredUsers", {
                method: "GET",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
              });
              fetch(request3).then(function (response) {
                response.json().then((res) => {
                  var result = [];

                  if (res.users === undefined) {
                    return "";
                  } else {
                    result.push("All Company Events");
                    res.users.map((element, key) => {
                      return result.push(element);
                    });

                    self.setState({
                      usersArray: result,
                    });
                    var e = {
                      value: "All Company Events",
                    };
                    self.handleSelectedUser(e);
                  }
                });
              });
            } else {
              if (res.data.length) {
                for (let i = 0; i < res.data.length; i++) {
                  newArray.push(res.data[i]);
                }
              }
              self.setState({
                companyEmail: res.data[0].companyEmail,
              });

              const request2 = new Request(myServer + "/api/v1/attendees", {
                method: "POST",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ events: newArray }),
              });
              fetch(request2).then(function (response) {
                response.json().then((res) => {
                  newArray.map(function (o, index) {
                    return (o.numOfAttendees = res.data[index]);
                  });
                  /*
                  self.setState({
                    events: newArray,
                  });
                  */
                });

                const newRequest = new Request(
                  myServer + "/api/v1/findModifiable",
                  {
                    method: "POST",
                    credentials: "include",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ events: newArray }),
                  }
                );
                fetch(newRequest).then(function (response) {
                  response.json().then((res) => {
                    /*
                    for (let i = 0; i < res.array.length; i++) {
                      newArray[i].modify = res.array[i].modify ? res.array[i].modify : 0;

                      console.log(newArray[i].modify);
                    }
                    */

                    const anotherRequest = new Request(
                      myServer + "/api/v1/findIfOn",
                      {
                        method: "POST",
                        credentials: "include",
                        headers: new Headers({
                          "Content-Type": "application/json",
                        }),
                        body: JSON.stringify({ events: newArray }),
                      }
                    );

                    fetch(anotherRequest).then(function (response) {
                      response.json().then((res) => {
                        for (let i = 0; i < res.array.length; i++) {
                          newArray[i].isOn = res.array[i].isOn;
                        }
                        AllEvents = newArray;
                        /*
                        self.setState({
                          events: AllEvents,
                        });
                        */

                        var eventsArray = [];
                        var copy2 = AllEvents.slice(0);

                        var emails = copy2.reduce(
                          (acc, o) => (
                            (acc[o.email] = (acc[o.email] || 0) + 1), acc
                          ),
                          {}
                        );

                        for (let k = 0; k < copy2.length; k++) {
                          if (copy2[k].email === null) {
                            copy2[k].email = "null";
                          }
                        }
                        var numOfUsers = Object.keys(emails).length;
                        for (let j = 0; j < numOfUsers; j++) {
                          for (let i = 0; i < copy2.length; i++) {
                            var start;
                            var end;
                            if (
                              res.array[i]?.startTime !== null &&
                              res.array[i]?.endTime !== null
                            ) {
                              start = res.array[i]?.startTime;
                              end = res.array[i]?.endTime;
                            } else {
                              start = null;
                              end = null;
                            }

                            if (copy2[i].email === Object.keys(emails)[j]) {
                              users[j] = {
                                user: copy2[i].email,
                              };
                              var event = {
                                eventID: copy2[i].eventID,
                                entranceKey: copy2[i].entranceKey,
                                startTime: start,
                                endTime: end,
                                numOfAttendees: copy2[i].numOfAttendees,
                                TimeZone: copy2[i].TimeZone,
                                modify: copy2[i].modify,
                                hours: copy2[i].Hours,
                                Title: copy2[i].Title,
                                isOn: copy2[i].isOn,
                                localRecord: copy2[i].localRecord,
                                estimatedAttendees: copy2[i].estimatedAttendees,
                                totalCost: copy2[i].totalCost,
                                archived: copy2[i].archived,
                                endTimeEpoch: copy2[i].endTimeEpoch,
                              };

                              eventsArray.push(event);
                              users[j].events = eventsArray;
                            }
                          }
                          eventsArray = [];
                        }
                        const request3 = new Request(
                          myServer + "/api/v1/getFilteredUsers",
                          {
                            method: "GET",
                            credentials: "include",
                            headers: new Headers({
                              "Content-Type": "application/json",
                            }),
                          }
                        );
                        fetch(request3).then(function (response) {
                          response.json().then((res) => {
                            var result = [];

                            if (res.users === undefined) {
                            } else {
                              res.users.map((element) => result.push(element));
                            }
                            self.setState(
                              {
                                usersArray: result,
                              },
                              () => {
                                var e = {
                                  value: "All Company Events",
                                };
                                self.handleSelectedUser(e);
                              }
                            );
                          });
                        });
                      });
                    });
                  });
                });
              });
            }
          });
        })
        .catch((error) => {
          return error;
        });
    } else {
      const request = new Request(myServer + "/api/v1/Events", {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      });
      await fetch(request)
        .then(function (response) {
          response.json().then((res) => {
            if (res.message === "cookie expired") {
              self.props.actions.logOutSuccess();
            }
            for (let i = 0; i < res.data.length; i++) {
              newArray.push(res.data[i]);
            }
            const request2 = new Request(myServer + "/api/v1/attendees", {
              method: "POST",
              credentials: "include",
              headers: new Headers({
                "Content-Type": "application/json",
              }),
              body: JSON.stringify({ events: newArray }),
            });
            fetch(request2).then(function (response) {
              response.json().then((res) => {
                newArray.map(function (o, index) {
                  o.numOfAttendees = res.data[index];
                  return o.numOfAttendees;
                });

                self.setState(
                  {
                    ...self.state,
                    events: newArray,
                  },
                  () => {
                    self.distributeEvents();
                  }
                );
              });
            });
          });
        })
        .catch((error) => {
          return error;
        });
    }
  }

  // Filter Events by User
  handleSelectedUser(e) {
    let filteredEvents = AllEvents;
    if (e.value != "All Company Events") {
      filteredEvents = filteredEvents.filter(function(event) {
        if (event.userID === e.value) {
          return true;
        }
        return false;
      });
    }

    this.setState(
      {
        ...this.state,
        events: filteredEvents,
      },
      () => {
        this.distributeEvents();
      }
    );
  }

  async distributeEvents() {
    var upcoming = [];
    var ongoing = [];
    var finished = [];
    var archived = [];

    for (let i = 0; i < this.state.events.length; i++) {
      if (this.state.events[i].startTime === null) {
        upcoming.push(this.state.events[i]);
      } else if (this.state.events[i].isOn === 1) {
        finished.push(this.state.events[i]);
      } else if (this.state.events[i].archived === 1) {
        archived.push(this.state.events[i]);
      } else {
        finished.push(this.state.events[i]);
      }
    }

    this.setState({
      upComing: upcoming,
      ongoing: ongoing,
      finished: finished,
      archived: archived,
      loading: false,
    });
  }

  componentDidUpdate(prevProp, prevState) {
    if (this.props.creditScore !== prevProp.creditScore) {
      window.location.reload();
    }
  }

  handleClick(e) {
    this.props.actions.modifyEvent(e.target.value);
    this.setState({
      redirectToModify: true,
    });
  }

  viewEvent(e) {
    this.props.actions.view(e.target.value);
    this.setState({
      redirectToView: true,
    });
  }

  resetEvent(e) {
    const eventID = e.target.value;
    let request = new Request(myServer + "/api/v1/Events", {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        event: eventID,
      }),
    });
    fetch(request).then(function (response) {
      response.json().then((res) => {
        if (res.message === "success") {
          setTimeout(() => {
            window.location.reload();
          }, 300);
        }
      });
    });
  }

  render() {
    let self = this;

    const { is_Admin, role, eventAttendees } = this.props;
    if (this.state.redirectToModify === true) {
      return <Redirect to="/ModifyEvent"></Redirect>;
    }
    if (this.state.redirectToView === true) {
      return <Redirect to="/ViewEvent"></Redirect>;
    }

    var spinner = (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );

    const attendeeCount = eventAttendees
      ? eventAttendees[this.state.eventToStartKey] || 0
      : 0;

    var view;
    var extendModal = (
      <div id="modal" style={{ display: this.state.modalDisplay }}>
        <p> do you want to extend your session </p>
        <p>By Clicking Yes, you agree to be charged.</p> <br></br>
        <Button onClick={this.extendSession}>Yes</Button>
        <Button
          onClick={() => {
            this.setState({
              extendSession: false,
              modalDisplay: "none",
            });
          }}
        >
          No
        </Button>
      </div>
    );

    if (this.state.isSessionOn && this.state.shouldRecord === 1) {
      view = (
        <React.Fragment>
          <AlertModal
            show={this.state.showModal}
            onHide={this.onHideSessionModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdropClassName="modal-backdrop--medium"
            dialogClassName="modal-xl modal-session"
            variant="alt"
          >
            <Modal.Header
              bsPrefix="modal-header p-0"
              closeButton
            ></Modal.Header>
            <Modal.Body className="session-content">
              <Row className="session-content__event-container">
                <Col xs={12} lg={12} className="order-2 order-lg-1">
                  <ReactMediaRecorder
                    audio
                    render={({
                      status,
                      startRecording,
                      stopRecording,
                      mediaBlob,
                      error,
                    }) => (
                      <div>
                        <Row>
                          <Col xs={8} lg={10}>
                            <h2 className="session-content__event-title">
                              {this.state.eventToStartName}
                            </h2>
                          </Col>
                          <Col xs={4} lg={2} className="text-right">
                            <img className="session-content__icon-session mt-3" src={SessionIcon} alt="" />
                          </Col>
                        </Row>

                        <h3 className="session-content__event-steps">Steps To Your Presentation</h3>

                        <Row>
                          <Col xs={12} lg={4}>
                            <h3 className="session-content__event-step-heading">Step 1</h3>
                            <p className="session-content__event-description">Accept microphone permissions.</p>
                            <img className="session-content__icon-microphone mt-3" src={MicrophoneIcon} alt="Microphone Icon" />
                          </Col>
                          <Col xs={12} lg={4}>
                            <h3 className="session-content__event-step-heading">Step 2</h3>
                            <p className="session-content__event-description">Tell your audience to go to:</p>
                            <a
                              className="session-content__event-link"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={process.env.REACT_APP_PUBLIC}
                            >
                              go.presentii.com
                            </a>
                            <p className="session-content__event-description">Your event key is:</p>
                            <p className="session-content__event-key">{this.state.eventToStartKey}</p>
                            <p className="session-content__event-description">{attendeeCount==1?'Attendee':'Attendees'} in your event:</p>
                            <p className="session-content__event-attendees"><span className="session-content__event-attending">{attendeeCount}</span></p>
                          </Col>
                          <Col xs={12} lg={4}>

                            <h3 className="session-content__event-step-heading">Step 3</h3>
                            <p className="session-content__event-description">When you are ready.</p>

                            <p>{error}</p>

                            {/* When Session is open but not recording yet */}
                            <div
                              value={this.state.eventID}
                              id="capture"
                              name="Capture"
                              onClickCapture={this.startEventAppAudio}
                            >
                              <Button
                                className="session-content__event-control"
                                color="alt"
                                disabled={status==="recording"}
                                onClick={() => {
                                  startRecording();
                                  this.setState({
                                    isRecording: 1,
                                  });
                                }}
                              >
                                {status==="recording"?"now recording":"start recording"}
                              </Button>
                            </div>

                            {/* When Is recording */}
                            {status === "recording" && (
                              <div
                                id="capture"
                                name="Capture"
                                onClickCapture={this.stopEventAppAudio}
                                value={this.state.eventID}
                              >
                                {this.state.eventSurvey === 1 ? (
                                  <Button
                                    className="session-content__event-control"
                                    ref={this.finishRecording}
                                    id="stop"
                                    color="danger"
                                    onClick={() => {
                                      stopRecording();

                                      this.setState({
                                        isRecording: 2,
                                      });

                                      let uploadRecordButton = document.getElementById(
                                        "uploadRecording"
                                      );
                                      setTimeout(() => {
                                        uploadRecordButton.click();
                                      }, 500);
                                    }}
                                  >
                                    stop recording & start questions
                                  </Button>
                                ) : (
                                  <Button
                                    ref={this.finishRecording}
                                    id="stop"
                                    className="session-content__event-control"
                                    color="danger"
                                    onClick={() => {
                                      stopRecording();
                                      this.setState({
                                        isRecording: 2,
                                      });

                                      let uploadRecordButton = document.getElementById(
                                        "uploadRecording"
                                      );
                                      setTimeout(() => {
                                        uploadRecordButton.click();
                                      }, 500);
                                    }}
                                  >
                                    stop recording &amp; end session
                                  </Button>
                                )}
                              </div>
                            )}

                            <Button
                              className={
                                this.state.isRecording === 2
                                  ? "d-inline-block session-content__event-control"
                                  : "d-none"
                              }
                              id="uploadRecording"
                              ref={this.uploadRecording}
                              disabled={this.state.uploading}
                              name={this.state.eventID}
                              value={mediaBlob}
                              onClick={this.upload}
                            >
                              {" "}
                              {this.state.uploadProgress<100
                                ? `Uploading (${this.state.uploadProgress}%)...`
                                : "Uploaded, Post-Processing..."}
                            </Button>

                          </Col>
                        </Row>

                        {this.state.extendSession && (
                          <div>
                            <p> Your Event session is about to expire </p>
                            <p>do you want to extend your session</p>
                            <p>By Clicking Yes, you agree to be charged.</p>
                            <Button onClick={this.extendSession}>Yes</Button>
                            <Button
                              onClick={() => {
                                this.setState({
                                  extendSession: false,
                                });
                              }}
                            >
                              No
                            </Button>
                          </div>
                        )}

                      </div>
                    )}
                  />
                </Col>
              </Row>
            </Modal.Body>
          </AlertModal>
        </React.Fragment>
      );
    }

    var page;

    if (this.state.showArchives) {
      page = (
        <React.Fragment>
          <Container>
            <Row>
              <Col>
                <Button
                  id="afterClickArchives"
                  variant="info"
                  className="mb-2"
                  onClick={() => {
                    self.setState({
                      showArchives: !this.state.showArchives,
                    });
                  }}
                >
                  Back To Events
                </Button>
                <ArchiveTable events={this.state.archived}></ArchiveTable>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    } else {
      page = (
        <React.Fragment>
          <Container>
            <Row className="flex-row-reverse mb-5">
              <Col md="4" className="text-right">
                <div className="d-inline">
                  {(is_Admin || role === "admin") && (
                    <FilterByUser
                      users={this.state.usersArray}
                      selectHandler={this.handleSelectedUser}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Section sectionTitle="Upcoming Events">
                  {/* Accordion visible only on mobile */}
                  <EventsCollapse variant="alt" className="d-block d-lg-none">
                    {this.state.upComing.map((event) => (
                      <Card key={`collapseCard${event.eventID}`}>
                        <Card.Header>
                          <ActiveToggle eventKey={event.eventID}>
                            {event.Title}
                          </ActiveToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={event.eventID}>
                          <Card.Body>
                            <div className="d-flex flex-column">
                              <div className="events-collapse__details">
                                <p className="event-collapse__details-item">
                                  <span>Event Key: </span>{" "}
                                  <strong>{event.entranceKey}</strong>
                                </p>
                                <p className="event-collapse__details-item">
                                  <span>Presenter: </span>
                                  <strong>{event.presenterName}</strong>
                                </p>
                                <p className="event-collapse__details-item">
                                  <span>Date/Time: </span>
                                  <strong>{event.scheduledStartTime &&
                                  moment
                                    .unix(event.scheduledStartTime)
                                    .format("MMM Do YYYY h:mm a")}</strong>
                                </p>
                              </div>
                              <div className="events-collapse__actions">
                                {event.modify === 1 && (
                                  <Button
                                    color="secondary"
                                    value={event.eventID}
                                    onClick={this.handleClick}
                                    disabled={
                                      this.state.isSessionOn ||
                                      this.state.modify
                                    }
                                    className="mr-3"
                                  >
                                    edit event
                                  </Button>
                                )}
                                {event.isOn === 1 ? (
                                  <React.Fragment>
                                    {this.state.extendSession
                                      ? extendModal
                                      : ""}
                                    <Button
                                      variant="danger"
                                      value={event.eventID}
                                      onClick={this.endEvent}
                                    >
                                      End Session
                                    </Button>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <Button
                                      name={event.localRecord}
                                      value={event.eventID}
                                      onClick={() =>
                                        this.setState({
                                          startModalShow: true,
                                          eventRecord: event.localRecord,
                                          eventToStart: event.eventID,
                                          eventToStartName: event.Title,
                                          eventToStartKey: event.entranceKey,
                                          eventSurvey: event.survey,
                                          estimatedAttendees: event.estimatedAttendees
                                        })
                                      }
                                      disabled={this.state.isSessionOn}
                                      variant="primary"
                                    >
                                      start session
                                    </Button>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    ))}
                  </EventsCollapse>
                  {/* Table visible only on desktop */}
                  <TableWrapper className="mb-5 d-none d-lg-block">
                    <Table responsive striped id="upcoming">
                      <thead>
                        <tr>
                          <th>Event Name</th>
                          <th>Event Key</th>
                          <th>Presenter</th>
                          <th>Date</th>
                          <th>Edit Event</th>
                          <th>Start Session</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.upComing.map((event) => (
                          <tr key={event.eventID}>
                            <td>{event.Title}</td>
                            <td className="text-uppercase">{event.entranceKey}</td>
                            <td>{event.presenterName}</td>
                            <td>
                              {event.scheduledStartTime &&
                                moment
                                  .unix(event.scheduledStartTime)
                                  .format("MMM Do YYYY")}
                            </td>
                            <td>
                              {" "}
                              {event.modify == 1 && (
                                <Button
                                  color="secondary"
                                  value={event.eventID}
                                  onClick={this.handleClick}
                                  disabled={
                                    this.state.isSessionOn || this.state.modify
                                  }
                                >
                                  edit
                                </Button>
                              )}
                            </td>
                            <td>
                              {event.isOn === 1 ? (
                                <React.Fragment>
                                  {this.state.extendSession ? extendModal : ""}
                                  <Button
                                    variant="danger"
                                    value={event.eventID}
                                    onClick={this.endEvent}
                                  >
                                    stop recording and end session
                                  </Button>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <Button
                                    name={event.localRecord}
                                    value={event.eventID}
                                    onClick={() => {
                                        this.setState({
                                          startModalShow: false,
                                          eventRecord: event.localRecord,
                                          eventToStart: event.eventID,
                                          eventToStartName: event.Title,
                                          eventToStartKey: event.entranceKey,
                                          eventSurvey: event.survey,
                                          estimatedAttendees: event.estimatedAttendees
                                        })
                                        const buttonEvent = this
                                        setTimeout(() => {
                                          buttonEvent.startEvent(event)
                                        }, 300)
                                      }
                                    }
                                    disabled={this.state.isSessionOn}
                                    variant="primary"
                                  >
                                    start
                                  </Button>
                                </React.Fragment>
                              )}
                            </td>
                          </tr>
                        ))}
                        {this.state.upComing.length === 0 ? (
                          <React.Fragment>
                            <tr>
                              <td colSpan="6" className="text-center font-weight-bold text-uppercase py-4">No Upcoming Events</td>
                            </tr>
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </tbody>
                    </Table>
                  </TableWrapper>
                  {this.state.isSessionOn && view}
                  <PreFinishModal
                    show={this.state.showDangerModal}
                    variant="alt"
                    endSession={this.endSessionHandler}
                    onHide={() => this.setState({ showDangerModal: false })}
                  ></PreFinishModal>
                </Section>
              </Col>
              { this.state.finished.length > 0 ? (
                <React.Fragment>
                  <Col xs={12}>
                    <Section sectionTitle="Past Events">
                      <EventsCollapse className="d-block d-lg-none">
                        {this.state.finished.map((event) => (
                          <Card key={`collapseCard${event.eventID}`}>
                            <Card.Header>
                              <ActiveToggle eventKey={event.eventID}>
                                {event.Title}
                              </ActiveToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={event.eventID}>
                              <Card.Body>
                                <div className="d-flex flex-column">
                                  <div className="events-collapse__details">
                                    <p className="event-collapse__details-item">
                                      <span>Entrance Key: </span>{" "}
                                      <strong>{event.entranceKey}</strong>
                                    </p>
                                    <p className="event-collapse__details-item">
                                      <span>Presenter: </span>{" "}
                                      <strong>{event.presenterName}</strong>
                                    </p>
                                    <p className="event-collapse__details-item">
                                      <span>Date: </span>{" "}
                                      <strong>
                                        {/* Date */}
                                        {event.startTime &&
                                          moment
                                            .tz(event.startTime, event.TimeZone)
                                            .format("MMM Do YYYY ")}
                                        {/* Start Time */}
                                        {event.startTime &&
                                          moment
                                            .tz(event.startTime, event.TimeZone)
                                            .format("h:mm a ")}
                                        -{/* End Time */}
                                        {event.endTime &&
                                          moment
                                            .tz(event.endTime, event.TimeZone)
                                            .format("h:mm a ")}
                                      </strong>
                                    </p>
                                    <p className="event-collapse__details-item">
                                      <span># of Attendees:</span>{" "}
                                      <strong>{event.numOfAttendees}</strong>
                                    </p>
                                  </div>
                                  <div className="event-collapse__actions">
                                    <Button
                                      color="secondary"
                                      name="finished"
                                      className="mr-3"
                                      value={event.eventID}
                                      onClick={() => {
                                        this.setState({
                                          eventToArchive: event.eventID,
                                          archiveModalShow: true,
                                        });
                                      }}
                                    >
                                      archive event
                                    </Button>
                                    <Button
                                      color="alt"
                                      value={event.eventID}
                                      onClick={this.viewEvent}
                                    >
                                      see results
                                    </Button>
                                  </div>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        ))}
                      </EventsCollapse>
                      <TableWrapper className="d-none d-lg-block">
                        <Table striped responsive>
                          <thead className="thead-dark">
                            <tr>
                              <th>Event Name</th>
                              <th>Event Key</th>
                              <th>Presenter</th>
                              <th>Date / Time</th>
                              <th># of Attendees</th>
                              {(this.state.paymentOption && (is_Admin || role === "admin")) && (
                                <th>Total Cost</th>
                              )}
                              <th>Archive Event</th>
                              <th>See Results</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.finished.map((event, index) => (
                              <tr key={event.eventID}>
                                <td>{event.Title}</td>
                                <td className="text-uppercase">{event.entranceKey}</td>
                                <td>{event.presenterName}</td>
                                <td>
                                  {/* Date */}
                                  {event.startTimeEpoch &&
                                    moment
                                      .tz(event.startTimeEpoch * 1000, event.TimeZone)
                                      .format("MMM Do YYYY ")}
                                  {/* Start Time */}
                                  {event.startTimeEpoch &&
                                    moment
                                      .tz(event.startTimeEpoch * 1000, event.TimeZone)
                                      .format("h:mm a ")}
                                  - {/* End Time */}
                                  {event.endTimeEpoch &&
                                    moment
                                      .tz(event.endTimeEpoch * 1000, event.TimeZone)
                                      .format("h:mm a ")}
                                </td>
                                <td>
                                  {" "}
                                  <label>{event.numOfAttendees}</label>{" "}
                                </td>
                                {(this.state.paymentOption && (is_Admin || role === "admin")) && (
                                  <td>
                                    <a
                                      href={`${window.location.origin}/api/v1/invoice/${event.eventID}`}
                                      target="_blank"
                                    >
                                      ${event.totalCost / 100}
                                    </a>
                                  </td>
                                )}
                                <td>
                                  <Button
                                    color="secondary"
                                    name="finished"
                                    value={event.eventID}
                                    onClick={() => {
                                      this.setState({
                                        eventToArchive: event.eventID,
                                        archiveModalShow: true,
                                      });
                                    }}
                                  >
                                    archive
                                  </Button>
                                </td>
                                <td>
                                  {!event.endTime || !event.recording
                                    ? <Button
                                        value={event.eventID}
                                        onClick={this.resetEvent}
                                      >reset</Button>
                                    : <Button
                                      color="alt"
                                      value={event.eventID}
                                      onClick={this.viewEvent}
                                    >results</Button>
                                  }
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <PreArchiveModal
                          show={this.state.archiveModalShow}
                          archiveModal={this.archiveEvent}
                          isLoading={this.state.isLoading}
                          onHide={() => this.setState({ archiveModalShow: false })}
                        />
                      </TableWrapper>
                    </Section>
                  </Col>
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              <Col xs={12}>
                <Section>
                  <div className="mb-3">
                    <Link
                      href="#"
                      onClick={() => {
                        self.setState({
                          showArchives: !this.state.showArchives,
                        });
                      }}
                    >
                      Show Archived Events
                    </Link>
                  </div>
                  <Link href="https://presentii.com/faq/">
                    Need Help? Check out our FAQs
                  </Link>
                </Section>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    }

    return (
      <DefaultLayout menuCollapsed={this.props.menuCollapsed}>
        {this.state.loading ? spinner : page}
      </DefaultLayout>
    );
  }
}
function mapStateToProps(state) {
  return {
    eventAttendees: state.eventAttendees,
    is_Admin: state.isAdmin,
    menuCollapsed: state.menuCollapsed,
    logged_Out: state.loggedOut,
    currentUserZone: state.userZone,
    creditScore: state.creditScore,
    payment: state.payment,
    companyID: state.companyID,
    role: state.role,
    email: state.email,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
