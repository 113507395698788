import React from "react";
import { useMediaQuery } from "react-responsive";
import theme from "../../config/theme";

// Assets
import { ReactComponent as Logo } from "../../assets/img/logo_navigation.svg";
import { ReactComponent as LoginBg } from "../../assets/img/login_waves_desktop.svg";
import { ReactComponent as LoginBgMobile } from "../../assets/img/login_waves_mobile.svg";

// Components
import {
  Wrapper,
  LeftColumn,
  RightColumn,
  LogoContainer,
  BackgroundContainer,
  BodyStyles,
} from "./LoginLayout.styles";

const LoginLayout = ({ children, ...props }) => {
  const isDesktop = useMediaQuery({
    query: `(min-device-width: ${theme.breakpoints.md}px)`,
  });

  return (
    <Wrapper {...props}>
      <BodyStyles />
      <LeftColumn>
        <LogoContainer>
        <a href="https://presentii.com" className="mr-0">
          <Logo />
        </a>
        </LogoContainer>
        <BackgroundContainer>
          {isDesktop ? <LoginBg /> : <LoginBgMobile />}
        </BackgroundContainer>
      </LeftColumn>
      <RightColumn>{children}</RightColumn>
    </Wrapper>
  );
};

export default LoginLayout;
