import styled from "styled-components/macro";

export const EventTitle = styled.div`
  padding-top: 0.4rem;
  color: #ef6d53;
  font-weight: 800;
`;
export const EventOverallScore = styled.strong`
  font-size: 1.4rem;
`;
export const EventPresenterName = styled.div`
  color: #2ea39e;
  font-weight: 800;
`;
export const EventStats = styled.span`
  padding-top: 0.4rem;
  padding-right: 2rem;
  font-weight: 600;
`;
export const CommentsToggleContainer = styled.div`
  margin: 0rem 2rem;
`;
export const EstimatedCost = styled.div`
  display: flex;
  flex-direction: column;
  img {
    max-width: 6.7857rem;
    margin-bottom: 1.2857rem;
  }
`;
export const CommentListTitle = styled.h3`
  margin: 2.5rem auto 40px;
  padding: 0;
  padding-bottom: 20px;
  max-width: 840px;
  position: relative;
`;
export const CommentsList = styled.ul`
  margin: 2.5rem auto 40px;
  padding: 0;
  padding-bottom: 20px;
  max-width: 840px;
  max-height: 400px;
  overflow-y: auto;
  position: relative;

  .comments-timeline {
    &__item {
      position: relative;
      padding-left: 150px;
      list-style: none;
      margin-bottom: 30px;

      &:before {
        content: "";
        position: absolute;
        top: 34.5px;
        left: 101px;
        border: 2px solid #2ea39e;
        width: 18px;
        height: 18px;
        background-color: #fff;
        border-radius: 50px;
      }

      &:after {
        content: "";
        position: absolute;
        top: 40px;
        bottom: 0;
        width: 2px;
        background: #dbdbdb;
        left: 110px;
        margin-left: -1px;
        height: 150%;
        z-index: -1;
      }
    }

    &__date {
      position: absolute;
      display: block;
      left: 0;
      top: 26px;
      width: 85px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
    }

    &__content {
      display: flex;
      align-items: center;
      position: relative;
      background-color: #f5f5f5;
      padding: 25px 30px 25px 28px;
      border-radius: 3px;
      min-height: 85px;
      font-size: 16px;

      &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #f5f5f5;
        transform: rotate(45deg);
        top: 37.5px;
        left: -5px;
      }
    }
  }
`;
