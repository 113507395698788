import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { ToolTipObject } from "./ToolTip.styles";

const ToolTip = React.forwardRef((props, ref) => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>{props.tooltipText}</Tooltip>}
    >
      <ToolTipObject
        rightAlign={props.rightAlign}
        className="d-flex justify-content-center align-items-center font-weight-bold"
      >
        ?
      </ToolTipObject>
    </OverlayTrigger>
  );
});

export default ToolTip;
